$rtl-header-myaccount-margin-right: 8px;
$rtl-header-cart-icon-margin-right: 4px;
$rtl-header-logo-margin: auto 12px auto auto;
$rtl-header-icon-padding-left: 10px;
$rtl-header-icon-padding-right: 10px;
$rtl-header-logo-margin-left: 32px;
$rtl-header-search-margin-left: 40px;
$rtl-header-search-margin-left-mobile: 10px;
$rtl-header-ms-search-form-control-margin-right: 8px;
$rtl-header-ms-search-form-control-padding-left: 40px;
$rtl-header-ms-search-account-info-margin-left: 26px;
$rtl-signin-button-text-margin-right: 10.33px;
$rtl-header-locator-margin-left-desktop: 26px;
$rtl-header-search-margin-left-tablet: 37px;
$rtl-header-search-margin-left-mobile: 25px;

[dir="rtl"] {
    .ms-header {
        &__account-info {
            margin-right: unset;
            margin-left: $rtl-header-ms-search-account-info-margin-left;
        }

        &__container {
            .ms-header__account-info {
                .ms-header__signin-button-text, .ms-profile-button-text {
                    margin-right: $rtl-signin-button-text-margin-right;
                }
            }

            .ms-header__logo {
                margin-right: unset;
                margin-left: $rtl-header-logo-margin-left;
            }

            .ms-search.with-submit {
                margin-left: $rtl-header-search-margin-left;
                margin-right: unset;

                .ms-search__searchForm {
                    .ms-search__form-control {
                        margin-right: $rtl-header-ms-search-form-control-margin-right;
                        padding-left: $rtl-header-ms-search-form-control-padding-left;
                        margin-left: unset;
                        padding-right: unset;
                    }
                }
            }
        }

        @media screen and (min-width: $msv-breakpoint-l) {
            .ms-header__preferred-store-container {
                margin-left: $rtl-header-locator-margin-left-desktop;
                margin-right: unset;

                .ms-header__preferred-store-btn {
                    &::before {
                        margin-left: 10px;
                        margin-right: unset;
                    }
                }
            }
        }

        @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
            .mobile-navicon-container {
                display: none;
            }

            .ms-nav.desktop-vp {
                display: flex;
                position: relative;
                border-top: 1px solid var(--msv-header-font-color);
            }

            .ms-header__topbar {
                .ms-search {
                    margin-left: $rtl-header-search-margin-left-tablet;
                    margin-right: unset;
                }

                .ms-header__account-info {
                    margin-left: $rtl-header-search-margin-left-tablet;
                    margin-right: unset;
                }

                .ms-header__preferred-store-container {
                    margin-left: $rtl-header-search-margin-left-tablet;
                    margin-right: unset;

                    .ms-header__preferred-store-btn {
                        margin-right: unset;

                        &::before {
                            margin-left: 0;
                        }
                    }
                }

                .ms-header__container .ms-header__topbar .ms-header__account-info {
                    margin-left: $rtl-header-search-margin-left-tablet;
                    margin-right: unset;
                    margin-top: 0;
                }
            }
        }

        @media (max-width: ($msv-breakpoint-m)-1) {
            .ms-header__topbar {
                .desktop-navicon-container {
                    display: none;
                }

                .ms-search {
                    margin-left: $rtl-header-search-margin-left-mobile;
                    margin-right: unset;
                }

                .ms-header__account-info {
                    margin-left: $rtl-header-search-margin-left-mobile;
                    margin-right: unset;
                }

                .ms-header__preferred-store-btn {
                    margin-left: $rtl-header-search-margin-left-mobile;
                    margin-right: unset;

                    &::before {
                        margin-left: 0;
                        margin-right: unset;
                    }
                }
            }
        }

        .msc-cart-icon {
            &__count {
                margin-left: unset;
                margin-right: $rtl-header-cart-icon-margin-right;
            }
        }

        @media (max-width: $msv-breakpoint-l) {
            &__collapsible-hamburger {
                .ms-nav {
                    .ms-nav__drawer {
                        .drawer__button {
                            .drawer__glyph__end {
                                right: unset;
                                left: $rtl-header-icon-padding-left;
                            }
                            text-align: right;
                        }
                    }
                }

                .ms-search.with-submit {
                    &__icon-text {
                        padding-left: unset;
                        padding-right: $rtl-header-icon-padding-right;
                    }
                }

                .ms-header__wishlist-mobile {
                    text-align: right;

                    &::before {
                        padding-left: $rtl-header-icon-padding-left;
                        padding-right: unset;
                    }
                }
            }

            &__container {
                .ms-header__topbar {
                    .ms-header__logo {
                        margin: $rtl-header-logo-margin;
                    }
                }

                .mobile-navicon-container {
                    .ms-header__nav-icon {
                        margin-left: $rtl-header-search-margin-left-mobile;
                        margin-right: unset;
                    }
                }
            }

            .ms-header__container .ms-header__topbar .ms-header__account-info {
                &.account-mobile {
                    .drawer__button {
                        &::before {
                            padding-right: unset;
                            padding-left: $rtl-header-icon-padding-left;
                        }

                        .drawer__glyph__end {
                            right: unset;
                            left: $rtl-header-icon-padding-left;
                        }
                        text-align: right;
                    }

                    .ms-header__signin-button {
                        &::before {
                            padding-right: unset;
                            padding-left: $rtl-header-icon-padding-left;
                        }
                    }
                }
            }

            .ms-cart-icon {
                padding-left: unset;
            }

            &__profile-button {
                &::after {
                    margin-left: unset;
                    margin-right: $rtl-header-myaccount-margin-right;
                }
            }
        }
    }

    .ms-header__modal {
        .msc-modal {
            .ms-header__mobile-hamburger {
                .msc-modal__content {
                    .msc-modal__header {
                        text-align: left;

                        .msc-modal__close-button {
                            right: 0;
                            position: relative;
                        }
                    }
                }
            }
        }
    }

    .skip-to-main {
        left: 0;
        right: -999px;
    }

    .skip-to-main:focus,
    .skip-to-main:active {
        right: 0;
    }
}
.herbal-adventureworks.fixed.changeColorHeader{
    .top_public{
        .ms-search{
            input,button{
                color: #fff;
            }
            button::before,
            input::placeholder {
                color: #fff;
            }
            input{
                border-bottom: 1px solid rgba(#fff, 0.55);
            }
        }
        .ms-header__topbar a.ms-nav__list__item__link,
        .ms-header__topbar .col-sm-6:last-child .ms-signin-info__my-account-button-link::before,
        .ms-header__topbar .col-sm-6:last-child .msc-wishlist-icon::before,
        .ms-header__topbar .col-sm-6:last-child .msc-cart-icon::before,
        .ms-header__topbar .col-sm-6:last-child .msc-cart-icon .msc-cart-icon__count,
        .ms-header__signout-button{
            color: #fff;
        }   
    }
}
.top_public{
    .ms-search{
        input,button{
            color: #fff;
        }
        button::before,
        input::placeholder {
            color: #fff;
        }
        input{
            border-bottom: 1px solid rgba(#fff, 0.55);
        }
    }
    .ms-header__topbar a.ms-nav__list__item__link,
    .ms-header__topbar .col-sm-6:last-child .ms-signin-info__my-account-button-link::before,
    .ms-header__topbar .col-sm-6:last-child .msc-wishlist-icon::before,
    .ms-header__topbar .col-sm-6:last-child .msc-cart-icon::before,
    .ms-header__topbar .col-sm-6:last-child .msc-cart-icon .msc-cart-icon__count,
    .ms-header__topbar .col-sm-6:last-child .ms-header__signout-button{
        color: #fff;
    }   
}
.herbal-adventureworks.fixed{
    .top_public{
        .ms-search{
            input,button{
                color: #292929;
            }
            button::before,
            input::placeholder {
                color: #292929;
            }
            input{
                border-bottom: 1px solid rgba(#292929, 0.55);
            }
        }
        .ms-header__topbar a.ms-nav__list__item__link,
        .ms-header__topbar .col-sm-6:last-child .ms-signin-info__my-account-button-link::before,
        .ms-header__topbar .col-sm-6:last-child .msc-wishlist-icon::before,
        .ms-header__topbar .col-sm-6:last-child .msc-cart-icon::before,
        .ms-header__topbar .col-sm-6:last-child .msc-cart-icon .msc-cart-icon__count,
        .ms-header__topbar .col-sm-6:last-child .ms-header__signout-button{
            color: #292929;
        }   
    }
}