//HEADER
header {
    &.fixed {
        .navigation_menu_page {
            &.nav-custom .ms-nav__list .ms-nav__list__item a {
                &::before{
                    background: #000;
                }
                @media (min-width: 992px) {
                    color: #292929;
                }
            }
        }
    }

    .navigation_menu_page {
        &.nav-custom .ms-nav__list .ms-nav__list__item a {
            color: white;
            font-weight: 300;
            font-size: 14px;
            position: relative;
            &::before{
                content: '';
                position: absolute;
                width: 0;
                left: 0;
                right: 0;
                background: #fff;
                transition: all .5s;
                bottom: -5px;
                height: 1px;
            }
            &:hover{
                text-decoration: none;
                &::before{
                    width: 100%;
                }
            }

            @media (min-width: 992px) {
                text-transform: uppercase;
            }
        }

        &.navCustom {
            &_href-normal {
                .ms-nav__list__item__link {
                    @media (max-width: 768px) {
                        font-size: 20px;
                        line-height: 24px;
                        letter-spacing: -0.03em;
                        color: #F8F6F3;
                        font-weight: normal;
                    }
                }
            }
        }
    }

    .header-menu-resizing,.navigation_menu_page {
        .ms-nav.desktop-vp.parent {
            .left-section {
                @media (min-width:1200px) {
                    flex: 0 0 40%;
                    max-width: 40%;
                }

                .ms-nav__list__item {
                    @media (min-width:1200px) and (max-width: 1600px) {
                        margin-right: 20px !important;
                    }

                    &:last-child {
                        @media (min-width: 992px) {
                            margin-right: 0 !important;
                        }
                    }
                }
            }

            .logo {
                @media (min-width:1200px) {
                    flex: 1 0 0;
                    text-align: center;
                }

                @media (min-width:992px) and (max-width: 1199px) {
                    margin-top: 9px !important;
                    margin-bottom: 9px !important;
                }
            }

            .right-section {
                @media (min-width:1200px) {
                    flex: 0 0 40%;
                    max-width: 40%;
                }

                .ms-nav__list__item {
                    @media (min-width:1200px) and (max-width: 1600px) {
                        margin-left: 20px !important;
                    }

                    &:first-child {
                        @media (min-width: 992px) {
                            margin-left: 0 !important;
                        }
                    }
                }
            }

            li.ms-main-menu_item.logo .ms-site-logo a img {
                @media (min-width:992px) and (max-width: 1600px) {
                    transform: translateX(0) !important;
                }
            }

            .ms-main-menu_item {
                &>.ms-nav__list>.ms-nav__list__item {
                    &>.ms-nav__list__item__button,
                    &>.ms-nav__list__item__link {
                        @media (min-width:1200px) and (max-width: 1600px) {
                            font-size: 12px !important;
                        }
                    }
                }
            }

            .ms-nav__list {
                @media (min-width: 992px) {
                    justify-content: space-evenly;
                }
            }
        }

        .navigation_menu_page {
            .ms-nav__list[role='menu'] {
                @media (min-width:992px) and (max-width: 1199px) {
                    flex-direction: column;
                }
            }
        }
    }

    .navigation_menu_page .desktop-vp.parent ul.ms-nav__list li.ms-main-menu_item ul.ms-nav__list li.ms-nav__list__item .ms-nav__deafult {
        z-index: 1 !important;
    }
}

// MAIN 

.my-dashboard-title {
    &-spacingCustom {

        .hec-breadcrumb-product .ms-breadcrumb_list {
            padding: 24px 0;
            margin-top: 0;
            margin-bottom: 0px;
            border-bottom: 0;
        }
    }
}

.ms-nav__list__item__link {
    @media (max-width: 991px) {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.03em;
        color: #F8F6F3;
        font-weight: normal;
    }
}