.news {
    &--details {
        padding-bottom: 100px;
        overflow: hidden;
        @media (max-width: 1023px) {
            padding: 0 20px;
        }
        h2 {
            font-size: 45px;
            line-height: 36px;
            letter-spacing: -0.02em;
            color: #292929;
            font-weight: 500;
            border-bottom: 1px solid rgba(32, 32, 32, 0.2);
            padding-bottom: 25px;
            margin-bottom: 20px;
            @media (max-width: 1023px) {
                font-size: 24px;
                line-height: 30px;
                letter-spacing: -1px;
                padding-bottom: 20px;
            }
        }
        .left {

            @media (min-width: 1024px) {
                width: 66%;
                float: left;
            }
            .ms-cms-detail-comment-count-section,
            .ms-cms-detail-appreciation-section {
                .label {
                    font-size: 10px;
                    line-height: 12px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    color: rgba(41, 41, 41, 0.4);
                    font-weight: 500;
                    margin-bottom: 2px;
                    @media (min-width: 1024px) {
                        font-size: 12px;
                        line-height: 15px;
                    }
                }
                .comment-count,
                .appreciation {
                    font-size: 10px;
                    line-height: 12px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    color: #292929;
                    @media (min-width: 1024px) {
                        font-size: 12px;
                        line-height: 15px;
                    }
                }
            }
            .ms-cms-info-section {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .field__label {
                    font-size: 10px;
                    line-height: 12px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    color: rgba(41, 41, 41, 0.4);
                    font-weight: 500;
                    margin-bottom: 2px;
                    @media (min-width: 1024px) {
                        font-size: 12px;
                        line-height: 15px;
                    }
                }
                .field__items {
                    display: flex;
                }
                .field__item {
                    font-size: 10px;
                    line-height: 12px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    color: #292929;
                    @media (min-width: 1024px) {
                        font-size: 12px;
                        line-height: 15px;
                    }
                    + .field__item {
                        &::before {
                            content: ", ";
                        }
                    }
                }
                > div {
                    width: 50%;
                    padding-bottom: 10px;
                    @media (min-width: 1024px) {
                        width: 25%;
                    }
                }
            }
            .ms-cms-detail-content-section {
                padding-top: 24px;
                @media (min-width: 1024px) {
                    padding-top: 90px;
                }
            }

            .msc__add-to-print-like-button {
                border-top: 1px solid rgba(32, 32, 32, 0.2);
                padding: 10px 0 60px;
                display: flex;
                @media (min-width: 1024px) {
                    padding: 20px 0 100px;
                }
                button {
                    background: none;
                    border: none;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 22px;
                    display: flex;
                    align-items: center;
                    color: #292929;
                    padding: 0;
                    + button {
                        margin-left: 40px;
                    }
                    &.like {
                        span {
                            .like-icon {
                                content: "";
                                margin-right: 10px;
                                width: 24px;
                                height: 20px;
                                background-image: url('https://files-ap-prod.cms.commerce.dynamics.com/cms/api/mztvwtszhp/binary/MB3bKN');
                                background-repeat: no-repeat;
                                display: block;
                                float: left;
                            }
                        }
                    }
                }
            }

            .ms-cms-detail-comment-section {
                h2 {
                    font-weight: 500;
                    color: #292929;
                    font-size: 18px;
                    line-height: 24px;
                    letter-spacing: -0.01em;
                    padding-bottom: 20px;
                    margin: 0;
                    @media (min-width: 1024px) {
                        font-size: 30px;
                        line-height: 34px;
                        letter-spacing: -0.02em;
                    }
                }
                .comment {
                    display: flex;
                    flex-direction: row;
                    gap: 30px;
                    padding: 20px 0;
                    + article {
                        margin: 0;
                        border-top: 1px solid rgba(41, 41, 41, 0.4);
                    }
                    >*+* {
                        margin: 0;
                    }
                    .hidden {
                        display: none;
                    }
                    .comment__meta {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        overflow: hidden;
                    }
                    .comment__content {
                        width: calc(100% - 90px);
                        .field--name-field-comment-name {
                            font-size: 14px;
                            line-height: 20px;
                            letter-spacing: 0.02em;
                            color: #292929;
                            margin-bottom: 6px;
                        }
                        .field--name-field-comment-body {
                            font-weight: 300;
                            font-size: 13px;
                            line-height: 18px;
                            letter-spacing: 0.02em;
                            color: #292929;

                            @media (min-width: 1024px) {
                                font-size: 14px;
                                line-height: 20px;
                            }
                        }
                    }
                }
            }
            .comment-form__title {
                font-weight: 300;
                font-size: 18px;
                line-height: 50px;
                color: #000000;
                border: none;
                margin: 0;
                padding-bottom: 20px;
            }
            .ms-cms-detail-comment-form {
                .ms-comment__form {
                    .ms-comment__form-item-label {
                        display: none;
                    }
                    textarea {
                        height: 240px;
                        resize: none;
                        width: 100%;
                        padding: 24px 30px;
                        background: #FFFFFF;
                        border: 1px solid rgba(32, 32, 32, 0.2);
                        &:focus,
                        &:focus-visible {
                            outline: none;
                        }
                    }

                    .msc-btn {
                        margin-top: 50px;
                        background-color: transparent;
                        border: 1px solid rgba(32, 32, 32, 0.2);
                        min-width: 160px;
                        padding: 15px;
                        font-weight: 300;
                        text-align: center;
                        color: #000000;
                        font-size: 13px;
                        line-height: 18px;
                        @media (min-width: 1024px) {
                            min-width: 212px;
                            font-size: 16px;
                            line-height: 19px;
                        }
                    }
                }
            }
        }
        .right {
            display: none;
            @media (min-width: 1024px) {
                width: 34%;
                float: right;
                max-width: 340px;
                display: flex;
                flex-direction: column;
                gap: 40px;
                padding-top: 100px;
            }

            .ms-search__form {
                position: relative;
                max-width: 340px;
                width: 100%;
                margin: 15px 0;
                padding: 20px;
                border: 1px solid rgba(32, 32, 32, 0.2);
                display: flex;
                justify-content: space-between;
                align-items: center;
                input {
                    border: none;
                    background-color: transparent;
                    padding: 0;
                    width: calc(100% - 20px);
                    &:focus-visible {
                        outline: none;
                    }
                }
                button {
                    background-color: transparent;
                    background-image: url('https://files-ap-prod.cms.commerce.dynamics.com/cms/api/mztvwtszhp/binary/MB3ejo');
                    width: 16px;
                    height: 20px;
                    border: none;
                    font-size: 0;
                    background-repeat: no-repeat;
                }
            }

            .ms-cms-detail-tag {
                ul {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    li {
                        font-size: 13px;
                        line-height: 20px;
                        letter-spacing: 0.02em;
                        color: #292929;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        a{
                        color: #292929;

                        }
                    }
                }
            }
        }

        .category-title {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: row;
            padding-bottom: 40px;
            border-bottom: 1px solid rgba(32, 32, 32, 0.2);
            margin-bottom: 25px;
            p {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: -0.03em;
                padding: 0;
                margin: 0;
            }
            a {
                font-size: 13px;
                line-height: 16px;
                letter-spacing: -0.03em;
                color: rgba(32, 32, 32, 0.4);
            }
        }
        .ms-cms-news-top-article__table,
        .ms-cms-news-recent-article__table {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
        .card-double-tiles {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 19px;
            &--thumb {
                width: 75px;
                img {
                    width: 100%;
                }
            }
            &--desc {
                width: calc(100% - 94px);
            }
            .ms-top-article__date,
            .ms-recent-article__date {
                font-size: 13px;
                line-height: 16px;
                letter-spacing: -0.02em;
                color: rgba(41, 41, 41, 0.4);
                display: block;
                padding-bottom: 5px;
            }
            .ms-top-article__title,
            .ms-recent-article__title {
                font-size: 16px;
                line-height: 19px;
                letter-spacing: -0.02em;
                display: block;
                color: #292929;
            }
        }
        article {
            .clearfix.text-formatted.field.field--name-body.field--type-text-with-summary.field--label-hidden.field__item {
                padding-right: 20px;
                border-right: 1px solid rgba(32, 32, 32, 0.2);
            }
        }
    }

}