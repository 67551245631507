$msv-font-path: "../../../webfonts";
@font-face {
    font-family: 'Test Domaine Sans';
    font-weight: 300;
    src: url("#{$msv-font-path}/domaine-sans-text-web-regular.woff2") format("woff2");
}

@font-face {
    font-family: 'Test Domaine Sans Text';
    font-weight: 300;
    src: url("#{$msv-font-path}/test-domaine-sans-text-light.woff2") format("woff2");
}

@font-face {
    font-family: 'Test Domaine Sans Text';
    font-weight: normal;
    src: url("#{$msv-font-path}/test-domaine-sans-text-regular.woff2") format("woff2");
}

@font-face {
    font-family: 'Test Domaine Sans Text';
    font-weight: bold;
    src: url("#{$msv-font-path}/test-domaine-sans-text-bold.woff2") format("woff2");
}

img {
    width: 100%;
    height: auto;
}

button:focus {
    border: 0 !important;
    outline: 0 !important;
    box-shadow: unset !important;
    &::before{
        display: none;
    }
}

.ms-text-block {
    margin-top: 0;
}

.ms-content-block {
    min-height: unset;
}

@keyframes fadeIn {
    0%,
    50% {
        opacity: 0;
        transform: translateY(20px);
        visibility: hidden;
    }
    to {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
    }
}


/* css for video banner  */

.ed-banner-video {
    padding-left: 0 !important;
    padding-right: 0 !important;
    &::after {
        content: 'AUSTRALIAN MADE';
        bottom: 40px;
        position: absolute;
        color: #fff;
        transform: translateX(-50%);
        left: 50%;
        transition: opacity 0.5s;
    }
    &.ed-banner-video--hide::after {
        opacity: 0;
    }
    .homepage-video-text {
        img {
            width: auto;
            margin: 0 auto;
        }
    }
}

.ed-banner-video .row {
    position: relative;
}

.ed-banner-video .row .ms-video-player {
    height: 100%;
    overflow: hidden;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }
}

.ed-banner-video .row .col-12:last-child {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    transform: translate(-50%, -50%);
    @media (max-width: 992px) {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.ed-banner-video .row .col-12:last-child h2 {
    font-weight: normal;
    font-size: 68.1353px;
    position: relative;
    line-height: 106%;
    text-align: center;
    letter-spacing: 0.09em;
    font-family: 'Test Domaine Sans Text';
    text-transform: uppercase;
    color: #f8f6f3;
    animation: 4s fadeIn 4s;
    animation-fill-mode: forwards;
    visibility: hidden;
    @media (max-width: 992px) {
        font-size: 26px;
        padding: 0 30px;
    }
}
.ed-banner-video.hidden-intro .row .col-12:last-child h2 {
    animation: 1.5s fadeIn;
    animation-fill-mode: forwards;
}

.ed-banner-video .row .col-12:last-child p {
    font-weight: 300;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
    width: 55%;
    margin: auto;
    margin-top: 30px;
    animation: 5s fadeIn 5s;
    animation-fill-mode: forwards;
    visibility: hidden;
    @media (max-width: 992px) {
        font-size: 15px;
        width: 70%;
    }
}
.ed-banner-video.hidden-intro .row .col-12:last-child p {
    animation: 2s fadeIn;
    animation-fill-mode: forwards;
}


/* end css for video banner  */

.btn-page {
    transition: all 0.5s ease;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    color: #292929 !important;
    width: 170px;
    height: 49px;
    background: #dd8797;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
    border: 1px solid transparent;
}

.btn-page:hover {
    background: #fff;
    color: #dd8797;
    border: 1px solid #dd8797;
}


/* css for fixed left right */

.fixed-left {
    position: fixed;
    left: -40px;
    top: 50% !important;
    transform: rotate(-90deg);
    z-index: 1;
    p {
        color: #fff;
        transition: all .5s;
        margin: 0;
        font-family: 'Test Domaine Sans Text';
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.125em;
        font-weight: 300;
        &:nth-child(2) {
            display: none;
        }
        @media (max-width: 992px) {
            font-size: 12px;
        }
    }
    &.fixed {
        p {
            color: #292929;
        }
    }
    @media (max-width: 992px) {
        left: -68px;
    }
}

.fixed-right {
    position: fixed;
    right: -70px;
    top: 50%;
    transform: rotate(90deg);
    z-index: 1;
    p {
        color: #fff;
        transition: all .5s;
        margin: 0;
        font-family: 'Test Domaine Sans Text';
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.125em;
        font-weight: 300;
        &:nth-child(2) {
            display: none;
        }
        @media (max-width: 992px) {
            font-size: 12px;
        }
    }
    &.fixed {
        p {
            color: #292929;
        }
    }
    @media (max-width: 992px) {
        right: -88px;
    }
}


/* css for banner */

.banner-home .ms-text-block {
    padding: 210px 0;
    text-align: center;
    width: 70%;
    margin: auto;
}

.banner-home .ms-text-block h2 {
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #292929;
    margin-bottom: 30px;
}


/* css for section 1 */

.banner-section-1 {
    margin-bottom: 150px;
    @media (max-width: 992px) {
        margin-bottom: 50px;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.banner-section-1 .box-section-1 .txt-section-1-left {
    margin: 0 0 0 20%;
    width: 80%;
    @media (max-width: 992px) {
        margin: 0;
        width: 100%;
        margin-left: 0 !important;
    }
}

.banner-section-1 .box-section-1 .txt-section-1-right {
    padding-left: 100px;
    width: 80%;
    display: flex;
    height: 100%;
    align-items: center;
    ul{
        li{
            font-size: 16px;
            line-height: 20px;
            color: #292929;
        }
    }
    @media (max-width: 992px) {
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
    }
}
.banner-section-1 .box-section-1 .txt-section-1-right h3 {
    // font-weight: normal;
    // font-size: 14px;
    // letter-spacing: 2px;
    // line-height: 17px;
    // color: #292929;
    // text-transform: uppercase;
    // margin-bottom: 50px;
    // @media (max-width: 992px) {
    //     margin-bottom: 30px;
    // }
}
.banner-section-1 .box-section-1 .txt-section-1-right h2 {
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #292929;
    margin-bottom: 20px;
    @media (max-width: 992px) {
        margin-top: 30px;
    }
}

.banner-section-1 .box-section-1 .txt-section-1-right p {
    font-size: 16px;
    line-height: 20px;
    color: #292929;
    // margin-bottom: 40px;
}


/* css for section 2 */

.banner-section-2 {
    margin-bottom: 100px;
    padding-right: 0 !important;
    padding-left: 0 !important;
    @media (max-width: 992px) {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

.banner-section-2 .ms-content-block__image img {
    height: 600px;
    object-fit: cover;
    @media (max-width: 992px){
        height: auto;
        width: 100%;
    }
}

.quality-research__banner-image-contain {
    .ms-content-block__image img {
        object-fit: contain;
        object-position: center;
    }
}

.banner-section-2 .box-section-2 .txt-section-2-right {
    margin-left: auto;
    border-left: 1px solid rgba(32, 32, 32, 0.2);
    padding-left: 41px;
    width: 80%;
    align-items: flex-start;
    padding-right: 8.5rem;
    @media (max-width: 992px) {
        margin-left: 0;
        padding-left: 15px;
        width: 100%;
        padding-right: 15px;
    }
}

.banner-section-2 .box-section-2 .txt-section-2-right h3 {
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 17px;
    color: #292929;
    text-transform: uppercase;
    margin-bottom: 50px;
}

.banner-section-2 .box-section-2 .txt-section-2-left {
    width: 100%;
    margin: 0 !important;
    @media (max-width: 992px) {
        padding-left: 15px;
        border-left: 1px solid rgba(32, 32, 32, 0.2);
        padding-bottom: 50px;
    }
}


/* css for section 3 */

.banner-section-3 {
    @media (min-width: 993px) {
        padding-left: 0 !important;
    }
}

.banner-section-3 .box-section-3 .txt-section-3-right {
    width: 85%;
    @media (max-width: 992px) {
        width: 100%;
    }
}
.bg-form-register-footer{
    @media (max-width: 992px){
        max-width: 100% !important;
    }
}

.color_black p {
    color: #000 !important;
    font-weight: normal !important;
    font-size: 14px !important;
}


/* css for section 6 */

.section-6 h2 {
    font-weight: 200;
    font-size: 37px;
    line-height: 111%;
    text-align: center;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    font-family: 'Test Domaine Sans Text';
    color: #292929;
    width: 55%;
    margin: auto;
    margin-bottom: 150px;
    position: relative;
    @media (max-width: 992px) {
        font-size: 23px;
        width: 90%;
    }
}

.mb_sp {
    margin-bottom: 150px;
}

.section-6 h2:after {
    position: absolute;
    content: '';
    width: 1px;
    height: 100px;
    left: 50%;
    transform: translateX(-50%);
    top: -130px;
    background: rgba(32, 32, 32, 0.2);
}

.section-6 h2:before {
    position: absolute;
    content: '';
    width: 1px;
    height: 200px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -15rem;
    background: rgba(32, 32, 32, 0.2);
}


/* ----------------------------------------------------------------- */


/* css for page footer  */

.private-footer-page {
    background: #292929;
    padding: 70px 0 40px;
}

.private-footer-page .menu-title .ms-text-block {
    margin-bottom: 15px;
}

.private-footer-page .menu-title .ms-text-block p {
    color: #f8f6f3;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 15px;
    width: calc(100% - 50px);
}

.private-footer-page .menu-item .ms-text-block {
    margin-bottom: 15px;
}

.private-footer-page .menu-item .ms-text-block h4 {
    color: rgba(255, 255, 255, 0.4);
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin: 0;
}

.private-footer-page .menu-item .ms-text-block p {
    color: #f8f6f3;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    padding-bottom: 0px;
    a{
        text-decoration: none;
        color: #fff;
    }
}

.private-footer-page {
    h2 {
        color: #f8f6f3;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        padding-bottom: 15px;
        width: calc(100% - 50px);
    }
    p {
        color: #f8f6f3;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        padding-bottom: 0px;
        a{
            text-decoration: none;
            color: #fff;
            word-break: break-word;
        }
    }
    h4 {
        color: rgba(255, 255, 255, 0.4);
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        margin: 0;
    }
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        >li {
            width: 100%;
        }
    }
    .ms-button-signout__footer{
        margin-top: -5px;
    }
    .ms-header__signout-button{
        color: #f8f6f3;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        padding-bottom: 0px;
        text-decoration: none;
    }
    .form-login-footer{
        padding: 0;
        .herbal-top-header{
            border-bottom: 0;
            padding: 0;
            .ms-nav__list{
                flex-wrap: wrap;
                padding: 0;
                margin-bottom: 5px;
                align-items: baseline;
            }
            .logo{
                display: none;
            }
            .ms-nav{
                padding: 0;
            }
            ul{
                li{
                    text-align: left !important;
                    margin: 0 !important;
                    a{
                        color: #f8f6f3;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;
                        padding-bottom: 0px;
                    }
                }
            }
        }
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        @media (max-width: 1199px) {
            flex-direction: column;
        }
        li {
            width: 50%;
            @media (max-width: 1199px) {
                width: 100%;
            }
        }
    }
    @media (max-width: 992px) {
        .p0-sp{
            padding: 0;
        }
        padding-top: 40px;
        h2 {
            margin-top: 30px;
            width: calc(100% - 0px);
            margin-bottom: 10px;
        }

    }
}


/* css for form register */

.bg-form-register-footer {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.bg-form-register-footer .ms-container-background-image {
    background-size: cover !important;
}

.bg-form-register-footer .ms-container-background-image {
    min-height: 325px !important;
}

.form-register-public {
    padding: 80px 0;
    background: #dd8797;
}

.form-register-public .box-register-left {
    padding-left: 10%;
}

.form-register-public .box-register-left .txt-box-register-left h3 {
    font-weight: normal;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px;
}

.form-register-public .box-register-left .txt-box-register-left h4 {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #292929;
    width: 70%;
}

.form-register-public .btn-register {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-register-public .btn-register a {
    height: 49px;
    line-height: 49px;
    background: #292929;
    color: #fff;
    text-decoration: none;
    width: 170px;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    transition: all .5s ease;
}

@media (min-width: 992px) {
    .form-register-public .btn-register a:hover {
        background: #fff;
        color: #292929;
    }
}


/* css for banner our story  */

.banner-our-story {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.banner-our-story .ms-container-background-image {
    height: calc(100vh + 7px) !important;
    margin-bottom: 150px;
    background-size: cover !important;
}

.banner-our-story .ms-container-background-image .ms-text-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    position: relative;
    >div {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-30%);
    }
}

.banner-our-story .ms-container-background-image .ms-text-block h2 {
    font-size: 68.1353px;
    font-family: 'Test Domaine Sans Text';
    line-height: 106%;
    text-align: center;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: #f8f6f3;
    width: 80%;
    margin: auto;
    font-weight: 300;
    @media (max-width: 992px) {
        font-size: 30px;
    }
}

.section-6-our-story {
    margin-top: 250px;
    @media (max-width: 992px) {
        margin-top: 150px;
    }
}

.section-6-our-story h2:before {
    height: 100px;
    bottom: -8rem;
}


/* css for team our story  */

.w100 {
    width: 100% !important;
}

.ed-w100 {
    width: 100% !important;
    margin-bottom: 15rem !important;
}

.team-our-story {
    margin-bottom: 200px;
    margin-top: 250px;
    width: 93%;
    margin: auto;
    margin-bottom: 150px !important;
    @media (max-width: 992px) {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.our-team-tittle {
    width: 50%;
    margin: auto;
    padding: 0px 0 60px 0;
}

.our-team-tittle h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 45px;
    line-height: 36px;
    color: #292929;
    padding-bottom: 40px;
}

.our-team-tittle p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 19px;
    line-height: 37px;
    letter-spacing: 1px;
    color: #292929;
}

.row-team-our-story {
    margin-bottom: 40px;
    .team-iteam .ms-content-block__details{
        p{
            word-break: break-word;
            a{
                transition: all 0.5s ease;
                font-weight: normal;
                font-size: 14px;
                line-height: 15px;
                color: #292929 !important;
                width: 170px;
                height: 49px;
                background: #dd8797;
                justify-content: center;
                align-items: center;
                text-decoration: none !important;
                border: 1px solid transparent;
                &:hover{
                    background: #fff;
                    color: #dd8797;
                    border: 1px solid #dd8797;
                }
            }
        }
    }
}

.row-team-our-story .team-iteam {
    padding: 0 40px;
    border-left: 1px solid rgba(32, 32, 32, 0.2);
    margin-bottom: 40px;
    @media (max-width: 992px) {
        padding: 0 15px;
    }
}

.row-team-our-story .team-iteam .ms-content-block__details h3 {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #292929;
    height: 80px;
    display: flex;
    align-items: center;
}

.row-team-our-story .team-iteam .ms-content-block__details h2 {
    font-weight: normal;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #292929;
    margin-bottom: 20px;
    height: 70px;
}

.row-team-our-story .team-iteam .ms-content-block__details p {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #292929;
}
@media (max-width: 768px){
    .row-team-our-story{
        .team-iteam{
            img{
                width: 70%;
            }
        }
    }
}

/* ------------------- POWER SECTION 1 --------------------- */

.power-items {
    margin-bottom: 100px;
    padding-left: 10% !important;
    padding-right: 10% !important;
    @media (max-width: 992px) {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

.power-items .row-1 .item-1 {
    padding: 0 40px;
    border-left: 1px solid rgba(32, 32, 32, 0.2);
    margin-bottom: 40px;
    @media (max-width: 992px) {
        padding: 0 15px;
        margin-bottom: 100px;
    }
}

.power-items .row-1 .item-1 .ms-content-block__details {
    ul {
        li {
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: -0.02em;
            color: #292929;
            margin-bottom: 0;
        }
    }
}

.power-items .row-1 .item-1 .ms-content-block__details h3 {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #292929;
    margin: 40px 0;
}

.power-items .row-1 .item-1 .ms-content-block__details h2 {
    font-weight: normal;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #292929;
    margin-bottom: 20px;
}

.power-items .row-1 .item-1 .ms-content-block__details p {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #292929;
    margin-bottom: 10px;
}

.marginTopBot {
    margin-top: 200px !important;
    margin-bottom: 350px;
}


/* ------------------- END POWER SECTION 1 --------------------- */


/* ------------------- Watch video --------------------- */

.watch-video {
    margin-bottom: 100px;
}

.watch-video .title-watch-video h2 {
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #191414;
    margin-bottom: 50px;
}


/* ------------------- End Watch video --------------------- */


/* ------------------- sales-enquiries --------------------- */

.sales-enquiries {
    background: #dd8797;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-bottom: 100px;
}

.sales-enquiries .col-2-parent .col-2-child .ms-text-block {
    padding-right: 40px;
}

.sales-enquiries .col-2-parent .col-2-child .item-sales h2 {
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #191414;
    margin-bottom: 20px;
}

.sales-enquiries .col-2-parent .col-2-child .item-sales p {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #191414;
}


/* ------------------- End sales-enquiries --------------------- */


/* ------------------- Card Power --------------------- */

.card-power {
    margin-top: 0;
}

.card-power .team-iteam {
    border-left: 0;
    padding: 0 20px;
}

.card-power .team-iteam .ms-content-block__details h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
    letter-spacing: -0.03em;
    color: #292929;
}

.title-page h2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: -0.02em;
    color: #292929;
    margin-bottom: 50px;
}


/* ------------------- End Card Power --------------------- */


/* ------------------- News --------------------- */

.list-news .title-news-page {
    padding-left: 0;
    padding-right: 0;
    margin-top: 70px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(32, 32, 32, 0.2);
    margin-bottom: 50px;
}

.list-news .title-news-page .title-news h2 {
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.03em;
    color: #1a1a1a;
}

.list-news .row-news .item-news {
    margin-bottom: 50px;
    padding: 0 20px;
}

.list-news .row-news .item-news img {
    margin-bottom: 30px;
}

.list-news .row-news .item-news h3 {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #292929;
    font-weight: 300;
    margin-bottom: 30px;
}

.list-news .row-news .item-news p {
    font-weight: normal;
    font-size: 24px;
    line-height: 130%;
    letter-spacing: -0.03em;
    color: #292929;
    height: 62px;
}


/* ------------------- End News --------------------- */


/* ------------------- Breadcrumb --------------------- */

// .hec-breadcrumb-product {
// 	display: block;
// }
// .hec-breadcrumb-product .ms-breadcrumb_list {
// 	float: unset;
// 	display: flex;
// 	margin-top: 47px;
// 	padding-bottom: 19px;
// 	border-bottom: 1px solid rgba(32, 32, 32, 0.2);
// }
// .hec-breadcrumb-product .ms-breadcrumb_list > li {
// 	float: unset;
// 	margin-right: 20px;
// 	display: inline-block;
// }
// .hec-breadcrumb-product .ms-breadcrumb_list > li > a {
// 	font-weight: 300;
// 	font-size: 12px;
// 	line-height: 15px;
// 	color: rgba(41, 41, 41, 0.4) !important;
// 	text-transform: uppercase !important;
// 	text-decoration: none !important;
// 	margin-right: 10px;
// 	padding-right: 10px;
// }
// .hec-breadcrumb-product .ms-breadcrumb_list > li.breadcrumb_item-current a {
// 	color: #292929 !important;
// }

/* ------------------- End Breadcrumb --------------------- */


/* ------------------- Product detail --------------------- */
.hec-item-product-detail {
    @media (max-width: 992px){
        margin-top: 0;
    }
	.ms-buybox__media-gallery {
		flex-basis: 50%;
		min-width: 50%;
		.ms-media-gallery {
			.ms-media-gallery__carousel {
				flex: 1;
				.msc-carousel__inner.active {
					display: flex;
					justify-content: center;
				}
			}
            .msc-ss-carousel-vert-button-wrapper{
                background: transparent;
            }
			.ms-media-gallery__thumbnails-container {
				img {
					object-fit: contain;
                    width: 40%;
                    margin: auto;
                    @media (min-width: 992px){
                        width: 400px;
                    }
				}
                .msc-ss-carousel-vert{
                    .msc-ss-carousel-vert-strip{
                        .msc-ss-carousel-vert-slide{
                            .ms-media-gallery__thumbnail-item{
                                width: 100%;
                            }
                        }
                    }
                }
			}
		}
        @media (max-width: 992px){
            margin-bottom: 50px;
            .ms-media-gallery{
                padding-top: 0;
                .ms-media-gallery__carousel{
                    height: 400px;
                    position: static;
                    display: none;
                    .msc-carousel__inner{
                        width: 100%;
                        height: 100%;
                        display: block;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            position: static;
                        }
                    }
                }
            }
        }
	}
	.ms-buybox__content {
        p {
            margin-bottom: 0;
        }
		flex-basis: 50%;
        background: transparent;
		min-width: 50%;
        position: relative;
        @media (max-width: 992px){
            padding: 0 15px;
        }
		.ms-buybox__product-title {
			font-weight: 500;
			font-size: 36px;
			line-height: 44px;
			letter-spacing: -0.03em;
			color: #1a1a1a;
			margin-bottom: 20px;
            padding-bottom: 0;
            margin-top: 50px;
		}
        .msc-buybox__ratings-section{
            border-bottom: 0;
            padding-bottom: 0;
            display: none;
        }
        .ms-cms-product-attributes-top{
            p {
                font-size: 15px;
            }
            ul{
                padding: 0;
                list-style: none;
                margin: 0;
                li{
                    display: flex;
                    border-top: 1px solid rgba(32, 32, 32, 0.2);
                    padding: 10px 0;
                    h2{
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 18px;
                        color: #292929;
                        min-width: 125px;
                        margin: 0;
                        margin-right: 15px;
                    }
                    p{
                        font-weight: normal;
                        font-size: 15px;
                        line-height: 18px;
                        color: #292929;
                        margin: 0;
                    }
                }
            }
        }
		.msc-price {
			.msc-price__actual {
				font-weight: normal;
				font-size: 15px;
				line-height: 20px;
				color: #1a1a1a;
			}
			.ms-buybox__product-unit-of-measure {
				>span {
					font-weight: normal;
					font-size: 15px;
					line-height: 20px;
					color: #1a1a1a;
				}
			}
		}
		.ms-buybox__product-description {
			font-weight: normal;
			font-size: 15px;
			line-height: 20px;
			color: #292929;
			margin-bottom: 60px;
			width: 80%;
		}
        .msc-buybox__price-section{
            .msc-buybox__price-section-text{
                font-size: 15px;
            }
            .msc-price{
                font-size: 15px;
            }
        }
		.ms-buybox__quantity {
			width: 165px;
			float: left;
			margin: 0;
			margin-right: 20px;
            padding: 0;
            border: 0;
            margin-top: 38px;
			.ms-buybox__product-quantity-label {
				display: none;
			}
			.quantity {
				border: 1px solid rgba(32, 32, 32, 0.2);
                @media(max-width: 992px){
                    width: 100%;
                }
				button {
					border: 0;
                    min-width: 35px;
                    background: transparent;
				}
				input {
					width: 50px;
					border: 0;
					font-size: 17px;
                    background: transparent;
                    padding: 0;
                    margin: 0;
				}
			}
            @media (max-width: 992px){
                float: unset;
                margin-bottom: 30px;
            }
		}
        .ms-buybox__inventory-info{
            position: absolute;
            left: 0;
            top: 0;
            margin: 0;
            @media (max-width: 992px){
                left: 15px;
            }
        }
        .ms-buybox__add-to-cart-container{
            margin: 0;
            padding: 0px 0 0px;
            margin-top: 38px;
            .msc-add-to-cart {
                padding: 0;
                height: 54px;
                width: 152px;
                flex: unset;
                text-transform: uppercase;
                background: #dd8797;
                border: 1px solid transparent;
                color: #000;
                font-size: 12px;
                transition: all 0.5s ease;
                cursor: pointer;
                &:hover {
                    background: #EAB4C3;
                    border: 1px solid #EAB4C3;
                }
                &[disabled]{
                    background: rgba(32, 32, 32, 0.2);
                    white-space: nowrap;
                    padding: 0 10px;
                    width: fit-content;
                    box-shadow: none;
                    &:hover{
                        cursor: pointer;
                        background: rgba(32, 32, 32, 0.2);
                        border: 0;
                    }
                }
            }
            .msc-add-to-wishlist {
                height: 51px;
                line-height: 27px;
                border: 0;
                background: transparent;
                color: #292929;
            }
            @media (max-width: 992px){
                margin-left: 0;
            }
        }
        .ms-buybox__add-to-order-template-container{
            text-align: left;
            display: block;
            clear: left;
            button.msc-add-to-cart-extra-actions{
                margin: 0;
                padding: 0;
                margin-top: 20px;
                margin-bottom: 40px;
            }
        }
        .msc-buybox__description-text,
        .msc-buybox__description-section{
            display: none;
        }
        .ms-buybox__add-to-wishlist-container{
            padding: 0;
            margin: 0;
            position: absolute;
            top: 0;
            left: 100px;
            button{
                font-size: 13px;
                margin: unset;
                height: auto;
                padding: 0;
                text-transform: none;
                letter-spacing: 0;
                &::before{
                    color: #292929;
                }
            }
        }
		.product-add-to-cart {
			width: 50%;
			float: left;
			margin: 0;
			.buttons {
				.msc-add-to-cart {
					padding: 0;
					height: 51px;
					width: 152px;
					flex: unset;
					text-transform: uppercase;
					background: #dd8797;
					border: 1px solid transparent;
					color: #000;
					font-size: 12px;
					transition: all 0.5s ease;
					&:hover {
						background: #EAB4C3;
						border: 1px solid #EAB4C3;
					}
				}
				.msc-add-to-wishlist {
					height: 51px;
					line-height: 27px;
					border: 0;
					background: transparent;
					color: #292929;
				}
			}
		}
	}
}


/* ------------------- End Product detail --------------------- */


/* css for table product detail  */

.hec-infor-description-product-detail {
    background: #e3dfd6;
    padding: 100px 0;
}

.hec-infor-description-product-detail .hec-description-table {
    max-width: 1140px;
    margin: auto;
}

@media (max-width: 992px) {
    .hec-infor-description-product-detail .hec-description-table {
        max-width: 960px;
        margin: auto;
    }
}

@media (max-width: 768px) {
    .hec-infor-description-product-detail .hec-description-table {
        max-width: 720px;
        margin: auto;
    }
}

@media (max-width: 576px) {
    .hec-infor-description-product-detail .hec-description-table {
        max-width: 540px;
        margin: auto;
    }
}

.hec-infor-description-product-detail .hec-description-table>h2 {
    font-weight: normal;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #1a1a1a;
    margin-bottom: 40px;
}

.hec-infor-description-product-detail .hec-description-table .ms-product-specification__table tbody tr {
    background-color: #e2dfd6 !important;
}

.hec-infor-description-product-detail .hec-description-table .ms-product-specification__table tbody tr th {
    padding-left: 0;
    border: 0;
    border-top: 1px solid rgba(32, 32, 32, 0.2);
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #292929;
    white-space: nowrap;
    padding-right: 85px;
}

.hec-infor-description-product-detail .hec-description-table .ms-product-specification__table tbody tr td {
    border: 0;
    border-top: 1px solid rgba(32, 32, 32, 0.2);
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #292929;
}
.hec-infor-description-product-detail{
    .field--type-string.field--label-hidden.field__item{
        font-weight: normal;
        font-size: 25px;
        line-height: 36px;
        letter-spacing: -0.02em;
        color: #1A1A1A;
        margin: 0;
        margin-bottom: 40px;
        @media (max-width: 992px){
            padding: 0 15px;
        }
    }
    ul{
        padding: 0;
        list-style: none;
        margin: 0;
        @media (max-width: 992px){
            padding: 0 15px;
        }
        li{
            padding: 10px 0;
            display: flex;
            border-top: 1px solid rgba(32, 32, 32, 0.2);
            h2{
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.01em;
                color: #292929;
                margin: 0;
                min-width: 250px;
                margin-right: 80px;
            }
            p{
                font-weight: normal;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.01em;
                margin: 0;
                color: #292929;

            }
            @media (max-width: 992px){
                flex-wrap: wrap;
                h2{
                    min-width: auto;
                    width: 100%;
                    font-size: 15px;
                    margin-bottom: 20px;
                }
                p{
                    font-size: 15px;
                }
            }
        }
    }
}


/* end css for table product detail  */


/* css for title compounds */

.title-compounds {
    margin-bottom: 70px;
}

.title-compounds h2 {
    font-weight: 300;
    font-size: 37.4545px;
    text-align: center;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: #292929;
    height: 120px;
    line-height: 120px;
    background: #eab4c3;
}


/* end css for title compounds */


/* css for list product  */

.list-product .msc-product__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #292929;
}

.list-product .msc-price span {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #292929;
    display: flex;
    .msc-price__strikethrough{
        text-decoration: line-through;
        margin-right: 10px;
    }
}

.list-product .msc-product .msc_image {
    object-fit: cover;
}
.msc-modal__dialog.msc-search-result-modal{
    padding: 20px;
    .msc-modal__content{
        border: 0;
        .msc-modal__header{
            h5{
                font-size: 30px;
            }
        }
        .msc-review-modal-body{
            .ms-search-result-container__Sort-by-category{
                label{
                    min-width: 70px;
                }
                select{
                    width: calc(100% - 75px);
                    height: 36px;
                }
            }
            .ms-search-result-container__refiner-section {
                margin-top: 24px;
                .ms-search-result-container__refine-menu {
                    .ms-refine-submenu {
                        &.availability, &.Available, &.physical {
                            display: none;
                        }
                        button {
                            background: transparent;
                            border: none;
                            border-top: 1px solid rgba(32, 32, 32, 0.2) !important;
                            border-bottom: 1px solid rgba(32, 32, 32, 0.2) !important;
                            display: flex;
                            justify-content: space-between;
                            padding: 15px 0;
                        }
                        .ms-refine-submenu__list {
                            padding: 15px 0;
                            li {
                                a {
                                    &::before {
                                        font-size: 20px;
                                        color: #292929;
                                    }
                                    span {
                                        font-size: 16px;
                                        margin-left: 10px;
                                        font-weight: 400;
                                    }
                                }
                            }
                            li + li {
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
        }
        .msc-modal__footer{
            justify-content: right;
            padding-right: 5px;
            button{
                border: 0;
                background: #292929;
                color: #fff;
                height: 40px;
                min-width: 100px;
            }
        }
    }
}
.list-product{
    .with-submit{
        position: relative;
        .ms-search__icon{
            button{
                &:focus{
                    &::before{
                        display: block;
                        font-size: 30px;
                    }
                }
            }
        }
        .ms-search__searchForm{
            position: relative;
            width: 25%;
            padding-right: 20px;
            @media (max-width: 992px){
                width: 100%;
            }
            input{
                padding: 10px 20px;
                border: 1px solid rgba(32,32,32,0.2);
                background: transparent;
                width: 100%;
                @media (max-width: 992px){
                    height: 36px;
                    padding: 0 20px;
                    padding-right: 0;
                    border: 0;
                }
            }
            button{
                position: absolute;
                right: 25px;
                color: #292929;
                top: 8px;
                &::before{
                    font-size: 20px;
                }
                @media (max-width: 992px){
                    top: 5px;
                    right: 10px;
                }
            }
            .ms-search__form-cancelSearch{
                display: none;
            }
        }
        .ms-search__autoSuggest{
            z-index: 999;
            position: absolute;
            margin-top: 10px;
            @media (max-width: 992px){
                margin-top: 30px;
            }
            .msc-autoSuggest__search-title-container{
                .ms-search__form-cancelSearch{
                    background: transparent;
                    font-size: 20px;
                }
                .msc-autoSuggest__search-title{
                    color: #fff;
                    @media (max-width: 992px){
                        margin-top: 10px;
                    }
                }
            }
            &.hide{
                z-index: -1;
                opacity: 0;
            }
            &.show{
                z-index: 1;
                opacity: 1;
            }
            .msc-autoSuggest__results-container{
                .msc-autoSuggest__results{
                    .ms-search__autoSuggest__keyword{
                        ul.msc-autoSuggest__keywordResults-items{
                            li.msc-autoSuggest__keywordResults-item{
                                a{
                                    word-break: break-word;
                                }
                            }
                        }
                    }
                }
                .ms-search__autoSuggest__product{
                    ul.msc-autoSuggest__productResults-items{
                        li.msc-autoSuggest__productResults-item{
                            a.msc-autoSuggest__productResults-item{
                                text-decoration: none;
                                span{
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .ms-search-result-container{
        .ms-search-result-container__category-nav-section{
            margin: 20px 0;
            display: inline-block;
            .ms-search-result-container__title.result-count-product.collection{
                h1{
                    font-family: 'Inter', sans-serif;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 20px;
                    color: #231F20;
                    margin-bottom: 0;
                }
            }
            nav{
                a{
                    font-weight: bold;
                    font-size: 15px;
                    line-height: 20px;
                    color: #231F20;
                    text-decoration: none;
                    @media (max-width: 992px){
                        display: none;
                    }
                }
            }
            .ms-search-result-container__title{
                width: 100%;
                h1{
                    .ms-search-result__collection-title-prefix {
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: bold;
                    }
                    span.ms-search-result__collection-title-text{
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 28px;
                        color: #231F20;
                        text-decoration: none;

                    }
                }
            }
        }
        #search-result-modal {
            background: #292929;
            border: 0;
            height: 50px;
            width: 100%;
            color: #fff;
            margin: 30px 0;
            padding: 0;
            text-transform: uppercase;
        }
        .msc-sort-filter-wrapper{
            text-align: center;
            padding: 0 5px;
            button{
                background: #292929;
                border: 0;
                height: 50px;
                width: 100%;
                color: #fff;
                margin: 30px 0;
                padding: 0;
                text-transform: uppercase;
            }

        }
        .ms-search-result-container__refine-product-section{
            display: flex;
            flex-wrap: wrap;
            position: relative;
            .ms-refiner-heading{
                width: 25%;
                padding-right: 25px;
                background: transparent;
                border-bottom: 0;
                .ms-refiner-heading_title{
                    min-height: auto;
                    display: block;
                    background: transparent;
                    button.msc-btn{
                        width: 100%;
                        font-size: 16px;
                        margin-left: 0;
                        justify-content: space-between;
                        border-bottom: 1px solid rgba(35, 31, 32, 0.15) !important;
                        background: transparent;
                        padding: 15px 0;
                        font-weight: bold;
                        color: #231F20;
                        &::after{
                            font-size: 23px;
                        }
                    }
                    .ms-search-result-wrapper-title-choice-summary{
                        display: none;
                    }
                    .ms-search-result-wrapper-sort-by-category{
                        top: -90px;
                        margin-right: 0;
                        z-index: 999;
                        .ms-search-result-container__Sort-by-category{
                            label{
                                margin: 0;
                                margin-right: 10px;
                            }
                            select{
                                border: 0;
                            }
                        }
                    }
                }
                .ms-search-result-container__refiner-section{
                    border-top: 0;
                    overflow-y: unset;
                    .ms-search-result-container__refine-menu{
                        max-height: fit-content;
                        margin: 0;
                        display: block;
                        overflow: unset;
                        .ms-refine-submenu.list-group{
                            min-width: auto;
                            width: 100%;
                            padding: 0;
                            margin-top: 10px;
                            &.availability, &.Available, &.physical {
                                display: none;
                            }
                            .ms-refine-submenu__list{
                                overflow-y: unset;
                                .ms-refine-submenu-item{
                                    a{
                                        span{
                                            font-size: 16px;
                                        }
                                        &::before{
                                            color: #292929;
                                        }
                                    }
                                }
                                .list-group-item{
                                    background: transparent;
                                    padding: 0;
                                    .ms-refine-submenu__range-refiner{
                                        background: 1px solid rgba(35, 31, 32, 0.15);
                                        height: 2px;
                                        .slider__container{
                                            .slider__track{
                                                background: #231F20;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .ms-search-result-container__product-section{
                width: 75%;
                .list-unstyled{
                    --col: 3;
                    --space: 40px;
                    display: flex;
                    flex-wrap: wrap;
                    margin-right: calc(-1 * var(--space));
                    margin-bottom: 50px;
                    @media (max-width: 992px){
                        --col: 2;
                        --space: 15px;
                    }
                    .ms-product-search-result__item{
                        width: calc(100% / var(--col));
                        padding-right: var(--space);
                        margin-bottom: 50px;

                        a{
                            text-decoration: none;
                            display: block;
                            .msc-product__image{
                                width: 100%;
                                margin-bottom: 20px;
                                border-bottom: 1px solid rgba(32, 32, 32, 0.2);
                                padding-bottom: 20px;
                                img{
                                    width: 100%;
                                    aspect-ratio: 1;
                                    object-fit: contain;
                                }
                            }
                            .msc-product__details{
                                padding: 0;
                                text-align: center;
                                h5{
                                    font-weight: 500;
                                    font-size: 18px;
                                    line-height: 30px;
                                    color: #292929;
                                    margin-bottom: 15px;
                                    height: 30px;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                }
                                span{
                                    font-weight: normal;
                                    font-size: 15px;
                                    line-height: 20px;
                                    color: #292929;
                                    display: flex;
                                    justify-content: center;
                                    .msc-price__strikethrough{
                                        text-decoration: line-through;
                                        margin-right: 10px;
                                        color: #9d9d9d;
                                    }
                                }
                            }
                        }
                        .ms-quickView{
                            text-align: center;
                            .ms-quickView__button{
                                font-size: 14px;
                                font-weight: 400;
                                color: #292929;
                                background: transparent;
                                width: 150px;
                                height: 50px;
                                border: 1px solid rgba(32, 32, 32, 0.2);
                                text-transform: capitalize;
                                text-align: center;
                                line-height: 50px;
                                padding: 0;
                                margin: auto;
                                margin-top: 20px;
                                transition: all .5s ease;
                                @media (min-width: 992px){
                                    &:hover{
                                        background: #dd8797;
                                        color: #fff;
                                        border: 1px solid #dd8797;
                                    }
                                }
                            }
                        }
                        .msc-product__availability {
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
            }
        }
        .ms-search-result-container__Products{
            @media (max-width: 992px){
                margin-top: 50px;
            }
            .list-unstyled{
                display: flex;
                flex-wrap: wrap;
                margin-right: -10px;
                @media (max-width: 992px){
                    gap: 5px;
                    margin-right: 0;
                    padding-left: 5px;
                }
                .ms-product-search-result__item{
                    margin-bottom: 40px;
                    width: 50%;
                    padding-right: 10px;
                    @media (max-width: 992px){
                        padding-right: 0;
                        width: 49%;
                    }
                    a{
                        text-decoration: none;
                        display: block;
                        .msc-product__image{
                            width: 100%;
                            margin-bottom: 20px;
                            border-bottom: 1px solid rgba(32, 32, 32, 0.2);
                            padding-bottom: 20px;
                            img{
                                width: 100%;
                                aspect-ratio: 1;
                                object-fit: contain;
                            }
                        }
                        .msc-product__details{
                            padding: 0;
                            text-align: center;
                            h5{
                                font-weight: 500;
                                font-size: 18px;
                                line-height: 30px;
                                color: #292929;
                                margin-bottom: 15px;
                                height: 30px;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                            }
                            span{
                                font-weight: normal;
                                font-size: 15px;
                                line-height: 20px;
                                color: #292929;
                                display: flex;
                                justify-content: center;
                                .msc-price__strikethrough{
                                    text-decoration: line-through;
                                    margin-right: 10px;
                                    color: #9d9d9d;
                                }
                            }
                        }

                    }
                    .ms-quickView{
                        text-align: center;
                        button{
                            font-size: 14px;
                            font-weight: 400;
                            color: #292929;
                            background: transparent;
                            width: 150px;
                            height: 50px;
                            border: 1px solid rgba(32,32,32,0.2);
                            text-transform: capitalize;
                            text-align: center;
                            line-height: 50px;
                            padding: 0;
                            margin: auto;
                            margin-top: 20px;
                            transition: all .5s ease;
                        }
                    }
                    .msc-product__availability {
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
        .ms-search-result-container__pagination{
            margin-bottom: 50px;
            ul{
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: center;
                li{
                    padding: 0;
                    a{
                        font-size: 14px;
                        line-height: 15px;
                        text-align: center;
                        color: rgba(41, 41, 41, 0.4);
                        min-width: 30px;
                        height: 30px;
                        line-height: 30px;
                        margin-right: 10px;
                        text-decoration: none;
                        display: inline-block;
                    }

                    &.active{
                        span{
                            display: inline-block;
                            background: #292929;
                            color: #fff;
                            width: 30px;
                            font-size: 14px;
                            height: 30px;
                            line-height: 30px;
                            text-align: center;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 767px){
        .ms-search__form{
            &.bx-hide{
                display: block;
                transition: all .5s ease;
                opacity: 0;
                width: 0;
                height: 0;
            }
            &.bx-show{
                opacity: 1;
                position: absolute;
                top: -6px;
                left: 50px;
                right: 0;
                input{
                    margin: 0;
                    border-bottom: 1px solid #292929;
                    padding: 0;
                    color: #292929;
                    &:focus{
                        border-bottom: 1px solid #292929;

                    }
                }
            }
        }
    }
}

/* end css for list product  */


/* css for our team  */

.banner-our-story {
    margin-bottom: 100px;
}

.banner-our-story .ms-container-background-image {
    margin-bottom: 0 !important;
}

.list-our-team .row-list-our-team .team-iteam {
    width: 80%;
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
}

.list-our-team .row-list-our-team .team-iteam .ms-content-block__details h2 {
    color: #c9837a;
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 15px;
}

.list-our-team .row-list-our-team .team-iteam .ms-content-block__details h3 {
    text-transform: uppercase;
    font-size: 21px;
    margin-bottom: 5px;
    line-height: 20px;
}

.list-our-team .row-list-our-team .team-iteam .ms-content-block__details p {
    font-size: 13px;
    font-style: italic;
}


/* end css for our team  */


/* css for our history  */

.page-history {
    width: 91%;
    margin: auto;
    padding-bottom: 150px;
}

.page-history .content_left .img-year-history {
    /* position: relative; */
    padding-right: 30px;
    /* border-right: 1px solid rgba(32, 32, 32, 0.2); */
    padding-bottom: 10rem;
    /* padding-top: 80px; */
}


/* .page-history .content_left .img-year-history .ms-content-block__details {
    position: absolute;
    top: 80px;
    right: -3px;
} */


/* .page-history .content_left .img-year-history .ms-content-block__details h2,
.page-history .content_right .img-year-history .ms-content-block__details h2 {
    font-size: 18px;
    width: 5px;
    height: 25px;
    background: #dd8797;
    text-align: center;
    color: #dd8797;
} */

.page-history .content_left .content-history {
    padding-left: 100px;
}

.page-history .content_right .img-year-history {
    /* position: relative; */
    padding-left: 30px;
    /* border-left: 1px solid rgba(32, 32, 32, 0.2); */
    padding-bottom: 10rem;
    /* margin-left: -1px; */
}


/* .page-history .content_right .img-year-history .ms-content-block__details {
    position: absolute;
    top: 0px;
    left: -3px;
} */

.page-history .content_right .content-history {
    padding-right: 100px;
}

.page-history .content_right .content-history p,
.page-history .content_left .content-history p {
    line-height: 27px;
    font-size: 15px;
}

.page-history .content_right .content-history h3,
.page-history .content_left .content-history h3 {
    font-weight: normal;
    font-size: 17px;
    line-height: 17px;
    color: #292929;
    margin-bottom: 10px;
}

.page-history .content_right .content-history h2,
.page-history .content_left .content-history h2 {
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #292929;
    margin-bottom: 10px;
}

.page-history .content_right .img-year-history img,
.page-history .content_left .img-year-history img {
    /* border-radius: 25px;
    border: solid 3px #443130; */
}

.bg-history-black {
    background: #000;
    padding-top: 8rem;
    padding-bottom: 8rem;
    margin-bottom: 8rem;
}

.bg-history-black .txt-left h2 {
    font-weight: normal;
    font-size: 17px;
    line-height: 17px;
    margin-bottom: 10px;
    color: #fff;
}

.bg-history-black .txt-left p {
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #fff;
    margin-bottom: 25px;
}

.bg-history-black .txt-right p {
    line-height: 27px;
    font-size: 15px;
    color: #fff;
    margin: 20px 0;
    font-weight: 300;
}

.padding-bottom-0 {
    padding-bottom: 0;
}


/* end css for our history  */


/* css for banner header  */

.banner-header .ms-content-block__image {
    height: 245px;
}

.banner-header .ms-content-block__image img {
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.banner-header .ms-content-block__details {
    padding: 0 !important;
    height: 100% !important;
    width: 100% !important;
    align-items: center !important;
    justify-content: center !important;
    background: rgba(0, 0, 0, 0.7) !important;
}

.banner-header .ms-content-block__details h2 {
    font-weight: 300;
    font-size: 68.1353px;
    line-height: 106%;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: #f8f6f3;
}


/* end css for banner header  */


/* css for contact us  */

.content_contact {
    margin-bottom: 12rem;
    padding-left: 0 !important;
}

.content_contact .row_content_contact .content_contact_right {
    padding-left: 100px;
    padding-right: 0;
    @media (max-width: 992px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.content_contact_right .info_contact {
    margin-bottom: 2rem;
}

.content_contact_right .title-contact h2 {
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #292929;
    margin-bottom: 50px;
}

.content_contact_right .title-contact h3 {
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 17px;
    color: #292929;
    text-transform: uppercase;
    margin-bottom: 10px !important;
}

.content_contact_right .info_contact .info-contact-left {
    @media (max-width: 992px) {
        margin-bottom: 50px;
    }
}

.content_contact_right .info_contact .info-contact-left h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #292929;
}

.content_contact_right .info_contact .info-contact-left h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: rgba(41, 41, 41, 0.6);
    margin-bottom: 0;
}

.content_contact_right .info_contact .info-contact-left p {
    font-size: 16px;
    line-height: 20px;
    color: #292929;
    margin-bottom: 0px;
}

.contact_social {
    @media (max-width: 992px) {
        .row {
            width: fit-content;
            > div {
                width: 100px;
            }
        }
    }
}

.contact_social .contact-icon-social img {
    width: auto;
    margin: 0 !important;
}

.contact_social .row .col-lg-4:first-child .contact-icon-social img {
    margin: unset;
    margin-left: 0 !important;
}

.contact_social .row .col-lg-4:last-child .contact-icon-social img {
    margin: unset;
    margin-right: 0 !important;
}

.ed_map_contact {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.ed_map_contact .ms-content-block {
    position: relative;
}

.ed_map_contact .ms-content-block .ms-content-block__details {
    right: 0;
    display: inline-block;
    width: 100%;
    padding: 0;
    height: 100%;
}

.ed_map_contact .ms-content-block .ms-content-block__details .ms-content-block__cta {
    height: 100%;
}

.ed_map_contact .ms-content-block .ms-content-block__details .ms-content-block__cta>a {
    width: 100%;
    height: 100%;
    margin: 0;
    border: 0;
    background: transparent;
    transition: all .5s ease;
}

.ed_map_contact .ms-content-block .ms-content-block__details .ms-content-block__cta>a:hover,
.ed_map_contact .ms-content-block .ms-content-block__details .ms-content-block__cta>a:focus {
    background: rgba(0, 0, 0, 0.3);
    border: 0;
}

.content_contact_left {
    position: relative;
    box-shadow: none !important;
    transition: all 0.5s ease;
    @media (max-width: 992px) {
        margin-bottom: 50px;
    }
    .ms-content-block__image{
        display: none;
    }
}

.content_contact_left .ms-content-block__details {
    position: relative;
    iframe{
        filter: grayscale(100%);
    }
}

.content_contact_left .ms-content-block__details .ms-content-block__cta {
    height: 100%;
}

.content_contact_left .ms-content-block__details .ms-content-block__cta a {
    width: 100%;
    height: 100%;
}

.content_contact_left:hover {
    box-shadow: none;
}

.content_contact_right {
    .txt_dedicated {
        margin-bottom: 60px;
        h2 {
            font-weight: 300;
            font-size: 30px;
            line-height: 36px;
            letter-spacing: -0.02em;
            color: #292929;
            margin-bottom: 20px;
        }
        h3 {
            font-size: 16px;
            line-height: 20px;
            color: #292929;
            margin-bottom: 15px;
        }
        p {
            font-size: 16px;
            line-height: 20px;
            color: #292929;
            margin-bottom: 0;
        }
    }
}


/* end css for contact us  */


/* css for timeline  */

.page-timeline {
    margin-bottom: 300px;
    position: relative;
    @media (max-width: 992px) {
        padding-right: 15px;
        &::after {
            position: absolute;
            height: 100%;
            content: '';
            left: 15px;
            width: 2px;
            top: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.25);
        }
    }
}

.page-timeline .timeline_left .img-year-timeline {
    padding-right: 100px;
    padding-bottom: 100px;
    border-right: 2px solid rgba(0, 0, 0, 0.25);
    @media (max-width: 992px) {
        border-right: 0px solid rgba(0, 0, 0, 0.25);
        border-left: 0px solid rgba(0, 0, 0, 0.25);
        padding-bottom: 30px;
        padding-right: 0;
        padding-left: 30px;
    }
}

.page-timeline .content-timeline {
    padding-left: 100px;
    padding-top: 50px;
    position: relative;
    @media (max-width: 992px) {
        padding-bottom: 50px;
    }
    ul {
        li {
            h4 {
                font-weight: normal;
                font-size: 18px;
                line-height: 35px;
                letter-spacing: -0.02em;
                color: #292929;
                margin-bottom: 0;
            }
        }
    }
}

.page-timeline .timeline_left .content-timeline {
    padding-left: 100px;
    padding-top: 50px;
    position: relative;
    @media (max-width: 992px) {
        border-left: 0px solid rgba(0, 0, 0, 0.25);
        padding-top: 0 !important;
        padding-left: 70px;
    }
}

.page-timeline .timeline_left .content-timeline:after {
    content: '';
    position: absolute;
    width: 50px;
    height: 2px;
    background: rgba(60, 47, 47, 0.25);
    left: 0;
    top: 85px;
    @media (max-width: 992px) {
        top: 25px;
        left: 15px;
        width: 40px;
    }
}

.page-timeline .timeline_left .content-timeline h3 {
    font-weight: 400;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: -0.03em;
    color: #292929;
    margin-bottom: 30px;
    @media (max-width: 992px) {
        margin-bottom: 0;
    }
}

.page-timeline .timeline_left .content-timeline p {
    font-weight: normal;
    font-size: 16px;
    line-height: 35px;
    letter-spacing: -0.02em;
    margin-bottom: 0;
    color: #292929;
}

.page-timeline .timeline_right .img-year-timeline {
    padding-left: 100px;
    @media (max-width: 992px) {
        padding-left: 30px;
    }
}

.page-timeline .timeline_right .img-year-timeline img,
.page-timeline .timeline_left .img-year-timeline img {
    width: auto;
    margin: auto;
    @media (max-width: 768px) {
        width: 100%;
    }
}

.page-timeline .timeline_right .content-timeline {
    padding-right: 100px;
    padding-top: 50px;
    position: relative;
    text-align: right;
    border-right: 2px solid rgba(0, 0, 0, 0.25);
    padding-bottom: 150px;
    @media (max-width: 992px) {
        text-align: left;
        border-right: 0;
        padding-right: 0;
        padding-left: 70px;
    }
}

.page-timeline .timeline_right .content-timeline:after {
    content: '';
    position: absolute;
    width: 50px;
    height: 2px;
    background: rgba(60, 47, 47, 0.25);
    right: 0;
    top: 85px;
    @media (max-width: 992px) {
        right: unset;
        left: 15px;
        top: 70px;
        width: 40px;
    }
}

.page-timeline .timeline_right .content-timeline h3 {
    font-weight: 400;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: -0.03em;
    color: #292929;
    margin-bottom: 30px;
}

.page-timeline .timeline_right .content-timeline p {
    font-weight: normal;
    font-size: 16px;
    line-height: 35px;
    letter-spacing: -0.02em;
    color: #292929;
}

.padingbotom100 {
    padding-bottom: 250px !important;
}

.padingbotom150 {
    padding-bottom: 150px !important;
}


/* end css for timeline  */

.ed-padding{
    .txt-section-1-right{
        padding-left: 10% !important;
    }
    .txt-section-1-left{
        margin: 0 0 0 10% !important;
    }
    .txt-section-1-right h3{
        font-weight: normal;
        font-size: 14px;
        letter-spacing: 2px;
        line-height: 17px;
        color: #292929;
        text-transform: uppercase;
        margin-bottom: 50px;
        @media (max-width: 992px) {
            margin-bottom: 30px;
        }
    }
}
.css-table-technical{
    .ms-content-cms__table-row td:nth-child(1){
        width: 0% !important;
    }
    .ms-content-cms__table-row td{
        width: 1% !important;

    }
}
