.my-dashboard-body {
    .msc-invoices-list__container__content__table {
        td {
            a {
                color: #DD8797
            }
        }
    }
    .ms-order-history.tab_order_history {
        table.ms-order-history__table-list {
            td {
                a {
                    color: #DD8797
                }
            }
        }
    }
    .ms-product-specification__table {
        .dashboard-my-library-tbody {
            .col-link1,
            .col-link2 {
                display: none;
            }
        }
    }
    .ms-cms-dashboard-my-recent-orders {
        .ms-order-history {
            .ms-order-history__header {
                .ms-order-history__heading {
                    text-transform: unset;
                }
            }
        }
    }
    .tab-user-dashboard {
        .ms-text-block,
        .category-title,
        .msc-cartline-top-section-wraper,
        .msl-library-dashboard-section-wraper,
        .ms-favourite-heading,
        .ms-order-history__header {
            p,
            h2,
            h6 {
                font-weight: 600;
                font-size: 18px;
                line-height: 20px;
                letter-spacing: -0.03em;
                color: #292929;
                border-bottom: 0;
            }

            h6 {
                margin-bottom: 0;
                padding: 0;
            }
        }
    }


    .ms-favourite-heading {
        margin-bottom: 40px;
    }

    .dashboard-current-cart-thead,
    .dashboard-my-library-thead {
        tr {
            border-bottom: 0 !important;
        }
        th {
            border-bottom: 1px solid rgba(32, 32, 32, 0.1) !important;
        }
    }
}
