[dir="rtl"] {
    $msv-tile-list-flipper-position: -15px;
    $msv-tile-list-title-initial-position-right: 30%;
    $msv-tile-list-thumbnail-image-initial-position-right: 10%;
    $msv-tile-list-title-effect-position-right: 10%;
    $msv-tile-list-text-effect-position-right: 10%;
    $msv-tile-list-links-effect-position-right: 10%;
    $rtl-ms-tile-list-item-margin-right-desktop:0;
    $rtl-ms-tile-list-item-margin-right-ipad:0;
    $rtl-ms-tile-list-item-margin-left-desktop:20px;
    $rtl-ms-tile-list-item-margin-left-ipad:24px;

    .ms-tile-list {
        .msc-ss-carousel {
            position: relative;

            .msc-ss-carousel-slide {
                float: left;
            }

            .msc-flipper {
                &.msc-ss-carousel__flipper {
                    right: -30px;
                    left: unset;

                    @media screen and (max-width: $msv-breakpoint-l) {
                        right: $msv-tile-list-flipper-position;
                    }
                }

                &.msc-ss-carousel__flipper--next {
                    right: auto;
                    left: -30px;

                    @media screen and (max-width: $msv-breakpoint-l) {
                        left: $msv-tile-list-flipper-position;
                    }
                }
            }

            .ms-tile__item {
                margin-left: $rtl-ms-tile-list-item-margin-left-desktop;
                margin-right: $rtl-ms-tile-list-item-margin-right-desktop;

                @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                    margin-left: $rtl-ms-tile-list-item-margin-left-ipad;
                    margin-right: $rtl-ms-tile-list-item-margin-right-ipad;
                }

                .ms-tile-list__heading {
                    .ms-tile-list__title {
                        right: $msv-tile-list-title-initial-position-right;
                    }
                }

                .ms-tile-list__thumbnail_image {
                    right: $msv-tile-list-thumbnail-image-initial-position-right;
                    left: auto;
                }

                .ms-tile-list-container {
                    &:hover,
                    &:focus,
                    &:focus-within {
                        .ms-tile-list__heading {
                            .ms-tile-list__title {
                                right: $msv-tile-list-title-effect-position-right;
                                left: auto;
                            }

                            .ms-tile-list__text {
                                right: $msv-tile-list-text-effect-position-right;
                            }

                            .ms-tile-list__links {
                                right: $msv-tile-list-links-effect-position-right;
                            }
                        }
                    }
                }
            }
        }
    }

    .ms-tile-list.b2b-tiles {
        .msc-ss-carousel .ms-tile__item {
            .ms-tile-list-container {
                .ms-tile-list__heading .ms-tile-list__title {
                    right: auto;
                }

                &:hover,
                &:focus {
                    .ms-tile-list__heading .ms-tile-list__title {
                        right: 10%;
                        left: auto;
                    }
                }
            }
        }
    }
}
