
@import "../00-settings/index.scss";
@import "../01-tools/index.scss";
@import "../02-generic/index.scss";

$carousel-width: 100%;
$carousel-strip-width: 100%;
$flipper-top-position: -22px;
$flipper-right-position: 56px;
$flipper-next-right-position: 0.5px;
$flipper-width: 48px;
$flipper-disabled-opacity: 0.4;
$flipper-outline-offset: 2px;
$flipper-border-radius: 50%;
$flipper-border-color: transparent;
$flipper-height-m: 48px;
$flipper-width-m: 48px;

$vert-carousel-height: 100%;
$vert-carousel-strip-width: 100%;
$vert-flipper-height: 40px;
$carousel-control-hover-opacity: 0.9;

.ms-tab {
    position: relative;
    touch-action: pan-y pinch-zoom;
    width: $carousel-width;
    height: auto;

    .ms-tab-tabs-section {
        scroll-behavior: smooth;
    }

    .ms-tab-flipper {
        position: absolute;

        .ms-tab-chevron-left {
            @include add-icon($msv-ChevronLeft);
            @include flipper-icon-decoration();
        }

        .ms-tab-chevron-right {
            @include add-icon($msv-ChevronRight);
            @include flipper-icon-decoration();
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            height: $flipper-height-m;
            width: $flipper-width-m;
        }
        background-color: transparent;
        top: 20px;
    }

    &__flipper {
        color: var(--msv-font-primary-color);
        background: inherit;
        border: $flipper-border-color;
        border-radius: $flipper-border-radius;
        left: auto;
        outline-offset: $flipper-outline-offset;
        top: $flipper-top-position;
        width: $flipper-width;
        z-index: 1;
        transform: translate(0, -50%);
        cursor: pointer;
        &.disabled {
            visibility: hidden;
        }
    }

    &__flipper--next {
        right: $flipper-next-right-position;
    }
}
