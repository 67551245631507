// Import bootstrap css

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/grid";
@import "../00-settings/responsive-breakpoints";

.row {
    margin: 0;
}

div[class^="col"] {
    padding: 0 !important;
    margin: 0 !important;
}
