$msv-breakpoint-modal-m: 450px;
$msv-store-locator-height-m: 650px;
$msv-store-selector-phone-section-padding-top: 13px;
$msv-store-selector-location-line-shop-address-padding-top: 2px;
$msv-store-selector-location-line-shop-address-padding-right: 15px;
$msv-store-selector-input-height: 50px;
$msv-store-selector-button-border-radius: 3px;
$msv-store-selector-input-border-radius: 3px;
$msv-store-selector-input-padding: 15px;
$msv-store-selector-button-height: 52px;
$msv-store-selector-button-width: 52px;
$msv-store-selector-form-width: 75%;
$msv-store-selector-search-form-padding-right: 5px;
$msv-store-selector-search-form-padding: 0 0 30px 0;
$msv-store-selector-search-form-padding-mobile: 0 16px 10px 16px;
$msv-store-selector-location-line-item-margin-top: 25px;
$msv-breakpoint-modal-m: 450px;
$msv-store-selector-search-form-margin-bottom: 5px;
$msv-store-locations-max-height:400px;
$pickup-option-list-margin: 5px;
$pickup-option-list-item-padding-left: 16px;
$pickup-option-list-item-padding-bottom: 10px;
$pickup-option-list-item-padding-top: 7px;
$pickup-option-list-item-padding-right: 5px;
$pickup-option-height: 52px;
$pickup-option-list-margin: 0;
$msv-store-location-pickup-option-line-height: 24px;
$msv-store-selector-input-box-sizing: border-box;

//style presets
:root {
    //Link
    --msv-store-locate-divider-color: #{$msv-gray-300};
}

.ms-store-locate {
    height: $msv-store-locator-height-m;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--msv-store-locate-divider-color);

    .ms-store-select {
        &__header {
            margin: 15px 0 10px 0;

            @media screen and (max-width: $msv-breakpoint-m) {
                /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
                height: auto !important;
                /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
                padding: 12px 16px 25px 16px !important;
                /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
                border: none !important;
            }

            .msc-modal__title {
                @include font-heading-h3-l();
            }
        }

        &__body {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            min-height: 0;
        }

        &__found-locations {
            margin-bottom: 0;
        }

        &__search {
            display: block;
            padding: $msv-store-selector-search-form-padding;

            @media screen and (max-width: $msv-breakpoint-m) {
                padding: $msv-store-selector-search-form-padding-mobile;
            }

            &-form {
                flex: auto;
                display: flex;
                margin-bottom: $msv-store-selector-search-form-margin-bottom;
                position: relative;
                align-items: center;
                padding-right: $msv-store-selector-search-form-padding-right;

                .MicrosoftMap {
                    .as_container_search {
                        left: 0;
                        top: 61px;
                        max-width: 360px;
                        width: 100%;
                    }
                }
            }

            &-see-all-stores {
                color: var(--msv-store-select-link-color);
                text-decoration: underline;

                @include font-body-regular-s();
                cursor: pointer;
                width: fit-content;

                &:hover, &:focus {
                    text-decoration: underline;
                }
            }

            &-input, .msc-form-control {
                @include form-input-box();
                height: unset;
                width: $msv-store-selector-form-width;
                -webkit-appearance: none;

                @include font-body-regular-s();
            }

            &-button {
                @include primary-button-light();

                border-radius: $msv-store-selector-button-border-radius;
                height: $msv-store-selector-button-height;
                min-width: unset;
                order: 2;
                font-size: unset;
                padding: unset;
                width: $msv-store-selector-button-width;
                margin-left: 4px;

                &:hover {
                    cursor: pointer;
                }

                &::before {
                    @include msv-icon();
                    content: $msv-Search;
                    width: $msv-store-selector-button-width;
                    text-align: center;
                }

                &:focus::before {
                    @include msv-icon();
                    content: $msv-Search;
                    width: $msv-store-selector-button-width;
                    text-align: center;
                    position: static;
                    border-radius: unset;
                    padding: 14px 0;
                    margin: 3px;
                }
            }

            .search-btn-disabled {
                background-color: $msv-gray-300;
            }
        }

        &__store-hours-details {
            @include font-content(var(--msv-font-weight-light),
            var(--msv-store-select-text-font-size), $msv-line-height-m);
            display: flex;
            flex-wrap: wrap;

            .ms-store-locate__store-hours-day {
                flex: 0 0 40%;
            }

            .ms-store-locate__store-hours-time {
                flex: 0 0 60%;
            }
        }

        &__toggle-view {
            display: none;
            cursor: pointer;
            color: $msv-link-color;

            &:hover {
                text-decoration: underline;
            }
        }

        &__locations {
            max-height: $msv-store-locations-max-height;
            overflow-y: scroll;
            padding: 0 17px 30px 0;
            margin-right: 5px;
        }

        &__location {
            padding: 20px 50px;
            border: 0.5px solid var(--msv-store-select-divider-color);
            border-radius: 10px;
            margin-bottom: 10px;
            position: relative;

            &-wrapper {
                position: relative;
                margin-bottom: 32px;
                padding-bottom: 32px;
                border-bottom: 1px solid $msv-gray-210;
                display: flex;
                flex-direction: column;

                @media (max-width: $msv-breakpoint-m) {
                    margin-bottom: 20px;
                    padding-bottom: 20px;
                }

                &:last-child {
                    border-bottom: none;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }

            @media screen and (max-width: $msv-breakpoint-m) {
                padding: 14px 0;
            }

            @include add-icon($msv-store, before);

            &::after {
                width: 40px;
                height: 40px;
                content: "";
                position: absolute;
                left: 0;
                border-radius: 0 8px 8px 0;
                border: 0.5px solid $msv-gray-50;
                border-left: none;
                top: 15px;
            }

            &::before {
                position: absolute;
                top: 27px;
                left: 10px;
                color: $msv-gray-20;
                z-index: 1;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &-line-item {
                color: var(--msv-store-select-text-font-color);
                font-size: var(--msv-store-select-text-font-size);
                position: relative;

                &-store-hours {
                    width: 100%;
                    margin-right: 15px;
                }

                &-contact-info {
                    width: 100%;
                    margin-right: 15px;
                }

                &-set-as-preferred-store {
                    border: none;
                    color: var(--msv-store-select-link-color);
                    padding: 13px 50px;
                    outline: none;
                    background-color: transparent;
                    cursor: pointer;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    position: relative;
                    top: 0;
                    right: 0;
                    margin-top: 14px;
                    justify-content: center;
                    flex-direction: row-reverse;
                    left: auto;

                    @include font-body-regular-s();

                    @media screen and (max-width: $msv-breakpoint-m) {
                        position: relative;
                        border-top: 1px solid var(--msv-store-select-divider-color);
                        width: 100%;
                        padding: 13px 50px;
                        margin-top: 14px;
                        justify-content: center;
                        flex-direction: row-reverse;
                        left: auto;
                        top: 0;
                        right: auto;
                    }

                    &:hover {
                        text-decoration: none;
                    }

                    &:focus {
                        border: 1px solid $msv-blue;
                        border-radius: 100px;
                    }

                    &::after {
                        width: 24px;
                        height: 24px;
                        display: inline-block;
                        border: 0.5px solid $msv-gray-50;
                        border-radius: 3px;
                        box-sizing: border-box;
                        padding: 2px;
                        content: "";
                        position: absolute;
                        left: 16px;
                        top: 14px;

                        @media screen and (max-width: $msv-breakpoint-m) {
                            left: auto;
                            position: relative;
                            margin-right: 10px;
                            top: 0;
                        }
                    }
                }

                &-preferred-store {
                    margin-top: 14px;
                    position: relative;
                    top: 0;
                    right: auto;
                    padding: 13px 50px;
                    align-items: center;
                    border-top: 1px solid var(--msv-store-select-divider-color);
                    width: 100%;
                    justify-content: center;
                    flex-direction: row-reverse;
                    display: flex;

                    @include font-body-regular-s();
                    cursor: pointer;
                    border: 1px solid transparent;
                    outline: none;
                    background-color: transparent;

                    @media screen and (max-width: $msv-breakpoint-m) {
                        position: relative;
                        border-top: 1px solid var(--msv-store-select-divider-color);
                        width: 100%;
                        padding: 13px 50px;
                        margin-top: 14px;
                        justify-content: center;
                        flex-direction: row-reverse;
                        display: flex;
                        right: auto;
                        top: 0;
                    }

                    &:hover {
                        text-decoration: none;
                    }

                    &:focus {
                        border: 1px solid $msv-blue;
                        border-radius: 100px;
                    }

                    &::before {
                        position: absolute;
                        left: 23px;
                        top: 15px;
                        border: solid $msv-black;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                        width: 10px;
                        height: 18px;
                        content: "";

                        @media screen and (max-width: $msv-breakpoint-m) {
                            left: -125px;
                            top: -2px;
                            position: relative;
                        }
                    }

                    &::after {
                        width: 24px;
                        height: 24px;
                        display: inline-block;
                        border: 0.5px solid $msv-gray-50;
                        border-radius: 3px;
                        box-sizing: border-box;
                        padding: 2px;
                        content: "";
                        position: absolute;
                        left: 16px;
                        top: 14px;

                        @media screen and (max-width: $msv-breakpoint-m) {
                            left: auto;
                            top: auto;
                            position: relative;
                            margin-right: 10px;
                        }
                    }
                }
            }

            &-line-header {
                margin-top: 10px;
                margin-bottom: 13px;

                @include font-body-bold-s();
            }

            &-line-stock-status {
                margin-bottom: 13px;

                @include font-body-regular-s();
            }

            &-line-item-header {
                margin-bottom: 28px;

                @media screen and (max-width: $msv-breakpoint-m) {
                    margin-bottom: 25px;
                    padding: 0 50px;
                }
            }

            &-line-item-store-name {
                color: var(--msv-store-select-title-font-color);

                @include font-heading-h6-l();
            }

            &-line-item-store-index {
                color: var(--msv-store-select-title-font-color);

                @include font-heading-h6-l();
                margin-right: 5px;
                display: none;

                &::after {
                    content: ".";
                }
            }

            &-line-item-store-distance {
                @include font-body-regular-xs();
                color: var(--msv-store-select-distance-color);
                padding-left: 12px;
            }

            &-line-store-distance {
                color: $msv-gray-500;
            }

            &-line-item-content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            &-line-shop-address {
                &-section {
                    display: flex;
                }

                &-glyph-icon {
                    padding-top: $msv-store-selector-location-line-shop-address-padding-top;
                    padding-right: $msv-store-selector-location-line-shop-address-padding-right;

                    @include add-icon($msv-Shop-Address);
                }

                &-text {
                    @include font-body-regular-s();
                    white-space: pre-wrap;
                    text-decoration: underline;
                }
            }

            &-line-shop-phone {
                &-section {
                    display: flex;
                    padding-top: $msv-store-selector-phone-section-padding-top;
                }

                &-icon {
                    padding-top: $msv-store-selector-location-line-shop-address-padding-top;
                    padding-right: $msv-store-selector-location-line-shop-address-padding-right;

                    @include add-icon($msv-Phone, before);
                }

                &-text {
                    @include font-body-regular-s();
                    text-decoration: underline;
                }
            }

            &-line-select-store {
                @include primary-button-light();
                align-self: flex-end;
            }

            &-line-pickup {
                z-index: 1005;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;

                &-menu {
                    @include primary-button-light();

                    cursor: pointer;
                    align-self: flex-start;
                }

                &-menu:active:focus:hover {
                    border: none;
                    outline: none;
                }

                &-label {
                    @include font-body-bold-s();
                    flex: 0 0 100%;
                    margin-bottom: 7px;
                }

                &-list-toggle {
                    text-align: left;

                    @include form-input-box();
                    @include add-icon($msv-ChevronDown, after);

                    &::after {
                        position: absolute;
                        right: 225px;
                        font-size: 20px;

                        @media (max-width: $msv-breakpoint-m) {
                            right: 18px;
                        }

                        @media (min-width: $msv-breakpoint-m + 1) and (max-width: $msv-breakpoint-l) {
                            right: 255px;
                        }
                    }

                    &:focus {
                        outline: none;

                        &::after {
                            right: 11px;
                        }
                    }
                }

                &[data-selected=true] {
                    .ms-store-select__location-line-pickup-label {
                        @include add-icon($msv-Checkbox-Circle-Checked, after);

                        &::after {
                            position: relative;
                            top: 48px;
                            z-index: 10;
                            left: -85px;
                            font-size: 20px;
                            color: var(--ms-pickup-option-icon-color);
                        }
                    }

                    .ms-store-select__location-line-pickup-list-toggle {
                        padding-left: 45px;
                    }
                }

                &-list {
                    display: none;

                    &.show {
                        display: block;
                        border: 0.5px solid $msv-gray-50;
                        border-bottom-right-radius: 4px;
                        border-bottom-left-radius: 4px;
                    }

                    &-container {
                        display: flex;
                        flex-direction: column;
                        width: 72%;

                        @media (min-width: $msv-breakpoint-m + 1) and (max-width: $msv-breakpoint-l) {
                            width: 68%;
                        }

                        @media (max-width: $msv-breakpoint-m) {
                            width: 100%;
                            margin-bottom: 15px;
                        }
                    }

                    @include font-body-regular-s();

                    &-item {
                        background-color: var(--msv-store-select-form-bg);

                        &-button {
                            border: none;
                            padding-left: $pickup-option-list-item-padding-left;
                            background-color: $msv-white;
                            width: 100%;
                            text-align: left;
                            height: $pickup-option-height;
                        }

                        &:hover {
                            background-color: $msv-white-20;
                            cursor: pointer;
                        }

                        &__text {
                            width: 100%;

                            &:hover::before {
                                outline: none;
                            }
                        }
                    }

                    &-link {
                        border: none;
                        line-height: $msv-store-location-pickup-option-line-height;
                        padding-bottom: $pickup-option-list-item-padding-bottom;
                        padding-left: $pickup-option-list-item-padding-left;
                        padding-top: $pickup-option-list-item-padding-top;
                        padding-right: $pickup-option-list-item-padding-right;
                        text-align: center;
                        width: 100%;
                        outline: none;

                        @include font-body-regular-s();
                    }

                    &-error {
                        display: none;

                        @include font-body-regular-xs();
                    }
                }

                &[data-error=true] {
                    .ms-store-select__location-line-pickup-list-error {
                        display: flex;
                        align-items: center;
                        margin-top: 12px;
                        color: var(--ms-pickup-option-error-color);

                        @include add-icon($msv-times-circle, before);

                        &::before {
                            margin-right: 10px;
                            font-size: 20px;
                        }
                    }
                }
            }
        }

        &__footer {
            position: absolute;
            bottom: -15px;
            width: 100%;
        }

        &__terms-link {
            @include font-body-regular-s();
            color: var(--msv-store-select-link-color);
            text-decoration: underline;
            border: none;
        }
    }

    abbr {
        text-decoration: none;
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .ms-store-locate {
        height: auto;
        border: none;

        .ms-store-select {
            &__search {
                padding: 0 0 15px 0;
            }

            &__locations {
                display: none;
                border-bottom: 1px solid var(--msv-store-locate-divider-color);
                padding: 0 8px 20px 16px;
                margin-right: 4px;
                max-height: 100%;

                &.show {
                    display: block;
                }
            }

            &__toggle-view {
                display: block;
                background-color: transparent;
                float: right;
                border: none;
                outline: none;
            }

            &__footer {
                &.map-only-view {
                    bottom: -660px;
                }
            }
        }
    }
}

.ms-map {
    height: $msv-store-locator-height-m;

    &__body {
        height: 100%;
        z-index: 0;
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .ms-map {
        display: none;

        &.show {
            display: block;
        }
    }
}
