.ms-text-block.txt-section-1-right.our-story-brand {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.our-story-brand {
    .btn-page {
        padding-left: 30px;
        position: relative;
        cursor: pointer;
        &::before {
            content: "";
            width: 40px;
            height: 45px;
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDIwMDEwOTA0Ly9FTiIKICJodHRwOi8vd3d3LnczLm9yZy9UUi8yMDAxL1JFQy1TVkctMjAwMTA5MDQvRFREL3N2ZzEwLmR0ZCI+CjxzdmcgdmVyc2lvbj0iMS4wIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiB3aWR0aD0iMTI4MC4wMDAwMDBwdCIgaGVpZ2h0PSIxMjgwLjAwMDAwMHB0IiB2aWV3Qm94PSIwIDAgMTI4MC4wMDAwMDAgMTI4MC4wMDAwMDAiCiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0Ij4KPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsMTI4MC4wMDAwMDApIHNjYWxlKDAuMTAwMDAwLC0wLjEwMDAwMCkiCmZpbGw9IiMwMDAwMDAiIHN0cm9rZT0ibm9uZSI+CjxwYXRoIGQ9Ik00NjIwIDY0MDAgYzAgLTIyODYgMyAtMjg2MSAxMyAtMjg1NyA2IDIgOTM5IDU0MCAyMDcyIDExOTQgMTEzMwo2NTQgMjI0MyAxMjk1IDI0NjcgMTQyNCAzMTUgMTgxIDQwNCAyMzcgMzk1IDI0NiAtNyA2IC0zMTEgMTg0IC02NzcgMzk2IC0zNjYKMjExIC0xNDY5IDg0OCAtMjQ1MiAxNDE1IC05ODMgNTY4IC0xNzk0IDEwMzUgLTE4MDMgMTAzOCAtMTMgNiAtMTUgLTI4OCAtMTUKLTI4NTZ6Ii8+CjwvZz4KPC9zdmc+Cg==) no-repeat center center;
            background-size: cover;
            position: absolute;
            top: 0;
            left: 10px;
        }
    }
}

#herbal-video-player-popup {
    video {
        height: auto;
    }
}