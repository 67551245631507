.hec-breadcrumb-product {
	display: block;
	.ms-breadcrumb_list {
		float: unset;
		display: flex;
		margin-bottom: 30px;
		padding: 24px 0;
		border-bottom: 1px solid rgba(32, 32, 32, 0.2);
		>li {
			float: unset;
			margin-right: 20px;
			display: inline-block;
			>a {
				font-weight: 300;
				font-size: 12px;
				line-height: 15px;
				color: rgba(41, 41, 41, 0.4) !important;
				text-transform: uppercase !important;
				text-decoration: none !important;
				margin-right: 10px;
                letter-spacing: 0.1em;
				padding-right: 10px;
			}
		}
		>li.breadcrumb_item-current {
			.ms-breadcrumb-no-link-text, span {
                font-weight: 300;
				font-size: 12px;
				line-height: 25px;
                letter-spacing: 0.1em;
				color: #292929 !important;
                text-transform: uppercase;
			}
		}
	}
	@media (max-width: 992px){
		padding: 0 15px;
		.ms-breadcrumb_list{
			>li{
				&:first-child{
					white-space: nowrap;
				}
			}
		}
	}
}

// .breadcrumb_full {
//     margin-top: 165px;
//     @media (max-width: 1199px) {
//         margin-top: 230px;
//     }
//     @media (max-width: 992px) {
//         margin-top: 70px;
//     }
// }
