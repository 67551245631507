$navigation-parent-menu-margin-left: 20px;
$navigation-parent-menu-margin-right: 20px;
$navigation-parent-menu-margin-top: 20px;
$navigation-menu-list-min-width: 250px;
$navigation-menu-item-line-height: 28px;
$navigation-menu-item-level2-line-height: 24px;
$navigation-menu-button-icon-margin-left: 8px;
$navigation-menu-icon-line-height: 40px;
$navigation-menu-wrapper-width: 100%;
$navigation-menu-wrapper-left: 0;
$navigation-menu-image-padding: 40px;
$navigation-ms-nav-list-width: 100%;
$navigation-ms-nav-submenu-margin-left: 15px;
$navigation-ms-nav-area-width: 63%;
$navigation-menu-list-min-width: 180px;
$navigation-ms-nav-list-item-link-margin-bottom: 20px;

$navigation-ms-nav-list-mobile-container-padding-left: 16px;
$navigation-ms-nav-list-mobile-container-padding-right: 34px;
$navigation-ms-nav-list-button-mobile-padding-left: 0;
$navigation-ms-nav-list-button-mobile-padding-right: 0;
$navigation-ms-nav-list-button-before-mobile-font-size: 21px;
$navigation-ms-nav-list-item-link-mobile-margin-top: 16px;
$navigation-ms-nav-list-item-link-mobile-padding-top: 16px;
$navigation-ms-nav-list-item-link-mobile-padding: 16px;
$navigation-ms-nav-list-feature-mobile-padding-bottom: 20px;
$navigation-ms-nav-child-mobile-padding-top: 12px;

$navigation-ms-nav-list-tablet-margin-left: 0;
$navigation-ms-nav-list-item-tablet-margin-left: 0;
$navigation-ms-nav-list-level3-tablet-margin-left: 20px;
$navigation-ms-nav-ms-nav-list-tablet-padding-left: 24px;
$navigation-ms-nav-list-item-link-level2-tablet-margin-bottom: 25px;
$navigation-ms-nav-list-item-link-level3-tablet-margin-bottom: 15px;
$navigation-ms-nav-list-item-tablet-first-child-margin-left: 0;
$navigation-ms-nav-close-button-tablet-padding-right: 16px;
$navigation-ms-nav-close-button-tablet-padding-top: 10px;
$navigation-ms-nav-list-item-link-button-tablet-margin-bottom: 28px;
$navigation-ms-nav-list-item-nav-feature-tablet-padding-left: 24px;
$navigation-ms-nav-list-item-drawer-button-tablet-margin-bottom: 15px;
$navigation-ms-nav-list-item-drawer-collapse-tablet-padding-left: 40px;
$navigation-ms-nav-list-item-link-tablet-padding-zero: 0;
$navigation-ms-nav-list-item-button-first-child-tablet-margin-left: 0;
$navigation-ms-nav-drawer-open-close-tablet-padding: 13px;

$navigation-ms-nav-list-desktop-margin-left: 0;
$navigation-ms-nav-list-item-desktop-margin-left: 0;
$navigation-ms-nav-list-level2-desktop-margin-left: 20px;
$navigation-ms-nav-list-desktop-padding-left: 60px;
$navigation-ms-nav-list-item-link-desktop-margin-bottom: 25px;
$navigation-ms-nav-list-item-link-level2-desktop-margin-bottom: 15px;
$navigation-ms-nav-list-item-button-link-desktop-margin-bottom: 40px;
$navigation-ms-nav-list-item-first-child-desktop-margin-left: 0;
$navigation-ms-nav-default-nav-list-desktop-padding-left: 60px;
$navigation-ms-nav-list-item-nav-feature-desktop-padding-left: 60px;
$navigation-ms-nav-list-item-nav-feature-desktop-padding-right: 60px;
$navigation-ms-nav-list-item-levle2-desktop-padding-right: 20px;
$navigation-ms-nav-list-item-drawer-button-desktop-margin-bottom: 15px;
$navigation-ms-nav-list-item-drawer-collapse-desktop-padding-left: 40px;
$navigation-ms-nav-list-item-link-desktop-padding-left: 0;
$navigation-ms-nav-list-item-link-desktop-padding-right: 0;
$navigation-ms-nav-list-item-button-first-child-desktop-margin-left: 0;
$navigation-ms-nav-drawer-open-desktop-padding-right: 13px;
$navigation-ms-nav-drawer-close-desktop-padding-right: 13px;
$navigation-ms-nav-list-item-link-disable-top-menu-padding-right: 20px;
$navigation-ms-nav-list-item-link-disable-top-menu-padding-bottom: 25px;

$msv-width-onequarter: 25%;

//style presets
:root {
    --msv-nav-bg: var(--msv-accent-brand-color);
    --msv-nav-border: transparent;
    --msv-nav-dropdown-border: transparent;
    --msv-nav-carot-color: var(--msv-font-secondary-color);
    --msv-nav-font-color: var(--msv-font-secondary-color);
    --msv-nav-font-size: var(--msv-body-font-size-m);
    --msv-nav-close-icon-font-size: #{$msv-icon-size-xs};
}

.ms-nav {

    &__list {
        z-index: 1000;

        &.level-2 {
            width: $msv-width-full;
        }

        .level-2 {
            > li {
                @include font-body-regular-m();
                color: var(--msv-nav-font-color);
            }

            &.navmenu-multi-level {
                li.ms-nav__list__item {
                    .havesubmenu {
                        font-weight: normal;
                    }
                }
            }
        }

        &__item {
            @include font-body-regular-m();
            color: var(--msv-nav-font-color);

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .havesubmenu {
                @include font-body-regular-m();
                color: var(--msv-nav-font-color);
            }

            .level-3 {
                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                .havesubmenu {
                    .ms-nav__list {
                        margin-left: $navigation-ms-nav-submenu-margin-left;
                    }
                }
            }

            &__image {
                position: absolute;
                right: 0;
            }
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    > .ms-nav__list {

        > .ms-nav__list__item {

            > .ms-nav__list {
                min-width: $navigation-menu-list-min-width;
            }

            > .ms-nav__feature {
                border: 1px solid var(--msv-nav-dropdown-border);
                height: auto;
                left: $navigation-menu-wrapper-left;
                min-width: $navigation-menu-list-min-width;
                position: absolute;
                outline: none;
                overflow: auto;
                width: $navigation-menu-wrapper-width;
                display: inline-flex;

                > .category-image-container {
                    .category-image {
                        position: relative;
                        margin-right: 3px;

                        .category-image-title {
                            text-align: center;
                            display: none;
                        }

                        picture {
                            + div.category-image-title {
                                display: block;
                            }
                        }

                        .ms-nav__list__item__link {
                            @include feature-link-dark-m();
                            text-align: center;
                            word-break: break-all;
                            white-space: break-spaces;
                            height: auto;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            padding: 0;
                            border-bottom: 3px solid $msv-blue;
                            transform: translate(-50%, -50%);
                            background-color: transparent;
                        }

                        img {
                            margin: 0 auto;
                            margin-bottom: $navigation-ms-nav-list-item-link-margin-bottom;

                            > .ms-nav-image__item {
                                display: block;
                                padding: $navigation-menu-image-padding;
                            }

                            &:hover {
                                transform: scale(1.025);
                                transition: transform 0.4s;
                            }
                        }
                    }
                }
            }
        }
    }
}


