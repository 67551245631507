//spacing
$header-container-max-width: 1440px;
$header-container-spacing-to-bottom-tablet: 10px;
$signin-info-spacing-left: 8px;
$heading-padding-top: 28px;
$heading-padding-bottom: 28px;
$header-height: 48px;
$header-mobile-height: 48px;
$header-icon-width: 40px;
$header-hamburger-icon-width: 21px;
$account-link-height: 35px;
$account-links-max-width: 167px;
$popover-menu-item-height: 48px;
$header-acc-info-desktop-margin-right: 26px;
$header-nav-icon-margin-right-desktop: 10px;
$header-logo-margin-right-desktop: 32px;
$header-cart-count-margin-bottom: 20px;
$header-cart-count-border-radius: 100px;
$header-cart-count-padding: 2px 6px 0 6px;
$header-search-margin-right-desktop: 40px;
$header-locator-margin-right-desktop: 26px;
$header-search-margin-right-tablet: 37px;
$header-search-margin-right-mobile: 25px;
$header-container-topbar-account-info-margin-left: 0;
$header-container-topbar-account-info-signin-button-padding: 0;
$msv-profile-button-text-margin-left: 10.33px;
$msv-pop-over-box-shadow-color: rgba(0, 0, 0, 0.1);
$msv-account-pop-over-box-shadow-color: rgba(0, 0, 0, 0.13);
$header-modal-mobile-hamburger-content-padding: 0;
$header-modal-mobile-hamburger-body-padding: 0;
$header-modal-mobile-hamburger-body-button-padding: 12px 8px;
$header-modal-mobile-close-button-padding-right: 12px;
$header-modal-mobile-close-button-padding-top: 10px;
$header-lock-opaque-desktop-height: 68px;
$header-lock-opaque-padding-top: 12px;
$header-lock-opaque-padding-bottom: 12px;
$header-lock-opaque-collapsible-hamburger-top: 68px;
$header-lock-opaque-search__autoSuggest-margin-top: 12px;
//style presets
:root {
    --msv-header-bg: var(--msv-accent-brand-color);
    --msv-header-font-color: var(--msv-font-secondary-color);
    --msv-header-icon-font-size: #{$msv-icon-size-xs};
}

.msc-modal .ms-header__mobile-hamburger .msc-btn {
    display: block;
}

.ms-header {
    color: #292929;
    &__container {
        margin: 0 auto;
        .ms-header__preferred-store-btn,
        .ms-search__icon,
        .ms-header__signin-button,
        .ms-header__profile-button {
            @include font-body-regular-m();
            @include vfi();
        }
        .ms-header__collapsible-hamburger {
            position: absolute;
            width: 100%;
            left: 0;
            @media (min-width: $msv-breakpoint-l) {
                top: 104px;
            }
            @media (max-width: $msv-breakpoint-l) {
                top: 68px;
            }
            .ms-header__mobile-hamburger-menu-links {
                display: none;
            }
        }
    }
    /* stylelint-disable no-descending-specificity -- Suppressed. */
    @media screen and (min-width: $msv-breakpoint-l) {
        .ms-header__preferred-store-container {
            .ms-header__preferred-store-btn {
                &::before {
                    margin-right: $header-nav-icon-margin-right-desktop;
                }
            }
        }
    }
    @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
        .mobile-navicon-container {
            display: none;
        }
        .ms-nav.desktop-vp {
            display: flex;
            position: relative;
            border-top: 1px solid var(--msv-header-font-color);
        }
        &__container {
            .ms-header__topbar {
                .ms-search {
                    margin-right: $header-search-margin-right-tablet;
                }
                .ms-header__account-info {
                    margin-right: $header-search-margin-right-tablet;
                }
                .ms-header__preferred-store-container {
                    margin-right: 0;
                }
                .ms-header__preferred-store-btn {
                    margin-right: $header-search-margin-right-tablet;
                    &::before {
                        margin-right: 0;
                    }
                }
                .ms-header__container .ms-header__topbar .ms-header__account-info {
                    margin-right: $header-search-margin-right-tablet;
                    margin-top: 0;
                }
            }
        }
    }
}

.ms-header__modal {
    @media (min-width: ($msv-breakpoint-m)-1) {
        display: none;
    }
    .msc-modal {
        .ms-header__mobile-hamburger {
            background: var(--msv-header-bg);
            height: 100%;
            margin: 0;
            max-width: none;
            .msc-modal__content {
                height: 100%;
                padding: $header-modal-mobile-hamburger-content-padding;
                background: var(--msv-header-bg);
                color: var(--msv-header-font-color);
                .ms-header__mobile-hamburger-menu-body {
                    padding: $header-modal-mobile-hamburger-body-padding;
                    .msc-btn {
                        font-size: 16px;
                        height: unset;
                        line-height: 22px;
                        padding: $header-modal-mobile-hamburger-body-button-padding;
                    }
                }
                .msc-modal__header {
                    display: block;
                    height: 18px;
                    .msc-modal__close-button {
                        color: var(--msv-header-font-color);
                        right: 0;
                        margin: 0;
                        opacity: 1;
                        padding-right: $header-modal-mobile-close-button-padding-right;
                        padding-top: $header-modal-mobile-close-button-padding-top;
                        z-index: 1000;
                        &:focus {
                            outline-offset: 0;
                        }
                        &::before {
                            @include msv-icon();
                            content: $msv-Cancel;
                            font-size: 20px;
                            position: unset;
                            text-align: center;
                            vertical-align: text-bottom;
                        }
                        span {
                            display: none;
                        }
                    }
                }
            }
            .ms-header__mobile-hamburger-menu-links {
                display: none;
            }
        }
    }
}

.skip-to-main {
    @include font-body-regular-s();
    color: $msv-black;
}

.ms-header.lock-opaque {
    top: 0;
    position: fixed;
    z-index: 1010;
    width: 100%;
    box-shadow: $msv-depth8;
    .ms-header__collapsible-hamburger.show {
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        min-height: auto !important;
        height: 100vh;
        overflow: auto;
    }
    @media screen and (min-width: $msv-breakpoint-l) {
        height: $header-lock-opaque-desktop-height;
        padding-top: $header-lock-opaque-padding-top;
        padding-bottom: $header-lock-opaque-padding-bottom;
        .ms-search__autoSuggest {
            margin-top: $header-lock-opaque-search__autoSuggest-margin-top;
        }
        .ms-header__collapsible-hamburger {
            top: $header-lock-opaque-collapsible-hamburger-top;
        }
    }
}

.ed-border {
    border-bottom: 1px solid rgba(32, 32, 32, 0.2) !important;
}

.private-top-header {
    padding-top: 6.5px;
    padding-bottom: 6.5px;
    .ms-search {
        justify-content: start;
        display: block;
        width: 300px;
        display: block;
        button {
            .ms-search__icon-text {
                font-size: 12px;
                color: #292929;
            }
            background: transparent;
            color: #000;
            padding-left: 0;
            border: 0;
        }
        input {
            outline: 0px solid #cecece;
            border: 0;
            font-size: 12px;
            color: #292929;
            background: transparent;
        }
    }
    .private-top-social {
        height: 100%;
        padding-right: 0;
        .row {
            justify-content: flex-end;
            align-items: center;
            height: 100%;
            .ms-content-block {
                margin-left: 20px;
            }
            .ms-text-block {
                margin-left: 20px;
                p {
                    font-weight: normal;
                    font-size: 12px;
                    color: #292929;
                    margin-bottom: 0;
                }
            }
            .ms-content-block .ms-content-block__image {
                max-width: 17px;
            }
        }
    }
}

.ed-border-public {
    // position: relative;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    // z-index: 999;
}

.private-menu-parent {
    padding-top: 20px;
    padding-bottom: 20px;
}

.private-menu-parent>.row {
    align-items: center;
}

.private-menu-parent .private-menu-left .ms-text-block {
    margin-right: 40px;
}

.private-menu-parent .private-menu-right .ms-text-block {
    margin-left: 40px;
}

.private-menu-parent .private-menu-right>.row {
    justify-content: flex-end;
    flex-wrap: nowrap;
}

.private-menu-parent .private-menu-child p {
    font-weight: normal;
    font-size: 14px;
    color: #292929;
    text-transform: uppercase;
}

.private-menu-parent .logo-page {
    width: 155px;
    margin: auto;
}
header{
    .herbal-top-header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        padding-top: 5px;
        padding-bottom: 5px;
        .ms-nav {
            padding: 8px 0;
            width: 100%;
            >ul.ms-nav__list {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                padding-left: 15px;
                padding-right: 15px;
                align-items: center;
                >li.ms-main-menu_item {
                    >ul {
                        display: flex;
                        flex-wrap: nowrap;
                        >li {
                            a,
                            span {
                                font-weight: 300;
                                font-size: 13px;
                                line-height: 15px;
                                color: #fff;
                                transition: all .5s;
                                margin-bottom: 0;
                                position: relative;
                                z-index: 9999;
                                font-family: 'Inter', sans-serif !important;
                                text-decoration: none;
                                display: block;
                            }
                        }
                    }
                    &.left-section {
                        >ul {
                            li {
                                margin-right: 40px;
                                margin-top: 0 !important;
                            }
                        }
                    }
                    &.right-section {
                        >ul {
                            li {
                                text-align: right;
                                margin-left: 40px;
                                margin-top: 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .header-menu-resizing {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
}
.admin-banner-header {
    #renderPage {
        >header {
            position: sticky;
            top: 0;
        }
    }
}

header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: transparent;
    z-index: 99999;
    transition: all .5s;
    &.fixed {
        background: rgba(248, 246, 243, 0.9);
    }
    .public-top-header {
        padding-top: 12.5px;
        padding-bottom: 12.5px;
        .public-top-header-left {
            margin-top: 0;
        }
        .public-top-header-left>div {
            display: flex;
            p {
                font-weight: 300;
                font-size: 12px;
                line-height: 15px;
                color: #fff;
                margin-right: 40px;
                transition: all .5s;
                margin-bottom: 0;
                position: relative;
                z-index: 9999;
                font-family: 'Inter', sans-serif;
                letter-spacing: 1px;
            }
        }
        .public-top-header-right {
            margin-top: 0;
        }
        .public-top-header-right>div {
            display: flex;
            justify-content: end;
            p {
                font-weight: 300;
                font-size: 12px;
                line-height: 15px;
                color: #fff;
                margin-left: 40px;
                transition: all .5s;
                margin-bottom: 0;
                position: relative;
                z-index: 9999;
                font-family: 'Inter', sans-serif;
                letter-spacing: 1px;
            }
        }
    }
    .ed-border-public .private-menu-child .ms-text-block p {
        font-size: 14px;
        line-height: 17px;
        text-align: right;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #fff;
        font-weight: 300;
    }
    &.fixed {
        .public-top-header {
            .public-top-header-left>div {
                p {
                    color: #292929;
                }
            }
            .public-top-header-right>div {
                p {
                    color: #292929;
                }
            }
        }
        .ed-border-public {
            border-bottom: 1px solid rgba(32, 32, 32, 0.2);
        }
        .herbal-top-header {
            border-bottom: 1px solid rgba(32, 32, 32, 0.2);
            .ms-header__topbar {
                a.ms-nav__list__item__link {
                    color: #292929;
                }
            }
            .ms-nav {
                padding: 8px 0;
                >ul.ms-nav__list {
                    >li.ms-main-menu_item {
                        >ul {
                            display: flex;
                            flex-wrap: nowrap;
                            >li {
                                a,
                                span {
                                    color: #292929;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .navigation_menu_page {
        .desktop-vp {
            padding: 15px 0;
            width: 100%;
            &.parent {
                ul.ms-nav__list {
                    justify-content: space-between;
                    align-items: center;
                    position: static;
                    padding-left: 0;
                    display: flex;
                    li.ms-main-menu_item {
                        margin: 0;
                        &.left-section {
                            ul.ms-nav__list {
                                >li.ms-nav__list__item {
                                    margin-right: 40px;
                                    margin-top: 0;
                                }
                            }
                        }
                        &.right-section {
                            ul.ms-nav__list {
                                >li.ms-nav__list__item {
                                    margin-left: 40px;
                                    margin-top: 0;
                                }
                            }
                        }
                        ul.ms-nav__list {
                            display: flex;
                            flex-wrap: nowrap;
                            li.ms-nav__list__item {
                                button,a {
                                    background: transparent;
                                    font-size: 14px;
                                    line-height: 17px;
                                    transition: all .5s;
                                    letter-spacing: 1px;
                                    text-transform: uppercase;
                                    color: #fff;
                                    font-weight: 300;
                                    height: auto;
                                    border: 0;
                                    position: relative;
                                    padding: 0;
                                    &::after {
                                        display: none;
                                    }
                                    &::before {
                                        content: '';
                                        position: absolute;
                                        width: 0;
                                        left: 0;
                                        right: 0;
                                        background: #fff;
                                        transition: all .5s;
                                        bottom: -5px;
                                        height: 1px;
                                    }
                                    &:hover,
                                    &:focus {
                                        &::before {
                                            width: 100%;
                                        }
                                    }
                                }
                                .ms-nav__deafult {
                                    // // height: 77%;
                                    // @media (min-width: 1199px) {
                                    //     height: 85%;
                                    // }
                                   
                                    @media (min-width: 993px) {
                                        position: fixed;
                                        left: 0;
                                        z-index: -1;
                                        width: 100%;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -30%);
                                        // background: red;
                                        transition: all 1s;
                                        ul.ms-nav__list {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            flex-direction: column;
                                            width: 100%;
                                            height: 100%;
                                            // transform: translateY(45px);
                                            li.ms-nav__list__item {
                                                margin-bottom: 30px;
                                                margin-right: 0;
                                                margin-left: 0;
                                                span,
                                                a {
                                                    font-weight: normal;
                                                    background: transparent;
                                                    text-decoration: none;
                                                    font-size: 24px;
                                                    line-height: 29px;
                                                    text-align: center;
                                                    margin: 0;
                                                    padding: 0;
                                                    // transform: translateX(-15px);
                                                    // margin-right: 40px;
                                                    letter-spacing: -0.03em;
                                                    text-transform: unset;
                                                    color: #F8F6F3;
                                                    display: block;
                                                    height: 29px;
                                                }
                                                &:after {
                                                    display: none;
                                                }
                                            }
                                            span.ms-sub-menu-close-btn {
                                                color: transparent !important;
                                                position: relative;
                                                &::before {
                                                    content: "×";
                                                    position: absolute;
                                                    color: #fff;
                                                    transform: translateX(8px);
                                                    font-size: 45px;
                                                    cursor: pointer;
                                                }
                                            }
                                        }
                                    }
                                    @media (min-width: 480px) {
                                        ul.ms-nav__list {
                                            li.ms-nav__list__item {
                                                margin-bottom: 20px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.logo {
                            .ms-site-logo {
                                a {
                                    width: 155px;
                                    img {
                                        transition: all .5s;
                                        transform: translateX(10px);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.fixed {
        .navigation_menu_page {
            .desktop-vp {
                &.parent {
                    ul.ms-nav__list {
                        li.ms-main-menu_item {
                            ul.ms-nav__list {
                                li.ms-nav__list__item {
                                    button,a {
                                        color: #292929;
                                        &::before {
                                            background: #292929;
                                        }
                                    }
                                }
                            }
                            &.logo {
                                .ms-site-logo {
                                    a {
                                        width: 155px;
                                        img {
                                            filter: invert(100%);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

body {
    &.changeColorHeader {
        .public-top-header {
            .public-top-header-left>div {
                p {
                    color: #fff;
                }
            }
            .public-top-header-right>div {
                p {
                    color: #fff;
                }
            }
        }
        .herbal-top-header {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            .ms-nav {
                padding: 8px 0;
                >ul.ms-nav__list {
                    >li.ms-main-menu_item {
                        >ul {
                            display: flex;
                            flex-wrap: nowrap;
                            >li {
                                a,
                                span {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
        .ed-border-public {
            border-bottom: 1px solid rgba(32, 32, 32, 0.2);
        }
        .navigation_menu_page {
            .desktop-vp {
                &.parent {
                    ul.ms-nav__list {
                        li.ms-main-menu_item {
                            ul.ms-nav__list {
                                li.ms-nav__list__item {
                                    button,a {
                                        color: #fff;
                                        &::before {
                                            background: #fff;
                                        }
                                    }
                                }
                            }
                            &.logo {
                                .ms-site-logo {
                                    a {
                                        width: 155px;
                                        img {
                                            filter: invert(0%);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// css for header private
.private_mt300 {
    // margin-top: 250px;
    // @media (max-width: 992px) {
    //     margin-top: 150px;
    // }
}

header {
    .herbal-top-header.ed-border {
        ul.ms-nav__list>li.ms-nav__list>.ms-nav__list__item__link,
        .ms-nav>ul.ms-nav__list>li.ms-main-menu_item>ul>li a,
        .ms-nav>ul.ms-nav__list>li.ms-main-menu_item>ul>li span {
            color: #292929;
        }
    }
    .ed-border {
        background: rgba(248, 246, 243, 0.9);
        &:first-child {
            .private-top-header {
                .ms-search.with-submit {
                    .search__form {}
                    .ms-search__autoSuggest {
                        max-height: 400px;
                        transition: all .5s;
                        &.hide {
                            opacity: 0;
                            visibility: hidden;
                        }
                        &.show {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
        &:last-child {
            .navigation_menu_page {
                .desktop-vp {
                    padding: 15px 0;
                    &.parent {
                        ul.ms-nav__list {
                            justify-content: space-evenly;
                            align-items: center;
                            position: static;
                            padding-left: 0;
                            li.ms-main-menu_item {
                                margin: 0;
                                &.left-section {
                                    ul.ms-nav__list {
                                        >li.ms-nav__list__item {
                                            margin-right: 40px;
                                        }
                                    }
                                }
                                &.right-section {
                                    ul.ms-nav__list {
                                        >li.ms-nav__list__item {
                                            margin-left: 40px;
                                        }
                                    }
                                }
                                ul.ms-nav__list {
                                    display: flex;
                                    flex-wrap: nowrap;
                                    li.ms-nav__list__item {
                                        white-space: nowrap;
                                        button,
                                        span,
                                        a {
                                            cursor: pointer;
                                            background: transparent;
                                            font-size: 14px;
                                            line-height: 17px;
                                            transition: all .5s;
                                            text-decoration: none;
                                            letter-spacing: 1px;
                                            color: #292929;
                                            font-weight: 300;
                                            height: auto;
                                            border: 0;
                                            position: relative;
                                            padding: 0;
                                            @media (min-width: 993px) {
                                                text-transform: uppercase;
                                            }
                                            &::after {
                                                display: none;
                                            }
                                            &::before {
                                                content: '';
                                                position: absolute;
                                                width: 0;
                                                left: 0;
                                                right: 0;
                                                background: #292929;
                                                transition: all .5s;
                                                bottom: -5px;
                                                height: 1px;
                                            }
                                            &:hover,
                                            &:focus {
                                                &::before {
                                                    width: 100%;
                                                }
                                            }
                                        }
                                    }
                                }
                                &.logo {
                                    .ms-site-logo {
                                        a {
                                            width: 155px;
                                            img {
                                                transition: all .5s;
                                                filter: invert(100%);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // @media (max-width: 992px) {
    //     display: none !important;
    // }
}

.header-top-sigin {
    p {
        color: #292929 !important;
    }
    .public-top-header-right>div p:first-child::after {
        background: rgba(32, 32, 32, 0.2) !important;
    }
}

body {
    .navigation_menu_page {
        padding: 0;
        @media (min-width: 768px) {
            max-width: 100%;
        }
    }
    &.overBody {
        position: relative;
        &::before{
            position: absolute;
            content: '';
            background: #292929;
            width: 100%;
            height: 100%;
            z-index: 10;
        }
        header{
            height: 100%;
        }
        .header-top-sigin {
            p {
                color: #fff !important;
            }
        }
        .header-signin {
            li.ms-nav__list__item {
                button {
                    color: #fff !important;
                }
            }
            img {
                filter: invert(0%) !important;
            }
        }
        .ed-border {
            background: #292929;
            &.herbal-top-header {
                border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
            }
            &.header-menu-resizing {
                border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
            }
            a,
            span,
            button {
                color: #fff !important;
                &::before {
                    color: #fff !important;
                }
            }
            img {
                filter: invert(0%) !important;
            }
            .header-signin {
                li.ms-nav__list__item {
                    button {
                        color: #fff !important;
                    }
                }
            }
            .ms-header__signout-button {
                color: #292929 !important;
            }
            .msc-cart-icon {
                &__count {
                    color: #fff !important;
                }
                &::before {
                    color: #fff !important;
                }
            }
            .ms-header__topbar .ms-search input {
                color: #fff;
                &::placeholder {
                    color: #fff;
                }
            }
        }
    }
}

.hec-top-header-custom {
    padding: 7px 0;
    // background: #fff;
    >ul {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        >li {
            &.ms-main-menu_item {
                width: 100%;
                >ul {
                    display: flex;
                    flex-wrap: nowrap;
                    >li {
                        a {
                            color: #292929 !important;
                        }
                    }
                }
            }
            &.left-section {
                >ul {
                    li {
                        margin-right: 40px;
                    }
                }
            }
            &.right-section {
                >ul {
                    justify-content: right;
                    li {
                        margin-left: 40px;
                    }
                }
            }
        }
    }
}

.hec-topHeaderSignin,
.herbal-top-header {
    .ms-header__topbar {
        display: flex;
        justify-content: space-around;
        flex-wrap: nowrap;
        .ms-nav__list__item__link {
            text-decoration: none;
            font-weight: 300;
            font-size: 13px;
            color: #fff
        }
        .ms-search {
            margin-left: 20px;
            @media(max-width: 992px) {
                margin-left: 0px;
            }
            button {
                @media(max-width: 768px) {
                    left: 10px;
                }
            }
            button.ms-search__form-cancelSearch {
                display: none;
            }
            .ms-search__form {
                @media(max-width: 992px) {
                    overflow: hidden;
                    &.bx-show {
                        @media (max-width: 768px) {
                            .ms-search__searchForm {
                                input.ms-search__form-control {
                                    position: fixed;
                                    margin: 0;
                                    width: 100%;
                                    padding: 20px;
                                    color: #fff;
                                    background: #2c262d;
                                    border-bottom: 1px solid #9d9d9d;
                                    left: 0;
                                    top: 72px;
                                    &::placeholder {
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            input {
                // color: #292929;
                border: 0;
                background: transparent;
                font-size: 13px;
                font-family: 'Inter', sans-serif;
                font-weight: 300;
                &:focus {
                    outline: none;
                    box-shadow: none;
                    border-bottom: 1px solid rgba(32, 32, 32, 0.2);
                }
                @media (max-width: 992px) {
                    &:focus {
                        border-bottom: 0;
                    }
                    width: 150px;
                }
            }
            .ms-search__autoSuggest {
                max-height: 60vh;
                overflow-y: auto;
                transition: all .5s;
                @media (max-width: 992px) {
                    position: fixed;
                    top: 70px;
                }
                @media (max-width: 768px) {
                    top: 112px;
                    max-height: 100vh;
                    .msc-autoSuggest__search-title-container {
                        display: none;
                    }
                    .msc-autoSuggest__productResults-empty-mobile-content {
                        display: none;
                    }
                    .ms-search__form-control__clear {
                        display: none;
                    }
                }
                .msc-autoSuggest__results-container {
                    height: 100%;
                }
                &.hide {
                    opacity: 0;
                    visibility: hidden;
                }
                &.show {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        @media (min-width: 993px) {
            .ms-header__nav-icon {
                display: none;
            }
        }
        .col-sm-6 {
            &:first-child {
                display: flex;
                align-items: center;
                @media (max-width: 768px) {
                    > ul.ms-nav__list {
                        margin-left: 16px;
                    }
                }
            }
            &:last-child {
                display: flex;
                justify-content: end;
                align-items: center;
                a {
                    text-decoration: none;
                }
                .ms-signin-info__my-account-button-link {
                    &::before {
                        font-family: "Remix Icon";
                        -moz-osx-font-smoothing: grayscale;
                        -webkit-font-smoothing: antialiased;
                        display: inline-block;
                        font-style: normal;
                        font-variant: normal;
                        font-weight: normal;
                        text-rendering: auto;
                        line-height: 1;
                        content: "\F25F";
                        font-size: 20px;
                        vertical-align: text-bottom;
                        text-align: center;
                        color: #292929;
                    }
                    img {
                        display: none;
                    }
                }
                .msc-wishlist-icon {
                    display: flex;
                    align-items: center;
                    &::before {
                        font-family: "Remix Icon";
                        -moz-osx-font-smoothing: grayscale;
                        -webkit-font-smoothing: antialiased;
                        display: inline-block;
                        font-style: normal;
                        font-variant: normal;
                        font-weight: normal;
                        text-rendering: auto;
                        line-height: 1;
                        content: "\EE0E";
                        font-size: 20px;
                        vertical-align: text-bottom;
                        text-align: center;
                        color: #292929;
                        margin-left: 10px;
                    }
                    @media (max-width: 993px) {
                        &::before {
                            margin-left: 15px;
                        }
                    }
                    span {
                        display: none;
                    }
                }
                .msc-cart-icon {
                    display: flex;
                    position: relative;
                    align-items: center;
                    &::before {
                        font-family: "Remix Icon";
                        -moz-osx-font-smoothing: grayscale;
                        -webkit-font-smoothing: antialiased;
                        display: inline-block;
                        font-style: normal;
                        font-variant: normal;
                        font-weight: normal;
                        text-rendering: auto;
                        line-height: 1;
                        content: "\F117";
                        font-size: 20px;
                        vertical-align: text-bottom;
                        text-align: center;
                        color: #292929;
                        margin-left: 10px;
                    }
                    @media (max-width: 993px) {
                        &::before {
                            margin-left: 20px;
                        }
                    }
                    .msc-cart-icon__count {
                        visibility: visible !important;
                        position: absolute;
                        right: -17px;
                        font-size: 10px;
                        top: -5px;
                        color: #292929;
                        font-weight: bold;
                    }
                }
                .ms-header__signout-button {
                    color: #292929;
                    font-weight: 300;
                    font-size: 13px;
                    line-height: 15px;
                    transition: all .5s;
                    margin-bottom: 0;
                    position: relative;
                    z-index: 9999;
                    font-family: 'Inter', sans-serif;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    margin-left: 23px;
                }
            }
        }
    }
}

header {
    @media (min-width: 993px) {
        nav {
            >button.ms-nav__list__item__button {
                display: none;
            }
        }
        .herbal-top-header {
            .logo-sp {
                display: none;
            }
        }
    }
    // public header + public top header
    @media (max-width: 992px) {
        .herbal-top-header {
            transition: all 1s ease;
            padding-top: 15px;
            padding-bottom: 15px;
            height: 71px;
            position: relative;
            >.row{
                >.col-12 {
                    position: unset;
                }
            }
            .right-section {
                >ul {
                    margin-right: 30px;
                }
            }
            .logo-sp {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 5px;
                z-index: 200;
                img {
                    width: 100px;
                }
            }
            header.ms-header {
                .ms-header__topbar {
                    .with-submit {
                        .ms-search__label {
                            button {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #fff;
                            }
                        }
                    }
                    .ms-header__nav-icon {
                        display: none;
                    }
                    .ms-signin-info__my-account-button-link,
                    .msc-wishlist-icon.ms-header__wishlist-desktop,
                    .msc-cart-icon {
                        position: fixed !important;
                        bottom: 60px !important;
                        left: 20px !important;
                        transition: all .1s ease !important;
                        opacity: 0 !important;
                        visibility: hidden !important;
                        &::before {
                            color: #fff !important;
                            font-size: 30px !important;
                        }
                    }
                    .msc-wishlist-icon.ms-header__wishlist-desktop {
                        left: 60px;
                        margin-left: 20px !important;
                    }
                    .msc-cart-icon {
                        left: 70px !important;
                    }
                    .ms-header__signout-button {
                        position: fixed !important;
                        bottom: 60px !important;
                        right: 20px !important;
                        transition: all .1s ease !important;
                        opacity: 0 !important;
                        visibility: hidden !important;
                        &::before {
                            color: #fff !important;
                        }
                    }
                }
            }
            &.open {
                background: #292929;
                header.ms-header {
                    .ms-header__topbar {
                        .with-submit {
                            .ms-search__label {
                                button {
                                    color: #fff;
                                }
                            }
                            .ms-search__form-submitSearch {
                                color: #fff;
                            }
                            .ms-search__form-control {
                                color: #fff;
                                &::placeholder {
                                    color: #fff;
                                }
                            }
                        }
                        .ms-signin-info__my-account-button-link,
                        .msc-wishlist-icon.ms-header__wishlist-desktop,
                        .msc-cart-icon,
                        .ms-header__signout-button {
                            opacity: 1 !important;
                            visibility: visible !important;
                        }
                    }
                }
                .msc-cart-icon__count {
                    color: #fff !important;
                }
            }
        }
        .ed-border-public {
            nav.mobile-vp {
                >button.ms-nav__list__item__button {
                    position: fixed;
                    top: 10px;
                    right: 15px;
                    color: transparent;
                    background: transparent;
                    border: 0;
                    width: 40px;
                    height: 40px;
                    padding: 0;
                    white-space: nowrap;
                    z-index: 99999;
                    &::after {
                        font-family: "Remix Icon";
                        -moz-osx-font-smoothing: grayscale;
                        -webkit-font-smoothing: antialiased;
                        display: inline-block;
                        font-style: normal;
                        font-variant: normal;
                        font-weight: normal;
                        text-rendering: auto;
                        line-height: 1;
                        content: "\EF3E";
                        font-size: 20px;
                        vertical-align: text-bottom;
                        transition: all 1s ease;
                        text-align: center;
                        color: #fff;
                        position: fixed;
                        right: 17px;
                        z-index: 99999;
                        top: 25px;
                    }
                }
                >ul.ms-nav__list {
                    position: fixed;
                    top: 71px;
                    width: 0%;
                    right: -100%;
                    bottom: 0;
                    background: #292929;
                    padding: 60px 20px 45px;
                    transition: all 1s ease;
                    opacity: 0;
                    visibility: hidden;
                    >li.ms-main-menu_item {
                        button, a {
                            background: transparent;
                            font-size: 20px;
                            line-height: 24px;
                            letter-spacing: -0.03em;
                            color: #F8F6F3;
                            font-weight: normal;
                            outline: none;
                            padding-left: 0;
                            border: 0;
                            
                        }
                        &.logo {
                            display: none;
                        }
                        >ul {
                            >li {
                                margin-bottom: 15px;
                            }
                        }
                    }
                }
                &.child {
                    ul.ms-nav__list {
                        button.ms-nav__list__mobile__container__button {
                            background: transparent;
                            border: 0;
                            padding: 0;
                            margin-bottom: 15px;
                            &::after {
                                font-family: "Remix Icon";
                                -moz-osx-font-smoothing: grayscale;
                                -webkit-font-smoothing: antialiased;
                                display: inline-block;
                                font-style: normal;
                                font-variant: normal;
                                font-weight: normal;
                                text-rendering: auto;
                                line-height: 1;
                                content: "\EA60";
                                font-size: 20px;
                                vertical-align: text-bottom;
                                transition: all 1s ease;
                                color: #fff;
                            }
                        }
                        li.ms-main-menu_item {
                            >ul {
                                li {
                                    margin-bottom: 15px;
                                    a,
                                    span {
                                        background: transparent;
                                        font-size: 20px;
                                        line-height: 24px;
                                        letter-spacing: -0.03em;
                                        color: #F8F6F3;
                                        font-weight: normal;
                                        outline: none;
                                        padding-left: 0;
                                        border: 0;
                                    }
                                }
                            }
                        }
                        .ms-sub-menu-close-btn {
                            display: none;
                        }
                    }
                }
            }
            &.open {
                nav.mobile-vp {
                    >button.ms-nav__list__item__button {
                        &::after {
                            content: "\EB98";
                        }
                    }
                    >ul.ms-nav__list {
                        right: 0 !important;
                        width: 100% !important;
                        opacity: 1 !important;
                        visibility: visible !important;
                        z-index: -1;
                    }
                }
            }
        }
        &.fixed {
            .herbal-top-header {
                transition: all 1s ease;
                .logo-sp {
                    img {
                        filter: invert(100%);
                    }
                }
                header.ms-header {
                    .ms-header__topbar {
                        .with-submit {
                            .ms-search__label {
                                button {
                                    color: #292929;
                                }
                            }
                        }
                    }
                }
                span,
                a {
                    color: #292929 !important;
                }
                &.open {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                    a,
                    span {
                        color: #fff !important;
                    }
                    header.ms-header {
                        .ms-header__topbar {
                            .with-submit {
                                .ms-search__form,
                                .ms-search__label {
                                    button {
                                        color: #fff;
                                        &::before {
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .logo-sp {
                        img {
                            filter: invert(0%);
                        }
                    }
                }
            }
            .ed-border-public {
                nav.mobile-vp {
                    >button.ms-nav__list__item__button {
                        &::after {
                            color: #292929;
                        }
                    }
                    >ul.ms-nav__list {
                        position: fixed;
                        top: 71px;
                        width: 0%;
                        right: -100%;
                        bottom: 0;
                        background: #292929;
                        padding: 60px 20px 45px;
                        transition: all 1s ease;
                        opacity: 0;
                        visibility: hidden;
                        >li.ms-main-menu_item {
                            button {
                                background: transparent;
                                font-size: 20px;
                                line-height: 24px;
                                letter-spacing: -0.03em;
                                color: #F8F6F3;
                                font-weight: normal;
                                outline: none;
                                padding-left: 0;
                                border: 0;
                            }
                            &.logo {
                                display: none;
                            }
                            >ul {
                                >li {
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }
                    &.child {
                        ul.ms-nav__list {
                            button.ms-nav__list__mobile__container__button {
                                background: transparent;
                                border: 0;
                                padding: 0;
                                margin-bottom: 15px;
                                &::after {
                                    font-family: "Remix Icon";
                                    -moz-osx-font-smoothing: grayscale;
                                    -webkit-font-smoothing: antialiased;
                                    display: inline-block;
                                    font-style: normal;
                                    font-variant: normal;
                                    font-weight: normal;
                                    text-rendering: auto;
                                    line-height: 1;
                                    content: "\EA60";
                                    font-size: 20px;
                                    vertical-align: text-bottom;
                                    transition: all 1s ease;
                                    color: #fff;
                                }
                            }
                            li.ms-main-menu_item {
                                >ul {
                                    li {
                                        margin-bottom: 15px;
                                        a,
                                        span {
                                            background: transparent;
                                            font-size: 20px;
                                            line-height: 24px;
                                            letter-spacing: -0.03em;
                                            color: #F8F6F3;
                                            font-weight: normal;
                                            outline: none;
                                            padding-left: 0;
                                            border: 0;
                                        }
                                    }
                                }
                            }
                            .ms-sub-menu-close-btn {
                                display: none;
                            }
                        }
                    }
                }
                &.open {
                    nav.mobile-vp {
                        >button.ms-nav__list__item__button {
                            &::after {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width:768px) {
        .herbal-top-header {
            .logo-sp {
                img {
                    transition: all 1s ease;
                    opacity: 1;
                    visibility: visible;
                }
            }
            nav.parent {
                >ul.ms-nav__list {
                    li.ms-main-menu_item {
                        ul.ms-nav__list {
                            li {
                                white-space: nowrap;
                            }
                        }
                        &.left-section {
                            ul.ms-nav__list {
                                li {
                                    transition: all 1s ease;
                                    opacity: 0;
                                    visibility: hidden;
                                    &:last-child {
                                        position: fixed;
                                        bottom: 100px;
                                        left: 20px;
                                        z-index: 99999;
                                        transition: all 1s ease;
                                        opacity: 0;
                                        visibility: hidden;
                                    }
                                }
                            }
                        }
                        &.right-section {
                            ul.ms-nav__list {
                                position: fixed;
                                bottom: 52px;
                                left: 20px;
                                flex-direction: column;
                                margin: 0;
                                z-index: 99999;
                                transition: all 1s ease;
                                opacity: 0;
                                visibility: hidden;
                                li {
                                    text-align: left;
                                    margin: 0;
                                    margin-bottom: 4px;
                                }
                            }
                        }
                    }
                }
            }
            &.open {
                .logo-sp {
                    img {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
                nav.parent {
                    >ul.ms-nav__list {
                        li.ms-main-menu_item {
                            &.left-section {
                                ul.ms-nav__list {
                                    li {
                                        opacity: 1;
                                        visibility: visible;
                                        &:last-child {
                                            opacity: 1;
                                            visibility: visible;
                                        }
                                    }
                                }
                            }
                            &.right-section {
                                ul.ms-nav__list {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // private header + private top header
    @media (max-width: 992px) {
        .herbal-top-header.ed-border {
            transition: all 1s ease;
            background: #fff;
            .logo-sp {
                img {
                    filter: invert(100%) !important;
                }
            }
            .right-section {
                >ul {
                    margin-right: 30px;
                }
            }
            header.ms-header {
                .ms-header__topbar {
                    .with-submit {
                        .ms-search__label {
                            button {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #292929;
                            }
                        }
                    }
                    .ms-header__nav-icon {
                        display: none;
                    }
                    .ms-signin-info__my-account-button-link,
                    .msc-wishlist-icon.ms-header__wishlist-desktop,
                    .msc-cart-icon {
                        position: fixed;
                        bottom: 30px;
                        left: 20px;
                        transition: all .1s ease;
                        opacity: 0;
                        visibility: hidden;
                        &::before {
                            color: #fff;
                        }
                    }
                    .msc-wishlist-icon.ms-header__wishlist-desktop {
                        left: 60px;
                    }
                    .msc-cart-icon {
                        left: 70px;
                    }
                    .ms-header__signout-button {
                        position: fixed;
                        bottom: 30px;
                        right: 20px;
                        transition: all .1s ease;
                        opacity: 0;
                        font-size: 15px;
                        visibility: hidden;
                        &::before {
                            color: #fff;
                        }
                    }
                }
            }
            a,
            span {
                color: #292929 !important;
            }
            .ms-search{
                a, span{
                    color: #fff !important;
                }
            }
            &.open {
                background: #292929;
                border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
                a,
                span {
                    color: #fff !important;
                }
                .logo-sp {
                    img {
                        filter: invert(0%) !important;
                    }
                }
                header.ms-header {
                    .ms-header__topbar {
                        .with-submit {
                            .ms-search__label {
                                button {
                                    color: #fff;
                                }
                            }
                        }
                        .ms-signin-info__my-account-button-link,
                        .msc-wishlist-icon.ms-header__wishlist-desktop,
                        .msc-cart-icon,
                        .ms-header__signout-button {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
        .ed-border:last-child {
            nav.mobile-vp {
                >button.ms-nav__list__item__button {
                    position: absolute;
                    top: -55px;
                    right: 0px;
                    color: transparent !important;
                    background: transparent;
                    border: 0;
                    width: 40px;
                    height: 40px;
                    padding: 0;
                    z-index: 999;
                    white-space: nowrap;
                    &::after {
                        font-family: "Remix Icon";
                        -moz-osx-font-smoothing: grayscale;
                        -webkit-font-smoothing: antialiased;
                        display: inline-block;
                        font-style: normal;
                        font-variant: normal;
                        font-weight: normal;
                        text-rendering: auto;
                        line-height: 1;
                        content: "\EF3E";
                        font-size: 20px;
                        vertical-align: text-bottom;
                        transition: all 1s ease;
                        text-align: center;
                        color: #292929;
                        position: absolute;
                        right: 17px;
                    }
                }
                .ms-sub-menu-close-btn {
                    display: none;
                }
                >ul.ms-nav__list {
                    position: fixed;
                    top: 0px;
                    width: 0%;
                    right: -100%;
                    bottom: 0;
                    background: #292929;
                    padding: 200px 20px 45px;
                    transition: all 1s ease;
                    opacity: 0;
                    visibility: hidden;
                    button.ms-nav__list__mobile__container__button {
                        background: transparent;
                        border: 0;
                        padding: 0;
                        margin-bottom: 15px;
                        &::after {
                            font-family: "Remix Icon";
                            -moz-osx-font-smoothing: grayscale;
                            -webkit-font-smoothing: antialiased;
                            display: inline-block;
                            font-style: normal;
                            font-variant: normal;
                            font-weight: normal;
                            text-rendering: auto;
                            line-height: 1;
                            content: "\EA60";
                            font-size: 20px;
                            vertical-align: text-bottom;
                            transition: all 1s ease;
                            color: #fff;
                        }
                    }
                    span.ms-nav__list__mobile__container__span {
                        display: none;
                    }
                    >li.ms-main-menu_item {
                        button,
                        span {
                            background: transparent;
                            font-size: 20px;
                            line-height: 24px;
                            letter-spacing: -0.03em;
                            color: #F8F6F3;
                            font-weight: normal;
                            outline: none;
                            padding-left: 0;
                            border: 0;
                            text-transform: uppercase;
                            @media (max-width: 992px){
                                text-transform: capitalize;
                            }
                        }
                        &.logo {
                            display: none;
                        }
                        >ul {
                            li {
                                margin-bottom: 15px;
                            }
                        }
                    }
                }
            }
            &.open {
                nav.mobile-vp {
                    >button.ms-nav__list__item__button {
                        &::after {
                            content: "\EB98";
                            color: #fff;
                        }
                    }
                    >ul.ms-nav__list {
                        right: 0;
                        width: 100%;
                        height: 100vh;
                        opacity: 1;
                        visibility: visible;
                        z-index: -1;
                    }
                }
            }
        }
    }
}
@media (max-width: 992px){
    .msc-price.msc-autoSuggest__productResults-item-text,
    .msc-price.msc-autoSuggest__productResults-item-price{
        color: #fff !important;
    }
}
.ms-business-sign-up__page-error.error.pageLevel{
    display: none;
}
.ms-business-sign-up__form-item .msc-address-form__item{
    position: relative;
    .msc-address-form__alert{
        position: absolute;
        top: 3px;
        align-items: center;
        left: 2px;
        &::before{
            margin-right: 4px;
            font-size: 13px;
        }
    }
}