$msv-herbal-popup-message-width: 100%;
$msv-herbal-popup-message-width-t: 100%;
$msv-herbal-popup-message-width-m: 100%;

//style presets
:root {
    //--msv-herbal-popup-font-color: var(--msv-font-primary-color);
}

.ms-herbal-popup {
    .msc-modal__content {
      padding: 20px 60px;
      background-color: #e2e0d6;
      .ms-herbal-popup__row-wrapper.tow-column {
        gap: 20px;
      }
      .ms-herbal-popup__col-left {
        display: flex;
        flex-direction: column;
        p {
            font-family: 'Test Domaine Sans Text';
            font-weight: normal;
            font-size: 21px;
            text-align: center;
            font-style: italic;
          }
        
        ul {
          width: 60%;
          margin: auto;
          text-align: center;
          list-style: inside;
          font-size: 14px;
          padding-left: 0;
        }
        li + li {
          margin-top: 4px;
        }
        .ms-herbal-popup__content-botton {
          margin-top: auto;
          text-align: center;
          .ms-herbal-popup__content-link {
            padding: 12px 50px;
            width: fit-content;
            background-color: var(--msv-primary-btn-light-bg-color);
            border: 1px solid var(--msv-primary-btn-light-border-color);
            color: var(--msv-primary-btn-light-font-color);
            &:hover {
              background-color: var(--msv-primary-btn-light-hover-bg-color);
              box-shadow: 0 0.6px 1.8px rgba(0, 0, 0, 0.1), 0 3.2px 7.2px rgba(0, 0, 0, 0.13);
              text-decoration: none;
            }
          }
        }
      }
    }
    &__body {
        width: $msv-herbal-popup-message-width;
    }
    &__row-wrapper {
        display: flex;
    }

    @media screen and (max-width: 564px) {
      &__body {
          width: $msv-herbal-popup-message-width-m;
      }
      &__row-wrapper {
          display: flex;
      }
      .msc-modal__content { 
        .ms-herbal-popup__col-left {
            p {
              font-size: 13px;
            }
        
            ul {
              font-size: 10px;
              width: 100%;
            }
        
            li+li {
              margin-top: 0;
            }
            .ms-herbal-popup__content-botton { 
              .ms-herbal-popup__content-link {
                  margin-top: 10px;
                  padding: 8px 20px;
                }
            }
          }
          .ms-herbal-popup__col-right {
            display: flex;
            align-items: center;
          }
      }
    }

    @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
        &__body {
            width: $msv-herbal-popup-message-width-t;
        }
        &__row-wrapper {
            display: flex;
        }
    }
}
