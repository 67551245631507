body {
    @media (min-width: 993px) {
        &.overBody {
            overflow: hidden;
            background: #292929;
            transition: all 1s;

            header.fixed {
                background: #292929;
                transition: all 1s;
            }
        }
    }
    @media (max-width: 768px) {
        .default-container.fluid {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

* {
    font-family: 'Inter', sans-serif;
}

.banner-section-1.banner-howtoorder.revert-row.test_unique_process {
    margin-bottom: 0;
}

.row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.banner-our-story {
    .ms-container-background-image {
        height: 101vh;
        > .row {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.banner-section-2 {
    overflow: hidden;
}

// .paddingLeft10{
//     padding-left: 15px;
// }
.paddingbot300 {
    padding-bottom: 300px !important;
    @media (max-width: 992px) {
        padding-bottom: 50px !important;
    }
}

// ----------------- css for how to order
.banner-how-to-order {
    .ms-container-background-image .ms-text-block h2 {
        width: 100% !important;
    }
}

.banner-howtoorder {
    .box-section-1 {
        .txt-section-1-right {
            h3 {
                font-weight: normal;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.15em;
                color: #292929;
                @media (max-width: 992px) {
                    margin-top: 30px;
                }
            }
        }
    }
}

.howtoorder-distributors {
    margin-bottom: 250px;
    @media (max-width: 992px) {
        padding-left: 15px;
        padding-right: 15px;
    }
    .title-distributors {
        h3 {
            font-weight: 300;
            font-size: 30px;
            line-height: 36px;
            letter-spacing: -0.02em;
            color: #292929;
            margin-bottom: 20px;
        }
        p {
            font-size: 16px;
            line-height: 20px;
            color: #292929;
        }
    }
    .box-distributors {
        .item-distributors {
            padding: 20px 40px 0px 40px;
            border-left: 1px solid rgba(32, 32, 32, 0.2);
            margin-bottom: 40px;
            .ms-content-block__image {
                margin-bottom: 20px;
                img {
                    width: 50%;
                }
            }
            @media (max-width: 992px) {
                padding: 20px 10px 20px 10px;
                .ms-content-block__image {
                    img {
                        width: 70%;
                    }
                }
            }
            .ms-content-block__details {
                h3 {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: 0.15em;
                    text-transform: uppercase;
                    color: #292929;
                    margin: 40px 0;
                }
                h2 {
                    font-weight: normal;
                    font-size: 30px;
                    line-height: 36px;
                    letter-spacing: -0.02em;
                    color: #292929;
                    height: 36px;
                    margin-bottom: 20px;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
                p > a,
                p {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: -0.02em;
                    color: #292929;
                    margin-bottom: 0;
                }
            }
        }
    }
}

// ----------------- end css for how to order
// ----------------- css for research and quality
.section_manufac {
    margin-bottom: 150px;
    .title_manufacturing {
        margin-bottom: 40px;
        > div {
            position: relative;
            h2 {
                font-weight: 500;
                font-size: 26px;
                line-height: 34px;
                letter-spacing: -0.02em;
                color: #292929;
                margin: 0;
            }
            p {
                position: absolute;
                right: 0;
                top: 5px;
                margin: 0;
                font-weight: normal;
                font-size: 13px;
                line-height: 22px;
                letter-spacing: -0.03em;
                color: rgba(32, 32, 32, 0.4);
            }
        }
    }
    .box-manufacturing {
        padding-left: 0 !important;
        padding-right: 0 !important;
        .item-manufacturing {
            margin-bottom: 30px;
            padding: 20px;
            img {
                margin-bottom: 20px;
            }
            h2 {
                font-weight: normal;
                font-size: 13px;
                line-height: 130%;
                letter-spacing: -0.03em;
                margin: 0;
                margin-bottom: 0;
                color: #292929;
            }
        }
        .row {
            display: flex;
            flex-wrap: nowrap;
            .col-12 {
                flex: auto;
                width: calc(100% / 5);
            }
            @media (max-width: 992px) {
                flex-wrap: wrap;
                .col-12 {
                    width: calc(100% / 1);
                }
            }
        }
    }
}

// ----------------- end css for how to order
//----------------- css for intro page
.intro_page {
    .ms-content-block {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 99999;
        opacity: 1;
        visibility: visible;
        .ms-content-container {
            background: #fff;
            h2 {
                text-align: center;
                font-weight: normal;
                font-size: 16px;
                line-height: 21px;
                margin: 0;
                text-align: center;
                letter-spacing: 0.125em;
                color: #292929;
                padding-top: 20px;
                position: fixed;
                top: 20px;
                width: 100%;
            }
            h3 {
                text-align: center;
                font-weight: normal;
                font-size: 16px;
                line-height: 21px;
                margin: 0;
                text-align: center;
                letter-spacing: 0.125em;
                color: #292929;
                position: fixed;
                bottom: 20px;
                width: 100%;
            }
            &::before {
                content: 'PURE PLANT POWER';
                font-family: 'Test Domaine Sans Text';
                font-size: 16px;
                line-height: 21px;
                letter-spacing: 0.125em;
                font-weight: 300;
                transform: rotate(-90deg);
                position: absolute;
                top: 50%;
                left: -40px;
            }
            &::after {
                content: 'GROUNDED IN TRADITION';
                font-family: 'Test Domaine Sans Text';
                font-size: 16px;
                line-height: 21px;
                letter-spacing: 0.125em;
                font-weight: 300;
                transform: rotate(90deg);
                position: absolute;
                top: 50%;
                right: -70px;
            }
        }
        .ms-background-container {
            height: calc(100% + 50px);
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                margin: auto;
                margin-top: 0px;
            }
        }
        &.d-none {
            display: block !important;
            opacity: 0;
            visibility: hidden;
            transition: all 1s;
        }
        @media (max-width: 992px) {
            &::after {
                content: '';
                position: fixed;
                width: 100%;
                height: 40%;
                top: 0;
                background: #fff;
                z-index: -1;
            }
            &::before {
                content: '';
                position: fixed;
                width: 100%;
                height: 33%;
                bottom: 0;
                background: #fff;
                z-index: -1;
            }
            .ms-background-container {
                height: calc(100% + 50px);
                img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                    margin: auto;
                    margin-top: 0px;
                }
            }
        }
    }
}

//----------------- end css for intro page
//----------------- css for Resouces
.resources_page {
    margin-bottom: 100px;
    .title-contact {
        h2 {
            font-weight: 300;
            font-size: 26px;
            line-height: 36px;
            letter-spacing: -0.02em;
            color: #292929;
            margin-bottom: 50px;
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(32, 32, 32, 0.2);
        }
    }
    .row-resources {
        margin-right: -40px;
        .item-resources {
            padding-right: 40px;
            margin-bottom: 40px;
            img {
                margin-bottom: 30px;
            }
            h3 {
                font-weight: normal;
                font-size: 12px;
                letter-spacing: 2px;
                line-height: 17px;
                color: #292929;
                text-transform: uppercase;
                margin-bottom: 30px;
            }
            h2 {
                font-weight: 300;
                font-size: 26px;
                line-height: 36px;
                letter-spacing: -0.02em;
                color: #292929;
            }
        }
    }
}

//----------------- end css for Resouces
//----------------- css for Children's Dosages
.children_page {
    .children_content_right {
        // border-bottom: 1px solid rgba(32, 32, 32, 0.2);
        margin-bottom: 15px;
        .artical_1 {
            margin-bottom: 40px;
        }
        .artical_2 {
            margin-bottom: 40px;
        }
        h2 {
            font-weight: 300;
            font-size: 30px;
            line-height: 36px;
            letter-spacing: -0.02em;
            color: #292929;
            margin-bottom: 20px;
        }
        h3 {
            font-size: 16px;
            line-height: 20px;
            color: #292929;
            font-weight: bold;
            margin-bottom: 0px;
        }
        p {
            font-size: 16px;
            line-height: 20px;
            color: #292929;
            margin-bottom: 0px;
        }
        .children_content_table {
            border-top: 1px solid rgba(32, 32, 32, 0.2);
            padding: 10px 0;
            ul {
                display: flex;
                list-style: none;
                margin: 0;
                padding: 0;
                justify-content: space-between;
                li {
                    font-size: 16px;
                    line-height: 60px;
                    color: #292929;
                    white-space: nowrap;
                    h3 {
                        font-size: 16px;
                        line-height: 60px;
                        color: #fff;
                        width: 60px;
                        height: 60px;
                        text-align: center;
                        background: #ba5b80;
                        font-weight: normal;
                    }
                    // &:nth-child(1) {
                    //     margin-right: 20px;
                    // }
                    // &:nth-child(2) {
                    //     margin-right: 20px;
                    // }
                    &:nth-child(3) {
                        // margin-right: 150px;
                        &::after {
                            font-family: 'Remix Icon';
                            -moz-osx-font-smoothing: grayscale;
                            -webkit-font-smoothing: antialiased;
                            display: inline-block;
                            font-style: normal;
                            font-variant: normal;
                            font-weight: normal;
                            text-rendering: auto;
                            line-height: 1;
                            content: '\EC5A';
                            text-align: center;
                            font-size: 17px;
                            vertical-align: middle;
                            margin-left: 10px;
                        }
                    }
                    &:nth-child(4) {
                        &::after {
                            font-family: 'Remix Icon';
                            -moz-osx-font-smoothing: grayscale;
                            -webkit-font-smoothing: antialiased;
                            display: inline-block;
                            font-style: normal;
                            font-variant: normal;
                            font-weight: normal;
                            text-rendering: auto;
                            line-height: 1;
                            content: '\EA6C';
                            text-align: center;
                            font-size: 17px;
                            vertical-align: middle;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
        @media (max-width: 992px) {
            .info-contact-left {
                padding-left: 15px;
                padding-right: 15px;
            }
            h2 {
                margin-top: 40px;
                margin-bottom: 0px;
            }
            p {
                margin-bottom: 0px;
            }
            .artical_1 {
                margin-bottom: 0px;
            }
            .artical_2 {
                margin-bottom: 0px;
                &:last-child {
                    margin-bottom: 50px;
                }
            }
            .children_content_table {
                ul {
                    overflow-x: auto;
                    padding-bottom: 10px;
                }
            }
        }
    }
}

//----------------- end css for Children's Dosages
//----------------- css for Lyndsay’s Formulas
.lyndsay_page {
    .children_page_content {
        .lyndsay_content_right {
            .lyndsay_content_1 {
                margin-bottom: 20px;
                padding: 0 15px;
                h2 {
                    font-weight: 300;
                    font-size: 30px;
                    line-height: 36px;
                    letter-spacing: -0.02em;
                    color: #292929;
                    margin-bottom: 5px;
                }
                p {
                    font-size: 16px;
                    line-height: 20px;
                    color: #292929;
                    width: 90%;
                }
            }
            .artical_1 {
                padding: 0 15px;
                h2 {
                    font-weight: 300;
                    font-size: 30px;
                    line-height: 36px;
                    letter-spacing: -0.02em;
                    color: #292929;
                    margin-bottom: 0px;
                }
                p {
                    font-size: 16px;
                    line-height: 20px;
                    color: #292929;
                    margin-bottom: 0;
                }
            }
            .lyndsay_table {
                margin-top: 20px;
                margin-bottom: 44px;
                overflow-x: auto;
                ul {
                    margin: 0;
                    list-style: none;
                    display: flex;
                    border-top: 1px solid rgba(32, 32, 32, 0.2);
                    justify-content: space-between;
                    padding: 10px 50px 10px 0px;
                    margin-left: 15px;
                    margin-right: 15px;
                    &:last-child {
                        border-bottom: 1px solid rgba(32, 32, 32, 0.2);
                    }
                    li {
                        font-size: 16px;
                        line-height: 20px;
                        color: #292929;
                        width: 100%;
                        white-space: nowrap;
                        p {
                            margin: 0;
                        }
                        &:nth-child(2) {
                            font-style: italic;
                        }
                        &:nth-child(3) {
                            text-align: right;
                        }
                        &:nth-child(4) {
                            text-align: right;
                        }
                    }
                    &.total {
                        li {
                            &:first-child {
                                color: transparent;
                            }
                            &:nth-child(2) {
                                text-align: right;
                                font-style: normal;
                            }
                        }
                    }
                }
                @media (max-width: 992px) {
                    ul {
                        width: max-content;
                        li {
                            min-width: 150px;
                        }
                    }
                }
            }
        }
    }
}

//----------------- end css for Lyndsay’s Formulas
//----------------- css for Register
.form_resgister_user {
    margin: auto;
    width: 60%;
    margin-top: 175px;
    margin-bottom: 100px;
    padding-top: 100px;
    .ms-password-reset__container {
        margin-top: 0;
    }
    label {
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        color: #292929;
        margin-bottom: 0;
        font-weight: 600;
        font-family: 'Inter', sans-serif;
    }
    input,
    select {
        background: #ffffff !important;
        border: 1px solid #e3dfd6 !important;
        height: 66px !important;
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        color: #292929;
        margin-top: 8px;
        &::placeholder {
            color: rgba(41, 41, 41, 0.4);
        }
        &:hover,
        &:focus {
            box-shadow: none;
            filter: none;
        }
    }
    .ms-sign-in__container {
        margin-top: 0 !important;
    }
    .ms-password-reset-verification__heading,
    .ms-password-reset__heading {
        font-weight: 300;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        font-family: 'Test Domaine Sans Text';
        color: #292929;
        margin-bottom: 15px;
    }
    .ms-password-reset__heading {
        width: 50%;
        margin: auto;
    }
    .ms-business-sign-up {
        label {
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.15em;
            text-transform: uppercase;
            color: #292929;
            margin-bottom: 0;
            font-weight: 600;
            font-family: 'Inter', sans-serif;
        }
        input,
        select {
            background: #ffffff !important;
            border: 1px solid #e3dfd6 !important;
            height: 66px !important;
            font-family: 'Inter', sans-serif;
            font-weight: 400;
            color: #292929;
            text-transform: capitalize;
            &::placeholder {
                color: rgba(41, 41, 41, 0.4);
            }
            &:hover,
            &:focus {
                box-shadow: none;
                filter: none;
            }
        }
        .error {
            margin-bottom: 2px;
        }
        .msc-address-form {
            label {
                margin-bottom: 15px;
            }
        }
        .ms-business-sign-up__container {
            h2.ms-business-sign-up__heading {
                font-weight: 300;
                font-size: 30px;
                line-height: 36px;
                letter-spacing: 0.09em;
                text-transform: uppercase;
                font-family: 'Test Domaine Sans Text';
                color: #292929;
                margin-bottom: 15px;
            }
            .ms-business-sign-up__form-item {
                position: relative;
            }
            .ms-business-sign-up__form-item-label {
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.15em;
                text-transform: uppercase;
                color: #292929;
                margin-bottom: 15px;
                font-weight: 600;
                font-family: 'Inter', sans-serif;
            }
            .ms-business-sign-up__form-item-input {
                background: #ffffff !important;
                border: 1px solid #e3dfd6 !important;
                height: 66px !important;
                font-family: 'Inter', sans-serif;
                font-weight: 400;
                color: #292929;
                text-transform: capitalize;
                &::placeholder {
                    color: rgba(41, 41, 41, 0.4);
                }
                &:hover,
                &:focus {
                    box-shadow: none;
                    filter: none;
                }
            }
            #company_addresszipcode,
            #company_addressphone,
            #CompanyEmail {
                text-transform: none;
            }
            .ms-business-sign-up__form-item__item-error-text {
                margin: 0;
                font-weight: 300;
                color: #a80000;
                position: absolute;
                top: 15px;
                font-family: 'Inter', sans-serif;
                left: 0;
                font-size: 12px;
                padding-left: 25px;
                &::before {
                    font-family: 'Remix Icon';
                    -moz-osx-font-smoothing: grayscale;
                    -webkit-font-smoothing: antialiased;
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    font-weight: 400;
                    text-rendering: auto;
                    line-height: 1;
                    content: '';
                    margin-right: 10px;
                    font-size: 15px;
                    position: absolute;
                    left: 3px;
                    top: 2px;
                }
            }
            .ms-business-sign-up__form-item.width-100 {
                margin-right: 20px !important;
                @media (max-width: 992px) {
                    margin-right: 0 !important;
                }
            }
        }
        .ms-business-sign-up__create-button {
            height: 66px;
            background: #292929;
            color: #fff;
            text-transform: uppercase;
            line-height: 66px;
            font-size: 18px;
            font-weight: 500;
            font-family: 'Inter', sans-serif;
            margin-top: 50px;
            text-align: center;
            display: block;
            margin-bottom: 20px;
            border: 1px solid transparent;
            text-decoration: none;
            transition: all 0.5s;
            width: 100%;
            &.disabled {
                background: #b1b1b1;
                &:hover {
                    cursor: no-drop;
                }
            }
            @media (min-width: 993px) {
                width: calc(100% - 20px);
                &:hover:not(.disabled) {
                    border: 1px solid #292929;
                    color: #292929;
                    background: #fff;
                }
            }
        }
        .ms-business-sign-up__formDescription {
            margin-top: 0;
            p {
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.01em;
                margin-bottom: 0;
                color: rgba(41, 41, 41, 0.6);
                font-family: 'Inter', sans-serif;
                a {
                    font-family: 'Inter', sans-serif;
                    text-decoration: none;
                    color: #ba5b80;
                }
            }
        }
    }
    .ms-sign-in__sign-in-section {
        margin: 0;
        .ms-sign-in__container {
            label {
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.15em;
                text-transform: uppercase;
                color: #292929;
                margin-bottom: 0;
                font-weight: 600;
                font-family: 'Inter', sans-serif;
            }
            input {
                background: #ffffff !important;
                border: 1px solid #e3dfd6 !important;
                height: 66px !important;
                font-family: 'Inter', sans-serif;
                font-weight: 400;
                color: #292929;
                &::placeholder {
                    color: rgba(41, 41, 41, 0.4);
                }
                &:hover,
                &:focus {
                    box-shadow: none;
                    filter: none;
                }
            }
            .error {
                margin-bottom: 2px;
            }
            .msc-address-form {
                label {
                    margin-bottom: 15px;
                }
            }
            a.ms-sign-in__forget-password {
                font-family: 'Inter', sans-serif;
                text-decoration: none;
                color: #ba5b80;
                font-size: 16px;
                width: 100%;
                text-align: right;
                font-weight: 600;
            }
            h2.ms-sign-in__sign-in-heading {
                font-weight: 300;
                font-size: 30px;
                line-height: 36px;
                letter-spacing: 0.09em;
                text-transform: uppercase;
                font-family: 'Test Domaine Sans Text';
                color: #292929;
                margin-bottom: 15px;
            }
            button {
                border-radius: 0;
            }
        }
    }
    .ms-password-reset-verification {
        .ms-password-reset-verification__container {
            width: 100%;
            margin: 0;
            h2.ms-password-reset-verification__heading {
                font-weight: 300;
                font-size: 30px;
                line-height: 36px;
                letter-spacing: 0.09em;
                text-transform: uppercase;
                font-family: 'Test Domaine Sans Text';
                color: #292929;
                margin-bottom: 15px;
            }
            label {
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.15em;
                text-transform: uppercase;
                color: #292929;
                margin-bottom: 0;
                font-weight: 600;
                font-family: 'Inter', sans-serif;
            }
            input {
                background: #ffffff !important;
                border: 1px solid #e3dfd6 !important;
                height: 66px !important;
                font-family: 'Inter', sans-serif;
                font-weight: 400;
                color: #292929;
                &::placeholder {
                    color: rgba(41, 41, 41, 0.4);
                }
                &:hover,
                &:focus {
                    box-shadow: none;
                    filter: none;
                }
            }
            button {
                border-radius: 0;
            }
            .error {
                margin-bottom: 2px;
            }
        }
    }
    @media (max-width: 992px) {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.fixed-right-signin,
.fixed-left-signin {
    p {
        color: #292929 !important;
    }
}

//----------------- end css for Register
//----------------- css for login
.form_resgister_user {
    .ms-sign-in {
        h2 {
            font-weight: 300;
            font-size: 30px;
            line-height: 36px;
            letter-spacing: 0.09em;
            text-transform: uppercase;
            font-family: 'Test Domaine Sans Text';
            color: #292929;
            margin-bottom: 15px;
        }
        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.01em;
            margin-bottom: 0;
            color: rgba(41, 41, 41, 0.6);
            font-family: 'Inter', sans-serif;
        }
        label {
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.15em;
            text-transform: uppercase;
            color: #292929;
            margin-bottom: 15px;
            font-weight: 600;
            font-family: 'Inter', sans-serif;
        }
        input {
            background: #ffffff !important;
            border: 1px solid #e3dfd6 !important;
            height: 66px !important;
            font-family: 'Inter', sans-serif;
            font-weight: 400;
            color: #292929;
        }
        .ms-sign-in__forget-password {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.01em;
            margin-bottom: 0;
            color: #ba5b80;
            font-family: 'Inter', sans-serif;
            text-decoration: none;
        }
        button {
            height: 66px;
            width: 100%;
            background: #292929;
            color: #fff;
            text-transform: uppercase;
            line-height: 66px;
            font-size: 18px;
            font-weight: 500;
            font-family: 'Inter', sans-serif;
            margin-top: 40px;
            text-align: center;
            display: block;
            margin-bottom: 20px;
            border: 1px solid transparent;
            text-decoration: none;
            transition: all 0.5s;
            border-radius: 0;
            padding: 0;
            &::before {
                display: none;
            }
            @media (min-width: 993px) {
                &:hover {
                    border: 1px solid #292929;
                    color: #292929;
                    background: #fff;
                }
            }
        }
    }
}

//----------------- end css for login
//----------------- end css for wating for approval
.waiting_for_approval_page {
    width: 60%;
    margin: auto;
    margin-top: 20rem;
    min-height: 70vh;
    h2 {
        font-weight: normal;
        font-size: 30px;
        position: relative;
        line-height: 41px;
        text-align: center;
        letter-spacing: 0.09em;
        font-family: 'Test Domaine Sans Text';
        text-transform: uppercase;
        color: #292929;
        margin-bottom: 15px;
    }
    p {
        font-size: 16px;
        line-height: 20px;
        color: rgba(41, 41, 41, 0.6);
        margin-bottom: 60px;
        a {
            text-decoration: none;
            height: 66px;
            width: 100%;
            background: #292929;
            color: #fff;
            text-transform: uppercase;
            line-height: 66px;
            font-size: 18px;
            font-weight: 500;
            font-family: 'Inter', sans-serif;
            margin-top: 80px;
            text-align: center;
            display: block;
            margin-bottom: 20px;
            border: 1px solid transparent;
            text-decoration: none;
            transition: all 0.5s;
            @media (min-width: 993px) {
                &:hover {
                    border: 1px solid #292929;
                    color: #292929;
                    background: #fff;
                }
            }
        }
    }
    @media (max-width: 992px) {
        margin-top: 10rem;
    }
}

//----------------- end css for wating for approval
//----------------- end css for Unique Process
.unique_process_page {
    .img-year-timeline {
        img {
            width: 80% !important;
            aspect-ratio: 1/1;
            padding-top: 60px;
            object-fit: cover;
        }
    }
    .content-timeline {
        @media (max-width: 992px) {
            p {
                width: 90%;
            }
        }
    }
}

//----------------- end css for Unique Process
//----------------- css for Cart page
.cart-page {
    .ms-cart {
        flex-direction: column;
        @media (min-width: 1350px) {
            flex-direction: row;
        }

        .msc-cartline-wraper {
            margin-bottom: 100px;
        }
        .msc-cart-lines-group {
            padding: 0 20px;
            margin-right: 0;
            @media (min-width: 1350px) {
                margin-right: 50px;
                padding: 0;
            }
            .msc-cart-lines-group-wraper {
                border: 0;
                .msc-cart-lines-group-wrapper__bopis-heading {
                    border-bottom: 0;
                    margin-bottom: 50px;
                    .msc-cart-lines-group-wrapper__bopis-heading-shipping-icon {
                        display: none;
                    }
                    .msc-cart-lines-group-wrapper__bopis-heading-shipping {
                        margin-left: 0;
                        font-weight: normal;
                        font-size: 30px;
                        position: relative;
                        line-height: 106%;
                        margin-bottom: 50px;
                    }
                    .msc-cart-lines-group-wrapper__bopis-heading-title {
                        display: none;
                    }
                }
                .msc-cart-lines-item {
                    position: relative;
                    padding: 0;
                    padding-bottom: 40px;
                    border-bottom: 0.5px solid rgba(41, 41, 41, 0.2);
                    margin: 0;
                    margin-bottom: 30px;
                    .msc-cart-line {
                        .msc-cart-line__product-image {
                            img {
                                border: 0;
                                transform: unset;
                            }
                        }
                        .msc-cart-line__content {
                            align-items: center;
                            .msc-cart-line__product {
                                a.msc-cart-line__product-title {
                                    font-family: 'Inter', sans-serif;
                                }
                                .msc-cart-line__product-price {
                                    span {
                                        font-family: 'Inter', sans-serif;
                                    }
                                }
                            }
                            .msc-cart-line__product-quantity {
                                .msc-cart-line__product-quantity-label {
                                    display: none;
                                }
                                .quantity {
                                    border: 1px solid rgba(32, 32, 32, 0.2);
                                    width: max-content;
                                    margin: auto;
                                    button,
                                    input {
                                        border: 0;
                                        background: transparent;
                                        height: 50px;
                                        padding: 0;
                                        width: 40px;
                                    }
                                }
                            }
                            .msc-cart-line__product-savings {
                                span {
                                    font-family: 'Inter', sans-serif;
                                }
                            }
                        }
                    }
                    .msc-cart-lines-button-container {
                        position: absolute;
                        bottom: 40px;
                        right: 0;
                        button {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        .msc-order-summary-wrapper {
            width: unset;
            background: transparent;
            border: 1px solid rgba(32, 32, 32, 0.2);
            height: fit-content;
            margin: 0 24px 100px;
            @media (min-width: 992px) {
                margin: 0 0 100px;
            }
            .msc-order-summary__items {
                border: 0;
                border-radius: 0;
                h2.msc-order-summary__heading {
                    font-size: 30px;
                    line-height: 36px;
                    letter-spacing: -0.02em;
                    color: #292929;
                    font-family: 'Inter', sans-serif;
                    border: 0;
                    padding: 24px 24px 41.5px;
                    @media (min-width: 992px) {
                        padding: 48px 48px 41.5px;
                    }
                }
                .msc-order-summary__line-total {
                    margin: 0 24px 40px;
                    @media (min-width: 992px) {
                        margin: 0 48px 40px;
                    }
                }
                .msc-order-summary__items {
                    p {
                        font-family: 'Inter', sans-serif;
                        padding: 0 24px;
                        @media (min-width: 992px) {
                            padding: 0 48px;
                        }
                        margin-bottom: 31px;
                        &:last-child {
                            padding: 28px 0 0;
                            .msc-price__actual {
                                font-weight: 400;
                                font-size: 24px;
                                line-height: 30px;
                                letter-spacing: -1px;
                                color: #292929;
                            }
                        }
                        span {
                            &:first-child {
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 17px;
                                letter-spacing: 1px;
                                text-transform: uppercase;
                                color: rgba(0, 0, 0, 0.4);
                            }
                            &:last-child {
                                font-style: normal;
                                font-weight: 500;
                                font-size: 15px;
                                line-height: 20px;
                                color: #292929;
                            }
                        }
                    }
                }
            }
            .msc-order-summary__checkout {
                border: 0;
                border-radius: 0;
                margin-top: 0;
                .msc-promo-code-heading {
                    border: 0;
                    border-top: 0.5px solid rgba(32, 32, 32, 0.2);
                    padding: 48px 24px 40px;
                    @media (min-width: 992px) {
                        padding: 48px 48px 40px;
                    }
                    font-style: normal;
                    font-weight: 400;
                    font-size: 30px;
                    line-height: 36px;
                    letter-spacing: -0.02em;
                    color: #292929;
                }
                form.msc-promo-code__form-container {
                    .msc-promo-code__group {
                        padding: 0 24px;
                        @media (min-width: 992px) {
                            padding: 0 48px;
                        }
                        input {
                            color: #292929;
                            border: 1px solid #e3dfd6;
                            border-radius: 0;
                            &::placeholder {
                                color: rgba(41, 41, 41, 0.4);
                            }
                        }
                        .msc-promo-code__apply-btn {
                            margin: 0;
                            width: 108px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 15px;
                            line-height: 22px;
                            text-align: center;
                            color: #ffffff;
                            text-transform: capitalize;
                            margin-bottom: 48px;
                            &::before {
                                display: none;
                            }
                        }
                        button {
                            background: #2c262d;
                            opacity: 1;
                            border-radius: 0;
                            &:hover {
                                border: 1px solid #292929;
                                color: #292929;
                                background: #fff;
                            }
                        }
                    }
                }
                .msc-order-summary__checkout__action {
                    padding: 0 24px;
                    @media (min-width: 992px) {
                        padding: 0 48px;
                    }
                    a.msc-cart__btn-checkout {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 24px;
                        border-radius: 0;
                        border: 0;
                        width: 100%;
                        height: 66px;
                        margin: 0;
                        padding: 21px 30px;
                        &::before {
                            display: none;
                        }
                    }
                }
                button.msc-cart__btn-addcarttotemplate {
                    width: calc(100% - 48px);
                    margin: 20px 24px 0;
                    @media (min-width: 992px) {
                        width: calc(100% - 96px);
                        margin: 20px 48px 0;
                    }
                    height: 66px;
                    padding: 21px 30px;
                    border: 1px solid #292929;
                    border-radius: 0;
                    letter-spacing: -0.01em;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                    color: #292929;

                    &::before {
                        display: none;
                    }
                }
                a.msc-cart__btn-guestcheckout {
                    background: #fff;
                    padding: 0;
                    width: calc(100% - 70px);
                    border: 1px solid #292929;
                    border-radius: 0;
                    &::before {
                        display: none;
                    }
                }
            }
        }
        @media (max-width: 992px) {
            flex-direction: column;
            .msc-order-summary__checkout__action {
                width: 100%;
            }
            .msc-cart__btn-guestcheckout {
                border-radius: 0;
            }
        }
    }
}

.cart-page-note {
    margin-bottom: 100px;
    button.drawer__button {
        position: relative;
        h2.drawer__buttontext {
            margin: 0;
            color: transparent;
            &::after {
                position: absolute;
                color: #292929;
                right: 5px;
                margin: 0;
                top: 25px;
            }
        }
        &:hover,
        &:focus {
            background-color: transparent;
            border: 0;
        }
    }
    .collapse.show {
        .ms-quick-order__content {
            button.ms-quick-order__content-form-main-addtocart-button {
                border-radius: 0;
            }
        }
    }
}
main {
    .my-dashboard-body {
        button.ms-search__form-submitSearch {
            @media (max-width: 992px) {
                top: 4px !important;
            }

        }
    }
}

//----------------- end css for Cart page
//----------------- css for Dashboard
.my-dashboard-title {
    .ms-account-welcome-tile {
        margin-top: 0;
        h2 {
            font-family: 'Inter', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            letter-spacing: -0.03em;
            color: #292929;
            border-bottom: 0;
            margin: 0;
            padding: 0;
            margin-bottom: 50px;
        }
    }
    @media (max-width: 992px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}
.ms-order-template__add-template-button {
    min-width: auto !important;
    width: 320px;
    padding: 0 !important;
}
.my-dashboard-body {
    min-height: 500px;
    .ms-tab {
        .ms-tab-header-section {
            display: none;
        }
        ul.ms-tab-tabs-section {
            display: flex;
            flex-wrap: nowrap;
            border-top: 1px solid rgba(32, 32, 32, 0.2);
            width: 100%;
            overflow-x: auto;
            padding-bottom: 15px;
            margin-bottom: 30px;
            > li {
                margin-right: 30px;
                cursor: pointer;
                outline: none;
                .ms-tab-item-header {
                    font-size: 12px;
                    line-height: 15px;
                    font-family: 'Inter', sans-serif;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    white-space: nowrap;
                    color: rgba(41, 41, 41, 0.4);
                    text-transform: uppercase;
                    padding-top: 10px;
                    outline: none;
                }
                &[aria-selected='true'] {
                    .ms-tab-item-header {
                        border-top: 1px solid #292929;
                        color: #292929;
                        outline: none;
                    }
                }
            }
        }
        .ms-tab-content-section {
            .ms-tab-item-content {
                .ms-tab-item-content__content {
                    &[aria-hidden='true'] {
                        display: none;
                    }
                }
            }
        }
    }
    .tab-user-dashboard {
        .ms-tab-item-content__content {
            @media (max-width: 992px) {
                .ms-search__icon {
                    position: relative;
                    z-index: 9999;
                }
            }
            .ms-dashboard-wrapper {
                .search-section {
                    position: relative;
                    margin-bottom: 40px;
                    input {
                        width: 100%;
                        padding: 20px;
                        padding-left: 50px;
                        @media (max-width: 767px) {
                            position: absolute;
                            border: 1px solid rgba(32, 32, 32, 0.2);
                            background: #fff;
                            top: -33px;
                            width: 100%;
                            right: 0;
                        }
                    }
                    button.ms-search__form-submitSearch {
                        position: absolute;
                        top: 18px;
                        left: 8px;
                        color: #292929;
                        &::before {
                            font-size: 21px;
                        }
                    }
                    button.ms-search__form-cancelSearch {
                        display: none;
                    }
                    .ms-search__autoSuggest {
                        position: absolute;
                        z-index: 9999;
                        .msc-autoSuggest__search-title-container {
                            button.ms-search__form-cancelSearch {
                                background: transparent;
                            }
                            .msc-autoSuggest__search-title {
                                color: #fff;
                            }
                        }
                        a {
                            text-decoration: none;
                            &:focus {
                                border: 0;
                            }
                        }
                        ul.msc-autoSuggest__keywordResults-items {
                            li.msc-autoSuggest__keywordResults-item {
                                word-break: break-all;
                            }
                        }
                    }
                }
                .block-section {
                    display: flex;
                    .left {
                        width: 70%;
                        padding-right: 40px;
                        .ms-cms-dashboard-button-container {
                            .browse_our_products {
                                background: #f8f6f3;
                                border: 1px solid rgba(32, 32, 32, 0.2);
                                padding: 30px 0;
                                margin-bottom: 40px;
                                h2 {
                                    font-weight: 600;
                                    font-size: 16px;
                                    line-height: 19px;
                                    letter-spacing: -0.03em;
                                    color: #292929;
                                    margin-bottom: 30px;
                                    padding-left: 30px;
                                }
                                .box_browse_our {
                                    .row {
                                        flex-wrap: nowrap;
                                        .col-12 {
                                            width: 100%;
                                            flex: unset;
                                            max-width: unset;
                                            margin: 0 7px !important;
                                            @media (max-width: 992px) {
                                                margin: 0 !important;
                                            }
                                            .ms-content-block {
                                                text-align: center;
                                            }
                                        }
                                        @media (max-width: 1199px) {
                                            flex-wrap: wrap;
                                            .col-12 {
                                                width: 30%;
                                            }
                                        }
                                        @media (max-width: 991px) {
                                            .col-12 {
                                                width: 50%;
                                            }
                                        }
                                    }
                                    img {
                                        width: auto;
                                        margin: auto;
                                        margin-bottom: 10px;
                                        background: #292929;
                                        border-radius: 50%;
                                        transition: all 0.5s ease;
                                        &:hover {
                                            background: #227868;
                                            filter: invert(100%);
                                        }
                                    }
                                    p {
                                        font-size: 12px;
                                        line-height: 15px;
                                        text-align: center;
                                        color: #292929;
                                    }
                                }
                            }
                        }
                    }
                    .right {
                        width: 30%;
                        .ms-cms-dashboard-latest-news {
                            background-color: #f8f6f3;
                            padding: 30px;
                            border: 1px solid rgba(32, 32, 32, 0.2);
                            height: calc(100% - 40px);
                            .category-title {
                                display: flex;
                                justify-content: space-between;
                                border-bottom: 1px solid rgba(32, 32, 32, 0.2);
                                padding-bottom: 35px;
                                margin-bottom: 25px;
                                p {
                                    font-weight: 600;
                                    font-size: 16px;
                                    line-height: 19px;
                                    letter-spacing: -0.03em;
                                    color: #292929;
                                    margin: 0;
                                }
                                a {
                                    font-size: 13px;
                                    line-height: 16px;
                                    letter-spacing: -0.03em;
                                    color: #DD8797;
                                    text-decoration: none;
                                }
                            }
                            ul.ms-cms-news-recent-article__table {
                                li {
                                    a {
                                        text-decoration: none;
                                        .card-double-tiles--desc {
                                            margin-bottom: 25px;
                                            .ms-recent-article__date {
                                                font-weight: normal;
                                                font-size: 13px;
                                                line-height: 16px;
                                                letter-spacing: -0.02em;
                                                color: rgba(41, 41, 41, 0.4);
                                                display: block;
                                            }
                                            .ms-recent-article__title {
                                                font-size: 16px;
                                                line-height: 19px;
                                                letter-spacing: -0.02em;
                                                color: #292929;
                                                display: block;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    @media (max-width: 992px) {
                        display: block;
                        .left {
                            width: 100%;
                            padding-right: 0px;
                        }
                        .right {
                            width: 100%;
                        }
                    }
                    &:last-child {
                        @media (max-width: 992px) {
                            margin-top: 40px;
                            margin-bottom: 40px;
                        }
                        .left {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            .ms-cms-dashboard-current-cart {
                                width: calc(50% - 20px);
                                margin-bottom: 40px;
                                .ms-dashboard-current-cart-wrapper {
                                    background: #f8f6f3;
                                    border: 1px solid rgba(32, 32, 32, 0.2);
                                    height: 100%;
                                    padding: 30px;
                                    .msc-cartline-top-section-wraper {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        margin-bottom: 40px;
                                        h1 {
                                            font-weight: 600;
                                            font-size: 16px;
                                            line-height: 19px;
                                            letter-spacing: -0.03em;
                                            color: #292929;
                                            margin-bottom: 0px;
                                        }
                                        .msc-view-cart__heading {
                                            a {
                                                font-size: 13px;
                                                line-height: 16px;
                                                letter-spacing: -0.03em;
                                                color: rgba(32, 32, 32, 0.4);
                                                text-decoration: none;
                                            }
                                        }
                                    }
                                    table {
                                        tr {
                                            th {
                                                font-size: 13px;
                                                line-height: 16px;
                                                letter-spacing: -0.02em;
                                                font-weight: 500;
                                                color: #292929;
                                                border-bottom: 1px solid rgba(32, 32, 32, 0.2);
                                                padding-bottom: 25px;
                                            }
                                            td {
                                                font-size: 13px;
                                                line-height: 16px;
                                                letter-spacing: -0.02em;
                                                font-weight: 500;
                                                color: #292929;
                                                padding: 0;
                                                padding-top: 25px;
                                                a {
                                                    text-decoration: none;
                                                    color: #292929;
                                                    white-space: nowrap;
                                                    overflow: hidden;
                                                    display: block;
                                                    text-overflow: ellipsis;
                                                    max-width: 100px;
                                                    margin-right: 10px;
                                                }
                                                .msc-cart-line__product-savings-actual {
                                                    .msc-price__strikethrough {
                                                        text-decoration: line-through;
                                                        color: #a1a1a1;
                                                        margin-right: 5px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .ms-cms-dashboard-my-library {
                                width: calc(50% - 20px);
                                margin-bottom: 40px;
                                .ms-cms-list-wrapper {
                                    background: #f8f6f3;
                                    border: 1px solid rgba(32, 32, 32, 0.2);
                                    height: 100%;
                                    padding: 30px;
                                    overflow-x: auto;
                                    .msl-library-dashboard-section-wraper {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        margin-bottom: 40px;
                                        h1 {
                                            font-weight: 600;
                                            font-size: 16px;
                                            line-height: 19px;
                                            letter-spacing: -0.03em;
                                            color: #292929;
                                            margin-bottom: 0px;
                                        }
                                        .msl-view-library-dashboard__heading {
                                            a {
                                                font-size: 13px;
                                                line-height: 16px;
                                                letter-spacing: -0.03em;
                                                color: #DD8797;
                                                text-decoration: none;
                                            }
                                        }
                                    }
                                    table {
                                        .dashboard-my-library-thead {
                                            tr {
                                                border-bottom: 1px solid rgba(32, 32, 32, 0.2);
                                            }
                                        }
                                        tr {
                                            th {
                                                font-size: 13px;
                                                line-height: 16px;
                                                letter-spacing: -0.02em;
                                                font-weight: 500;
                                                color: #292929;
                                                padding-bottom: 25px;
                                            }
                                            td {
                                                font-size: 13px;
                                                line-height: 16px;
                                                letter-spacing: -0.02em;
                                                font-weight: 500;
                                                color: #292929;
                                                padding: 0;
                                                white-space: nowrap;
                                                padding-top: 25px;
                                                a {
                                                    text-decoration: none;
                                                    span {
                                                        color: transparent;
                                                        width: 50px;
                                                        &::before {
                                                            font-family: 'Remix Icon';
                                                            -moz-osx-font-smoothing: grayscale;
                                                            -webkit-font-smoothing: antialiased;
                                                            display: inline-block;
                                                            font-style: normal;
                                                            font-variant: normal;
                                                            font-weight: normal;
                                                            text-rendering: auto;
                                                            line-height: 1;
                                                            content: '\EC5A';
                                                            font-size: 20px;
                                                            vertical-align: text-bottom;
                                                            text-align: center;
                                                            color: #292929;
                                                            margin-left: 10px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .ms-cms-dashboard-my-recent-orders {
                                width: 100%;
                                margin-bottom: 40px;

                                .ms-order-history {
                                    background: #f8f6f3;
                                    border: 1px solid rgba(32, 32, 32, 0.2);
                                    height: 100%;
                                    padding: 30px;
                                    .ms-order-history__header {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        margin-bottom: 40px;
                                        h2 {
                                            font-weight: 600;
                                            font-size: 16px;
                                            line-height: 19px;
                                            letter-spacing: -0.03em;
                                            color: #292929;
                                            margin-bottom: 0px;
                                        }
                                        .ms-order-list-link {
                                            a {
                                                font-size: 13px;
                                                line-height: 16px;
                                                letter-spacing: -0.03em;
                                                color: rgba(32, 32, 32, 0.4);
                                                text-decoration: none;
                                            }
                                        }
                                    }
                                    table {
                                        .ms-order-history__table-list__header {
                                            border-bottom: 0;
                                        }
                                        tr {
                                            border-top: 0;
                                            border-bottom: 0;
                                            height: auto;
                                            th {
                                                font-size: 13px;
                                                line-height: 16px;
                                                letter-spacing: -0.02em;
                                                font-weight: 500;
                                                color: #292929;
                                                padding: 0;
                                                padding-top: 25px;
                                                border-top: 1px solid rgba(32, 32, 32, 0.2);
                                            }
                                            td {
                                                font-size: 13px;
                                                line-height: 16px;
                                                letter-spacing: -0.02em;
                                                font-weight: 500;
                                                color: #292929;
                                                padding: 0;
                                                padding-top: 25px;
                                                border: 0;
                                                a {
                                                    text-decoration: none;
                                                    color: #DD8797;
                                                    font-size: 13px;
                                                }
                                                span.ms-order-history__table-list__row__order-status {
                                                    display: inline-block;
                                                    min-width: 130px;
                                                    height: 28px;
                                                    line-height: 26px;
                                                    border: 1px solid #292929;
                                                    text-align: center;
                                                    font-size: 11px;
                                                    &.status-4 {
                                                        background: #292929;
                                                        color: #fff;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            @media (max-width: 992px) {
                                .ms-cms-dashboard-current-cart {
                                    width: 100%;

                                    table {
                                        tr {
                                            th {
                                                white-space: nowrap;
                                                min-width: 150px;
                                            }
                                            td {
                                                white-space: nowrap;
                                            }
                                        }
                                    }
                                }
                                .ms-cms-dashboard-my-library {
                                    width: 100%;
                                    overflow: hidden;
                                }
                                .ms-cms-dashboard-my-recent-orders {
                                    table {
                                        tr {
                                            th {
                                                white-space: nowrap;
                                                min-width: 150px;
                                            }
                                            td {
                                                white-space: nowrap;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .right {
                            .ms-cms-dashboard-my-favourite {
                                background: #f8f6f3;
                                padding: 30px;
                                border: 1px solid rgba(32, 32, 32, 0.2);
                                .ms-dashboard-wishlist-items.ms-wishlist-items {
                                    .ms-favourite-heading {
                                        display: flex;
                                        justify-content: space-between;
                                        padding-bottom: 35px;
                                        h2.ms-wishlist-items__heading {
                                            font-weight: 600;
                                            font-size: 16px;
                                            line-height: 19px;
                                            letter-spacing: -0.03em;
                                            color: #292929;
                                            margin: 0;
                                            border: 0;
                                            padding-bottom: 0;
                                        }
                                        .ms-favourite-list-link {
                                            a {
                                                font-size: 13px;
                                                line-height: 16px;
                                                letter-spacing: -0.03em;
                                                color: rgba(32, 32, 32, 0.4);
                                                text-decoration: none;
                                            }
                                        }
                                    }
                                    .ms-favourite-header {
                                        font-size: 13px;
                                        line-height: 16px;
                                        letter-spacing: -0.02em;
                                        color: #292929;
                                        text-align: right;
                                        padding-bottom: 25px;
                                        border-bottom: 1px solid rgba(32, 32, 32, 0.2);
                                        margin-bottom: 25px;
                                    }
                                    ul.ms-wishlist-items__products {
                                        li.ms-wishlist-items__product {
                                            display: flex;
                                            flex-wrap: nowrap;
                                            margin-bottom: 35px;
                                            width: 100%;
                                            position: relative;
                                            .ms-favourite-col-first {
                                                height: 60px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                .ms-wishlist-items__product-remove-button {
                                                    position: inherit;
                                                    background-color: transparent;
                                                    color: #000;
                                                }
                                            }
                                            .ms-favourite-col-left {
                                                min-width: 60px;
                                                background: #dd8797;
                                                height: 60px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                margin-right: 10px;
                                                span {
                                                    font-family: 'Test Domaine Sans Text';
                                                    text-transform: uppercase;
                                                    font-size: 16px;
                                                }
                                            }
                                            .ms-favourite-col-middle {
                                                a {
                                                    text-decoration: none;
                                                    font-size: 13px;
                                                    line-height: 16px;
                                                    letter-spacing: -0.02em;
                                                    color: #292929;
                                                    padding: 0;
                                                    width: auto;
                                                    margin: 0;
                                                    white-space: normal;
                                                }
                                                span {
                                                    padding: 0;
                                                    width: max-content;
                                                    font-size: 13px;
                                                    line-height: 16px;
                                                    letter-spacing: -0.02em;
                                                    color: #292929;
                                                }
                                            }
                                            .ms-favourite-col-right {
                                                .ms-wishlist-items__product-remove-button {
                                                    display: none;
                                                }
                                                .ms-wishlist-items__product-add-button {
                                                    height: 30px;
                                                    min-width: auto;
                                                    border: 0;
                                                    padding: 0;
                                                    position: absolute;
                                                    right: -12px;
                                                    bottom: -15px;
                                                    background: transparent;
                                                    font-familly: 'Inter, sans-serif' !important;
                                                    font-size: 13px;
                                                    text-transform: none;
                                                    cursor: pointer;
                                                    &:hover {
                                                        background-color: transparent;
                                                        box-shadow: none;
                                                    }
                                                }
                                                .ms-wishlist-items__product-add-button.disabled {
                                                    color: #b7b7b7;
                                                    cursor: not-allowed;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .ms-account-profile__attributes-wrapper {
                .ms-account-profile__attributes {
                    h2.ms-account-profile__attributes-tile__heading {
                        color: transparent;
                    }
                    button.ms-account-profile__attributes-edit-button {
                        border: 0;
                        background: transparent;
                    }
                }
            }
        }
    }
    .tab-whistlist {
        ul.ms-wishlist-items__products {
            margin-right: -40px;
            li.ms-wishlist-items__product {
                padding-right: 40px;
                position: relative;
                margin-bottom: 100px;

                img {
                    padding: 0;
                    margin-bottom: 30px;
                    object-fit: contain;
                }
                a.ms-wishlist-items__product-link {
                    font-family: 'Inter', sans-serif;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 30px;
                    text-decoration: none;
                    text-align: left;
                    color: #292929;
                    border-top: 1px solid rgba(32, 32, 32, 0.2);
                    padding: 0;
                    padding-top: 10px;
                    text-align: center;
                }
                .ms-wishlist-items__product-dimensions {
                    display: none;
                }
                .ms-wishlist-items__product-price {
                    font-size: 16px;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: #292929;
                    font-weight: normal;
                    font-family: 'Inter', sans-serif;
                    padding: 0;
                    margin-bottom: 20px;
                    justify-content: center;
                }
                button.ms-wishlist-items__product-add-button {
                    text-transform: uppercase !important;
                    border-radius: 0;
                    height: 50px;
                    width: 150px;
                    background: transparent;
                    margin: auto;
                    color: #292929;
                    border: 1px solid rgba(32, 32, 32, 0.2) !important;
                    line-height: 50px;
                    font-size: 14px;
                    font-weight: 400;
                    font-family: 'Inter', sans-serif;
                    text-align: center;
                    display: block;
                    text-decoration: none;
                    transition: all 0.5s;
                    padding: 0;
                    &:hover {
                        background: #dd8797;
                        color: #fff;
                        border: 1px solid #dd8797;
                    }
                    &[disabled] {
                        background: rgba(32, 32, 32, 0.2);
                        text-transform: capitalize;
                        white-space: nowrap;
                        padding: 0 10px;
                        box-shadow: none;
                        &:hover {
                            cursor: no-drop;
                            background: rgba(32, 32, 32, 0.2);
                            border: 0;
                        }
                    }
                }
                button.ms-wishlist-items__product-remove-button {
                    background: transparent;
                    color: #292929;
                    line-height: 50px;
                    text-align: center;
                    padding: 0;
                    background: transparent;
                    opacity: 1;
                    top: 0;
                    margin: 0;
                    right: 40px;
                    z-index: 999;
                    &::before {
                        font-size: 30px;
                    }
                }
            }
            @media (max-width: 992px) {
                margin-right: -10px;
                li.ms-wishlist-items__product {
                    width: 50%;
                    padding-right: 10px;
                    a.ms-wishlist-items__product-link {
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        width: 100%;
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .tab_order_history {
        h2 {
            font-family: 'Inter', sans-serif;
            font-weight: 600;
            line-height: 29px;
            letter-spacing: -0.03em;
            color: #292929;
            border-bottom: 0;
            margin: 0;
            padding: 0;
            margin-bottom: 0px;
        }
        .ms-order-history__ms-order-history-filter {
            button {
                font-family: 'Inter', sans-serif;
                font-weight: 600;
                font-size: 14px;
                border: 0;
                &::after {
                    border-right: 0;
                }
            }
        }
        .ms-order-history__sales-order-list {
            padding: 0;
            .ms-order-history__btn-order-details {
                border-radius: 0;
                height: 50px;
                background: #f8f6f3;
                color: #292929;
                line-height: 50px;
                font-size: 14px;
                font-weight: 500;
                font-family: 'Inter', sans-serif;
                text-align: center;
                display: block;
                border: 1px solid rgba(32, 32, 32, 0.2);
                text-decoration: none;
                transition: all 0.5s;
                padding: 0 30px;
                cursor: pointer;
                &:hover {
                    background: #292929;
                    color: #fff;
                }
            }
            .ms-order-history__groups {
                img {
                    object-fit: contain;
                }
            }
        }
    }
    .tab_invoicesList {
        .msc-invoices-list__header {
            h1 {
                font-family: 'Inter', sans-serif;
                font-weight: 600;
                line-height: 29px;
                letter-spacing: -0.03em;
                color: #292929;
                border-bottom: 0;
                margin: 0;
                padding: 0;
                margin-bottom: 0;
            }
            .msc-invoices-list__header-description {
                div {
                    font-family: 'Inter', sans-serif;
                }
            }
        }
        .msc-invoices-list__filter {
            button {
                font-family: 'Inter', sans-serif;
                font-weight: 600;
            }
        }
        .msc-invoices-list__empty-lines__container {
            margin-bottom: 150px;
            .msc-invoices-list__empty-lines__container__title,
            .msc-invoices-list__empty-lines__container__description {
                font-family: 'Inter', sans-serif;
            }
            .msc-invoices-list__empty-lines__container__actions {
                a {
                    white-space: nowrap;
                    border-radius: 0;
                    cursor: pointer;
                    color: #fff;
                    transition: all 0.5s ease;
                    border: 1px solid #292929;
                    margin-top: 70px;
                    &:hover {
                        background: #f8f6f3;
                        color: #292929;
                    }
                }
            }
        }
        .msc-invoices-list__container__content__table__row {
            td {
                label {
                    margin-bottom: 0;
                }
                &:last-child {
                    display: flex;
                    padding: 25px 0;
                    button {
                        padding: 0;
                        height: 35px;
                        font-size: 12px;
                    }
                }
            }
        }
    }
    .tab-profile-info,
    .tab-profile-address {
        h2,
        p,
        a,
        span,
        label,
        h3 {
            font-family: 'Inter', sans-serif !important;
        }
        label {
            font-weight: bold;
        }
        h2,
        h3 {
            font-weight: bold;
        }
        .ms-account-profile__attributes-tile__heading {
            color: transparent !important;
        }
        button {
            border-radius: 0;
            cursor: pointer;
            transition: all 0.5s ease;
            &::before {
                display: none;
            }
            &:hover {
                border: 1px solid #292929;
                color: #292929;
                background: #fff;
            }
        }
        .ms-account-profile__section.ms-account-profile__section-preferences {
            display: none;
        }
        &.ms-account-profile {
            margin-top: 60px;
        }
    }
    @media (max-width: 992px) {
        padding-left: 15px;
        padding-right: 15px;
        .ms-tab {
            ul.ms-tab-tabs-section {
                margin-bottom: 30px;
            }
        }
    }
}

//----------------- css for Order Detail
// .mt-250 {
//     margin-top: 165px;
//     @media (max-width: 1199px) {
//         margin-top: 230px;
//     }

//     @media (max-width: 992px) {
//         margin-top: 70px;
//     }
// }
.order-detail-page {
    h2,
    span,
    a,
    button,
    p,
    label {
        font-family: 'Inter', sans-serif !important;
        text-decoration: none;
    }
    .ms-order-details__order-information-qr-code {
        background-color: transparent;
        margin-bottom: 50px;
    }
    .ms-order-details__sales-line-btn-buy-it-again-link {
        width: 180px;
        padding: 0;
    }
    .msc-cart-line__product-discount {
        color: #DD8797 !important;
    }
}
.ms-order-template__image-container img {
    object-fit: contain;
}
.ms-order-template__add-to-bag-button-div {
    text-align: center;
}
.ms-order-template-action-bar {
    button {
        width: 300px;
    }
}
//----------------- end css for Order Detail

//----------------- css for checkout page
.btn-primary {
    background-color: #2c262d !important;
    border-color: #2c262d !important;
}
.checkout-page {
    margin-bottom: 100px;
    .container-heading {
        padding: 0 10px;
    }

    .ms-checkout {
        .ms-checkout__body {
            display: block;
            .ms-checkout__top {
                display: flex;
                flex-direction: column;
                @media (min-width: 1200px) {
                    flex-direction: row;
                }
                .ms-checkout__line-items {
                    width: 100%;
                    padding-right: 15px;
                    padding-left: 15px;
                    padding-right: 20px;
                    padding-left: 15px;
                    margin-top: 0;
                    margin-right: auto;
                    margin-left: auto;

                    border: 0;
                    background-color: transparent;

                    &-header {
                        margin: 0;
                    }

                    &-heading {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 30px;
                        line-height: 36px;
                        letter-spacing: -0.02em;
                        color: #292929;
                        margin: 0;
                    }

                    &__headwrapper {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        margin-bottom: 20px;
                    }

                    &__headedit {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 22px;
                        text-decoration-line: underline;
                        color: #292929;
                        a {
                            color: inherit;
                        }
                    }

                    &__bodywrapper {
                        @media (min-width: 1024px) {
                            padding: 40px;
                            border: 1px solid rgba(32, 32, 32, 0.2);
                        }
                    }

                    @media (min-width: 1200px) {
                        padding-right: 20px;
                        padding-left: 15px;
                        width: 68%;
                    }
                }
                .msc-order-summary {
                    &-wrapper {
                        width: 100%;
                        padding-top: 50px;
                        padding-right: 15px;
                        padding-left: 15px;
                        margin-right: auto;
                        margin-left: auto;
                        @media (min-width: 1200px) {
                            padding-top: 0;
                            padding-right: 15px;
                            padding-left: 20px;
                            width: 32%;
                        }
                    }

                    &__heading {
                        padding: 0;
                        margin-bottom: 20px;

                        font-style: normal;
                        font-weight: 400;
                        font-size: 30px;
                        line-height: 36px;

                        letter-spacing: -0.02em;

                        color: #292929;
                        border: 0;
                    }

                    &__items {
                        border: 1px solid rgba(32, 32, 32, 0.2);
                        border-radius: 0;
                        padding: 40px;
                    }

                    &__line {
                        &-sub-total {
                            padding: 0;
                            margin-bottom: 31px;
                        }

                        &-shipping {
                            padding: 0;
                            margin-bottom: 31px;
                        }

                        &-tax-amount {
                            padding: 0;
                            margin-bottom: 31px;
                        }

                        &-total-discounts {
                            padding: 0;
                            margin-bottom: 31px;
                        }

                        &-total {
                            margin: 0;
                            padding: 28px 0 0;
                            .msc-price__actual {
                                font-size: 24px;
                                line-height: 30px;
                            }
                        }
                    }

                    &__label {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        text-align: right;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        color: #292929;
                    }

                    &__value {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 20px;
                        text-align: right;
                        color: #292929;
                    }
                }
            }
            .ms-checkout__main {
                .ms-checkout__guided-form {
                    .ms-checkout__guided-card {
                        .ms-checkout__guided-card-header {
                            h2 {
                                font-size: 30px;
                                line-height: 36px;
                                letter-spacing: -0.02em;
                                color: #292929;
                                font-family: 'Inter', sans-serif;
                                border: 0;
                            }
                            .ms-checkout__guided-card-btn-edit {
                                text-decoration: underline;
                                font-size: 15px;
                                color: #292929;
                            }
                        }
                        .ms-checkout__guided-card-body {
                            .ms-checkout__guided-card-content {
                                .ms-checkout-shipping-address {
                                    .msc-address-select {
                                        button {
                                            margin-bottom: 30px;
                                            background: #2c262d;
                                            color: #fff;
                                            &:focus {
                                                &::before {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .ms-checkout__guided-card-footer {
                                margin-top: 10px;
                            }
                        }
                    }
                    .ms-checkout__terms-and-conditions {
                        margin-bottom: 50px;
                        padding: 0;
                        .ms-checkout-terms-and-conditions__container {
                            p {
                                margin: 0;
                            }
                        }
                    }
                }
            }
            .ms-checkout__side {
                background: rgba(227, 223, 214, 0.4);
                padding: 5px;
                height: fit-content;
                .msc-order-summary-wrapper {
                    border: 0;
                    border-radius: 0;
                    border-bottom: 0.5px solid rgba(41, 41, 41, 0.4);
                    h2 {
                        font-size: 30px;
                        line-height: 36px;
                        letter-spacing: -0.02em;
                        color: #292929;
                        font-family: 'Inter', sans-serif;
                        border: 0;
                        text-align: center;
                    }
                    .msc-order-summary__items {
                        border: 0;
                        border-radius: 0;
                        margin-bottom: 20px;
                        p {
                            font-family: 'Inter', sans-serif;
                            padding: 0 31px;
                            &.msc-order-summary__line-total {
                                padding: 0;
                                padding-top: 10px;
                            }
                            span {
                                &:first-child {
                                    font-weight: normal;
                                    font-size: 14px;
                                    line-height: 17px;
                                    letter-spacing: 1px;
                                    text-transform: uppercase;
                                    color: rgba(0, 0, 0, 0.4);
                                }
                                &:last-child {
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 15px;
                                    line-height: 20px;
                                    color: #292929;
                                }
                            }
                        }
                    }
                }
                .ms-checkout__side-control-first {
                    border: 0;
                    border-bottom: 0.5px solid rgba(41, 41, 41, 0.4);
                    border-radius: 0;
                }
                .ms-checkout__line-items.multiple-pickup-enabled {
                    border: 0;
                    border-radius: 0;
                    background: transparent;
                    padding-bottom: 50px;
                    .ms-checkout__line-items-header {
                        h2 {
                            font-size: 30px;
                            line-height: 36px;
                            letter-spacing: -0.02em;
                            color: #292929;
                            font-family: 'Inter', sans-serif;
                            border: 0;
                        }
                    }
                    .ms-checkout__line-items-delivery-group.ship {
                        padding: 0 5px;
                        .ms-checkout__line-items-group-title {
                            font-size: 14px;
                            font-weight: normal;
                        }
                    }
                }
                .ms-checkout__line-items {
                    background: transparent;
                    padding: 32px 20px;
                    .ms-checkout__line-items-header {
                        text-align: center;
                        position: relative;
                        margin: 0;
                        display: block;
                        margin-bottom: 30px;
                        h2 {
                            font-size: 30px;
                            line-height: 36px;
                            letter-spacing: -0.02em;
                            color: #292929;
                            font-family: 'Inter', sans-serif;
                            border: 0;
                        }
                        .ms-checkout__line-items-edit-cart-link {
                            text-decoration: underline;
                            font-size: 14px;
                            font-weight: normal;
                            color: #292929;
                            text-decoration: underline;
                            font-size: 15px;
                            color: #292929;
                            position: absolute;
                            bottom: -50px;
                            right: 0;
                        }
                    }
                    .ms-checkout__line-items-delivery-group.ship {
                        .ms-checkout__line-items-group-title {
                            font-size: 15px;
                        }
                        .ms-checkout__line-items-list {
                            .msc-cart-line {
                                a {
                                    text-decoration: none;
                                }
                                .msc-cart-line__content {
                                    .msc-cart-line__product-inventory-label {
                                        margin: 0;
                                    }
                                    .msc-cart-line__product-discount {
                                        span {
                                            color: #dd8797;
                                        }
                                        .msc-cart-line-item-product-discount-price {
                                            margin: 0 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .ms-checkout-shipping-address__group-images-lines-line-quantity {
        background-color: #292929;
    }
    .ms-checkout__guided-card {
        .ms-checkout__guided-card-body {
            .ms-checkout__guided-card-content {
                // padding: 0 20px;
                .ms-checkout-section-container {
                    // padding: 0 !important;
                    // .ms-checkout-section-container__item.initialized.updating {
                    //     margin-bottom: 50px;
                    //     border: 1px solid #cecece;
                    //     padding: 20px;
                    //     margin-top: 0;
                    //     &:last-child {
                    //         border-top: 0;
                    //     }
                    //     .ms-checkout-billing-address__heading {
                    //         margin-bottom: 20px;
                    //         font-weight: bold;
                    //     }
                    //     .msc-address-detail {
                    //         padding-left: 27px;
                    //     }
                    //     .ms-checkout-payment-instrument__add {
                    //         height: 85px;
                    //     }
                    //     .ms-checkout-customer-account__bottom-border {
                    //         border-bottom: 0;
                    //     }
                    // }
                    .ms-payment-method-instruction {
                        font-size: 24px;
                        line-height: 30px;
                        font-weight: 500;
                        padding: 20px;
                        margin-bottom: 0;
                    }
                    .ms-checkout-terms-and-conditions__container {
                        padding: 0 20px;
                    }
                    // .ms-checkout-section-container__item.initialized {
                    //     border: 1px solid #cecece;
                    //     padding: 0px 40px;
                    //     margin: 10px 0 40px;
                    //     border-top: 0;
                    //     margin-top: 0;
                    // }
                    .payment-notice {
                        display: none;
                    }
                }
            }
            // .ms-checkout__guided-card.closed.initialized.ready {
            //     padding-bottom: 0;
            // }
        }
        .ms-checkout__guided-card-body {
            padding: 0 20px;
        }
    }
}
//----------------- end css for checkout page
.ms-checkout-payment-instrument-paypal .ms-checkout-payment-instrument__add {
    margin-left: -4px;
}
.msc-cartline-wraper {
    .msc-cart__empty-cart {
        height: auto;
    }
}
.msc-cart__empty-cart {
    height: calc(100vh - 442px);
    margin-bottom: 68px !important;
    margin-top: 0 !important;
    .msc-cart-line {
        display: flex !important;
        height: 100%;
        justify-content: center;
        align-items: center;
        h3 {
            &::before {
                font-family: 'Remix Icon';
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                display: block;
                font-style: normal;
                font-variant: normal;
                font-weight: normal;
                text-rendering: auto;
                line-height: 1;
                content: '\F120';
                font-size: 60px;
                vertical-align: text-bottom;
                text-align: center;
                color: #262626;
                margin-bottom: 10px;
            }
        }
        a {
            background: #292929;
            color: #fff;
            text-decoration: none;
            height: 40px;
            line-height: 40px;
            padding: 0 30px;
            margin-top: 10px;
        }
    }
}
//----------------- end css for list news
.ms-cms-content-pagination {
    padding-top: 85px;

    @media (max-width: 769px) {
        padding-top: 45px;
    }

    ul {
        display: flex;
        justify-content: center;
    }
    .msc-page-item {
        width: 30px;
        height: 30px;
        padding: 0;
        justify-content: center;
        align-items: center;
        display: flex;
        + li {
            margin-left: 10px;
        }

        &.previous,
        &.next {
            width: auto;
        }

        &.disabled {
            display: none;
        }

        .msc-page-link {
            font-family: 'Inter', sans-serif;
            font-weight: normal;
            font-size: 14px;
            line-height: 15px;
            color: #29292966;
        }
        &.active {
            background-color: #292929;
            .msc-page-link {
                color: #fff;
            }
        }
    }
}
.herbal-list-filter {
    p {
        font-weight: 300;
        font-size: 26px;
        line-height: 36px;
        letter-spacing: -0.02em;
        color: #292929;
    }
}
.list-news-page {
    margin-bottom: 100px;
    .ms-cms-list-news-wrapper {
        .ms-cms-list__news-content {
            --col: 3;
            --space: 40px;
            display: flex;
            flex-wrap: wrap;
            margin-right: calc(-1 * var(--space));
            @media (max-width: 992px) {
                --col: 2;
                --space: 15px;
            }
            .col-md-4 {
                padding-right: 40px !important;
                margin-bottom: 80px !important;
                width: calc(100% / var(--col));
                max-width: unset !important;
                flex: unset !important;
                a {
                    text-decoration: none;
                    display: block;
                    width: 100%;
                    .ms-content-cms__news-column {
                        img {
                            margin-bottom: 30px;
                            width: 100%;
                            aspect-ratio: 2/1;
                            object-fit: cover;
                            object-position: center;
                        }
                        .item-news--type {
                            font-weight: normal;
                            font-size: 12px;
                            letter-spacing: 2px;
                            line-height: 17px;
                            color: #292929;
                            text-transform: uppercase;
                            margin-bottom: 30px;
                        }
                        .item-news--title {
                            font-weight: 300;
                            text-transform: capitalize;
                            font-size: 26px;
                            line-height: 36px;
                            letter-spacing: -0.02em;
                            color: #292929;
                        }
                    }
                }
            }
            .ms-cms-content-pagination {
                ul {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    li {
                        padding: 0;
                        a {
                            font-size: 14px;
                            line-height: 15px;
                            text-align: center;
                            color: rgba(41, 41, 41, 0.4);
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                            margin-right: 10px;
                            text-decoration: none;
                            display: inline-block;
                        }

                        &.active {
                            span {
                                display: inline-block;
                                background: #292929;
                                color: #fff;
                                width: 30px;
                                font-size: 14px;
                                height: 30px;
                                line-height: 30px;
                                text-align: center;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
//----------------- end css for list news

.msc-modal.show {
    z-index: 9999999 !important;
    .msc-quickview__sku-text {
        display: none;
    }
    .ms-quickView__body.msc-modal__body {
        .ms-quickView__media-gallery {
            img {
                object-fit: contain;
                max-width: unset;
                aspect-ratio: 8/7;
            }
        }
        .ms-quickView__content {
            .msc-quickview__price-section {
                .ms-quickView__add-to-wishlist-container {
                    .msc-add-to-wishlist.msc-add-to-cart-extra-actions {
                        &::before {
                            transition: all 0.5s ease;
                            color: #292929 !important;
                        }
                    }
                }
            }
        }
    }
    .ms-quickView__footer.msc-modal__footer {
        .ms-quickView__product-add-to-cart {
            a {
                &::before {
                    background-color: #dd8797 !important;
                }
            }
        }
    }
}

.ms-checkout-terms-and-conditions__container {
    div {
        p {
            margin-bottom: 0;
        }
    }
}
.test_unique_process {
    .box-section-1 {
        margin-bottom: 100px;
        .txt-section-1-right {
            padding-left: 10%;
            width: 90%;
        }
        .txt-section-1-left {
            margin: 0 0 0 10%;
        }
    }
    @media (max-width: 992px) {
        .txt-section-1-right {
            padding-left: 0 !important;
        }
        .txt-section-1-left {
            margin: 0 !important;
        }
        img {
            margin-bottom: 40px;
        }
    }
}
.news--details {
    margin-top: 150px;
    @media (max-width: 992px) {
        margin-top: 0;
    }
}
button {
    border-radius: 0 !important;
    font-family: 'Inter', sans-serif !important;
}
a {
    font-family: 'Inter', sans-serif !important;
    border-radius: 0 !important;
    &:focus {
        &::before {
            border: 0 !important;
        }
    }
}
//----------------- end css for Dashboard
.revert-row {
    @media (max-width: 992px) {
        .row {
            flex-direction: column-reverse;
        }
    }
}
ul {
    padding-left: 15px;
    li {
        &::marker {
            font-size: 10px;
        }
    }
}

.private-footer-page {
    .herbal-top-header .ms-nav > ul.ms-nav__list > li.ms-main-menu_item > ul > li span {
        margin-bottom: 11px;
    }
}

.ms-dashboard-current-cart__table {
    width: 100%;
    .msc-cart__empty-cart {
        .msc-cart-line {
            justify-content: unset;
            align-items: unset;
        }
    }
}
.list-product {
    @media (max-width: 992px) {
        .ms-search__form-cancelSearch {
            position: absolute;
            z-index: 1;
            right: 5px;
            top: 70px;
        }
    }
}
.aboutHerbalMedicine {
    position: relative;
    top: 200px;
    @media (max-width: 992px) {
        top: 70px;
    }
}

#whyLiquidHerbs,
#aboutHerbalMedicine,
#consumerInformation,
#ourBrand,
#ourFamily,
#manufacturingProcess,
#qualityAssurance,
#research,
#fullSpectrumExtracts,
#goodManufacturingPractice {
    margin-top: -200px;
    padding-bottom: 200px;
    display: block;
}

#ourBrand,
#qualityAssurance {
    margin-top: -250px;
}
#aboutHerbalMedicine,
#consumerInformation,
#whyLiquid,
#research,
#qualityAssurance,
#fullSpectrumExtracts,
#goodManufacturingPractice {
    @media (max-width: 992px) {
        margin-top: 0;
        padding-bottom: 70px;
    }
}

.ms-dashboard-current-cart-wrapper {
    overflow-x: auto;
}
.ms-dashboard-current-cart__table {
    min-width: 310px;
}

.ms-link-logo {
    img {
        transform: translateX(0) !important;
    }
}

.ms-tab-item-content__content {
    .ms-account-customer-balance {
        &__created-on {
            display: none;
        }

        &__dropdown {
            button {
                margin-top: 0;
                border-color: #29292929;
                background: #fff;
                padding: 15px 60px;
            }
        }

        &__account-heading {
            margin-top: 40px;
            border-top: 0.5px solid #29292929;
            font-weight: normal !important;
            border-bottom: none !important;
            margin-bottom: 0;
            padding: 40px 0 !important;
        }

        &__account {
            padding: 0 !important;
        }

        &__account-number {
            font-size: 14px !important;
            font-weight: normal !important;
        }

        &__account__credit {
            padding-top: 24px !important;
        }

        &__available-credit-amount {
            font-size: 14px !important;
            font-weight: normal !important;
        }

        &__credit-summary-heading {
            padding-top: 40px !important;
            margin: 0;
            border: 0 !important;
        }

        &__summary-details {
            .ms-account-customer-balance__credit-summary__labels {
                .ms-account-customer-balance__available-credit-label {
                    color: rgba(41, 41, 41, 0.4) !important;
                }
                .ms-account-customer-balance__balance-owed-label {
                    color: rgba(41, 41, 41, 0.4) !important;
                }
            }
        }

        .ms-account-loyalty__home-link.msc-btn {
            margin-top: 24px;
            padding: 25px 100px;
            font-size: 14px;
        }
    }
    .ms-account-profile.tab-profile-info {
        margin-top: 40px;
        padding-top: 40px;
        border-top: 0.5px solid #29292929;
        border-bottom: 0.5px solid #29292929;
        .ms-account-profile-wrapper {
            position: relative;
            .ms-account-loyalty-tile__heading {
                border-bottom: 0;
                margin-bottom: 0;
                font-weight: normal;
                &::before {
                    content: '';
                    margin-right: 0;
                }
            }
            .ms-account-profile__section {
                margin-bottom: 0;
                .ms-account-profile__section-links {
                    display: none;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }
    }
    @media screen and (min-width: 1024px) {
        .ms-account-management-address {
            width: calc(5 * 8.333%);
        }
    }
    .ms-account-management-address.tab-profile-address {
        padding: 40px 0;
        .ms-address-list {
            margin-bottom: 0;
            border-bottom: none;
            position: relative;
            .msc-address-list__heading {
                font-weight: normal;
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: 0;
            }
            .msc-address-list__button-add.msc-btn {
                background: none;
                border: none;
                color: #000;
                padding: 0;
                min-width: unset;
                margin: 0;
                position: absolute;
                top: 0;
                right: 0;
                text-decoration: underline;
                font-size: 12px;
                &:hover {
                    box-shadow: none;
                }
            }
            .msc-address-list__primary {
                border-top: 0;
            }
            .msc-address-list__primary-list {
                border-bottom: 0;
                padding-bottom: 0;
                .msc-address-list__button-edit {
                    text-decoration: underline;
                    font-size: 14px;
                }
                .msc-address-list__button-remove {
                    text-decoration: underline;
                    border-left: 0;
                    font-size: 14px;
                }
                .msc-address-list__button-primary.msc-btn {
                    width: 100%;
                    margin-bottom: 20px;
                    margin-top: 0;
                }
            }
            .msc-address-list__primary-list + .msc-address-list__primary-list {
                margin-top: 24px;
            }
            .msc-address-detail {
                padding-bottom: 30px;
                .msc-address-detail__item-name {
                    text-align: right;
                    display: block;
                    &::before {
                        content: 'Name';
                        left: 0;
                        position: absolute;
                        font-weight: normal;
                        color: rgba(41, 41, 41, 0.4);
                    }
                }
                .msc-address-detail__item-street {
                    text-align: right;
                    display: block;

                    &::before {
                        content: 'Street';
                        left: 0;
                        position: absolute;
                        font-weight: normal;
                        color: rgba(41, 41, 41, 0.4);
                    }
                }
                .msc-address-detail__item-streetnumber {
                    text-align: right;
                    display: block;
                    &::before {
                        content: 'Delivery Instructions';
                        left: 0;
                        position: absolute;
                        font-weight: normal;
                        color: rgba(41, 41, 41, 0.4);
                    }
                }
                .msc-address-detail__item-city {
                    text-align: right;
                    display: table-cell;
                    width: 100%;
                    &::before {
                        content: 'Address';
                        left: 0;
                        position: absolute;
                        font-weight: normal;
                        color: rgba(41, 41, 41, 0.4);
                    }
                }
                .msc-address-detail__item-state,
                .msc-address-detail__item-zipcode,
                .msc-address-detail__item-threeletterisoregionname {
                    display: table-cell;
                    padding-left: 5px;
                    &::after {
                        content: '';

                    }
                }
                .msc-address-detail__item-address-detail_Phone {
                    margin-top: 0;
                    text-align: right;
                    display: block;
                    &::before {
                        content: 'Phone';
                        left: 0;
                        position:absolute;
                        font-weight: normal;
                        color: rgba(41, 41, 41, 0.4);
                    }
                }
                .msc-address-detail__item-phone {
                    &::before {
                        content: '';
                    }
                }
                .msc-address-detail__item-empty,
                .msc-address-detail__main-item-empty {
                    display: none;
                }
            }
        }
    }
}

.ms-order-lookup {
    &__registered-user {
        display: none;
    }
}