$content-block-min-height: 150px;
$hero-body-font-size: 16px;
$hero-body-line-height: 21px;
$hero-body-font-weight: normal;
$hero-body-max-length: 30em;
$hero-body-top-margin: 8px;
$hero-content-text-margin-top: 4px;
$hero-content-button-margin-top: 32px;
$hero-heading-font-size: 32px;
$hero-heading-line-height: 43px;
$hero-heading-font-weight: normal;
$hero-heading-max-length: 30em;
$hero-link-to-link-spacing: 16px;
$hero-links-secondary-padding: 12px 20px;
$additional-content-line-height: 80px;
$msv-mobile-title-font-size-xl: 40px;
$msv-tablet-title-font-size-xl: 60px;
$msv-title-line-height: 50px;
$msv-tablet-title-line-height-xl: 72px;
$content-block-details-padding-desktop: 120px 80px 60px 80px;
$content-block-details-padding-tablet: 40px 24px 28px 24px;
$content-block-details-padding-mobile: 40px 16px 28px 16px;
$additional-content-cta-margin-mobile: -117px;
$additional-content-cta-margin-tablet: -137px;
$msv-mobile-title-font-size-s: 24px;
$msv-mobile-title-line-height-m: 40px;
$msv-tablet-title-line-height-m: 40px;
$additional-content-text-font-size: 36px;
$b2b-hero-body-right-margin: 40px;
$b2b-hero-content-text-margin-top: 28px;
$b2b-hero-content-button-margin-top: 28px;
$b2b-hero-link-to-link-padding: 20px;
$b2b-hero-links-secondary-margin-left: 20px;
$b2b-hero-links-mobile-secondary-margin-top: 14px;
$b2b-content-block-details-margin-left: 120px;
$b2b-content-block-details-margin-top: 79px;
$b2b-content-block-paragraph-padding-right: 5px;
$additional-content-link-padding: 4px;

:root {
    // heading
    --msv-content-block-heading-font-size: #{$hero-heading-font-size};
    --msv-content-block-heading-font-color: var(--msv-font-primary-color);
    // additional content heading
    --msv-additional-content-text-font-size: #{$additional-content-text-font-size};

    // Text theme
    --msv-content-block-textheme-dark: var(--msv-font-primary-color);
    --msv-content-block-textheme-light: #{$msv-white};
    --msv-content-block-text-font-size: var(--msv-body-font-size-m);

    // primary button
    --msv-content-block-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-content-block-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-content-block-primary-btn-border: var(--msv-accent-brand-color);
}

@mixin overlay-text-on-image {
    .ms-content-block__details {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
    }
}


@media screen and (max-width: $msv-breakpoint-l) {
    .ms-content-block {
        &[data-m-layout="full-width"] {
            &.texttheme__dark .ms-content-block__title,
            &.texttheme__dark .ms-content-block__text {
                color: $msv-gray-60;
            }

            &.texttheme__light .ms-content-block__title,
            &.texttheme__light .ms-content-block__text {
                color: var(--msv-content-block-textheme-light);
            }
        }

        &[data-m-layout="full-width-b2b"] {
            &.texttheme__dark .ms-content-block__title,
            &.texttheme__dark .ms-content-block__text {
                color: $msv-gray-60;
            }

            &.texttheme__light .ms-content-block__title,
            &.texttheme__light .ms-content-block__text {
                color: var(--msv-content-block-textheme-light);
            }

            & .ms-content-block__cta {
                flex-direction: column;

                .msc-cta__secondary {
                    margin-left: 0;
                    margin-top: $b2b-hero-links-mobile-secondary-margin-top;
                }
            }

            & .ms-content-block__details__additional-content {
                margin-top: $b2b-hero-content-text-margin-top;
                position: relative;
                margin-left: 0;
            }
        }
    }
}
