$msv-checkout-payment-instrument-min-height: 80px;
$msv-checkout-payment-instrument-card-title-margin-bottom: 8px;
$msv-checkout-payment-instrument-address-title-margin-bottom: 8px;
$msv-checkout-payment-instrument-address-title-margin-top: 20px;
$msv-checkout-payment-instrument-loading-dimension: 100%;
$msv-checkout-payment-instrument-border: 0;
$msv-checkout-payment-container-padding: 0;
$msv-checkout-payment-container-margin-zero: 0;
$msv-checkout-payment-ready-margin-top: 20px;
$msv-checkout-payment-ready-margin-bottom: 20px;
$msv-checkout-payment-ready-flex-basis: 100%;
$msv-checkout-payment-ready-margin-left: 10px;
$msv-checkout-payment-instrument-mutation-observer-margin-top: 20px;
$msv-checkout-payment-instrument-heading-margin-bottom: 8px;
$msv-checkout-payment-instrument-paypal-max-width: 300px;
$msv-checkout-payment-instrument-error-padding: 20px;
$msv-checkout-payment-instrument-icon-margin-right: 22px;
$msv-checkout-payment-instrument-error-message-margin-left: 42px;
$msv-checkout-payment-instrument-error-margin-bottom: 44px;

//style presets
:root {
    --msv-checkout-payment-instrument-bg: #{$msv-gray-100};

    // error
    --msv-checkout-payment-instrument-error-message-bg: #{$msv-pink};
    --msv-checkout-payment-instrument-error-message-border: transparent;
    --msv-checkout-payment-instrument-error-message-color: #{$msv-red};
}

.checkout-payment-instrument {
    position: relative;
    min-height: $msv-checkout-payment-instrument-min-height;

    &__loading-background {
        display: flex;
        height: $msv-checkout-payment-instrument-loading-dimension;
        position: absolute;
        width: $msv-checkout-payment-instrument-loading-dimension;
        background-color: var(--msv-checkout-payment-instrument-bg);

        .waiting {
            display: block;
            margin: auto;
        }
    }

    &__iframe {
        border: $msv-checkout-payment-instrument-border;
        width: $msv-checkout-payment-instrument-loading-dimension;
    }

    &__show {
        &-card-title {
            font-weight: var(--msv-font-weight-bold);
            line-height: $msv-line-height-s;
            margin-bottom: $msv-checkout-payment-instrument-card-title-margin-bottom;
        }

        &-billing-address-title {
            margin-bottom: $msv-checkout-payment-instrument-card-title-margin-bottom;
            margin-top: $msv-checkout-payment-instrument-address-title-margin-top;
        }

        &-card-name,
        &-card-number,
        &-card-expiration,
        &-billing-address-1,
        &-billing-address-2,
        &-billing-address-3 {
            margin-bottom: $msv-checkout-payment-container-margin-zero;
        }
    }
}

.ms-checkout-payment-instrument {
    display: flex;
    flex-direction: column-reverse;

    &__overlay-modal {
        display: none;
    }

    &__error {
        background-color: rgba(219, 75, 92, 0.05);
        border: 1px solid var(--msv-checkout-payment-instrument-error-message-border);
        color: var(--msv-checkout-payment-instrument-error-message-color);
        padding: 16px 24px 16px 26px;
        margin-top: 10px;
        margin-bottom: $msv-checkout-payment-instrument-error-margin-bottom;

        &-title {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 30px;
            letter-spacing: -0.01px;
            color: #292929;
            position: relative;
            padding-left: 50px;
            margin-bottom: 20px;
            &::before {
                content: '' !important;
                position: absolute;
                left: 0;
                top: 0;
                height: 28px;
                width: 28px;
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJcSURBVHgBtVZNTttAFH5vEqQsOUIiAVV39AQNuxJaEXYVDW16hJ6A9CSFUtTuSKUC3ZGegOyqJkjJEbyMGnum7zOxa8J4bIfwSUnG4y/vm/fmmx+mHBi+bNXJ8K4xpq4UV+V3Ff3M7Em7L60+sfm+/uNLLysWu14OGgdtxXRoyFQpB5h4LN+dtfPPx+kcC0bNdtWf+mfS3KQFAOHSSmmr1j0az79T8x03r/bbgR9cLyoGoCIy4Os/263m/cEkAIJSdEZLBJNpr52fHv9/ngFlRGaRIZYIr7xSfhaVNy5pMA2uHkEMWJ3+9eOqhYJwY14nLgJm2rzZefsuFoT108h+4NfwkezHaRy8i3iUCtMJtbCoXdlVKhV6+vPbONDBlk0UfXgHDripcqIBLYUdhBzA3MJQNtGkWGg64bpimYB3paTGud4wMpuoTSzTB6zrChNKGbCJFhajcO+t8nCnZSgn5resImIRFBWANpomk0n8jDb6igAl9fIQ5+cszUgZ8JQxlEm2GcTlXgf6UlLuFRXDnKW51xlLswjKSe0iudxoE3XFkpOiG54Wg8abESxrI8lOH25XLjdG7kVbzsGRjYPsNy5Oa+XZ40f52ycbMS3AnWC3B66TJ/t153ZwM8h6fNAp7xzQLLtQOOqUnX4v7xIpCC85t7EgJl1r/kBLhtbBe8S+JwhsXJwcSZbLytTDfebJ5ddustN6Tfz94nW1pEpXac7NAi7HUsa9ZGZOwQiDxn5bKId5hWEOuDF5SyskGGG4ffDckG6yYrgYn+iyhdKPjTY9JtVdvzz5lRXrHwKq3y1GDMQMAAAAAElFTkSuQmCC');
                background-repeat: no-repeat;
                background-size: contain;
                user-select: none;
            }
        }

        &-message {
            padding-left: 50px;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            color: #292929;
            letter-spacing: -0.01px;
        }
    }
}

.ms-checkout-payment-instrument-paypal {
    .ms-checkout-payment-instrument__add {
        max-width: $msv-checkout-payment-instrument-paypal-max-width;
    }
}

.ms-checkout-section-container {
    padding-left: $msv-checkout-payment-container-padding;
    padding-right: $msv-checkout-payment-container-padding;

    &__item {
        .ms-text-block {
            @include font-body-regular-xs();
            background: $msv-blue-20;
            border-radius: 10px;
            padding: 20px;

            h3 {
                @include font-body-bold-m();
                padding-bottom: 8px;
            }
        }
    }
}

.ms-checkout__guided-card.ready {
    .ms-checkout-payment-instrument {
        margin-top: $msv-checkout-payment-ready-margin-top;
        margin-bottom: $msv-checkout-payment-ready-margin-bottom;

        &__show {
            display: flex;
            flex-flow: row wrap;
        }

        &__card-title {
            @include font-heading-h5-l();
            flex-basis: $msv-checkout-payment-ready-flex-basis;
            margin-bottom: $msv-checkout-payment-instrument-heading-margin-bottom;
        }

        &__billing-address-title {
            @include font-heading-h5-l();
            flex-basis: $msv-checkout-payment-ready-flex-basis;
            margin-bottom: $msv-checkout-payment-instrument-address-title-margin-bottom;
            margin-top: $msv-checkout-payment-instrument-address-title-margin-top;
        }

        &__card-number {
            margin-left: $msv-checkout-payment-ready-margin-left;
        }

        &__card-name,
        &__card-number,
        &__card-expiration,
        &__card-type-id {
            @include font-body-regular-m();
        }

        &__card-name,
        &__card-expiration {
            flex-basis: $msv-checkout-payment-ready-flex-basis;
        }

        &__card-type-id {
            text-transform: lowercase;

            &::first-letter {
                text-transform: uppercase;
            }
        }

        &__billing-address-1,
        &__billing-address-2,
        &__billing-address-3 {
            @include font-body-regular-m();
        }
    }
}

.ms-checkout__guided-card.updating {
    .ms-checkout-payment-instrument__mutation-observer {
        margin-top: $msv-checkout-payment-instrument-mutation-observer-margin-top;
    }
}
